import * as React from 'react'
import {
	Button,
	Component,
	Field,
	Repeater,
	SelectField,
	TextField,
} from '@contember/admin'
import { ImageField } from '../ImageField'
import { BulkAppendTeamMembers } from '../BulkAppendTeamMembers'

export const TeamFormTeamManager = Component(() => {
	const [isBulkAppendActive, setIsBulkAppendActive] = React.useState(false)

	return (
		<>
			{isBulkAppendActive
				? <BulkAppendTeamMembers isBulkAppendActive={isBulkAppendActive} setIsBulkAppendActive={setIsBulkAppendActive} />
				: <>
					<TextField field="name" label="Název třídy" required />
					<SelectField field="category" label="Kategorie třídy" options="TeamCategory.name" />
					<Button onClick={() => setIsBulkAppendActive(true)}>Přidat žáky hromadně</Button>
					<Repeater field="teamMembers" sortableBy="order" label="Žáci">
						<TextField field="name" label="Jméno" />
					</Repeater>
					<ImageField field="coverImage" label="Cover fotka" />
				</>
			}
		</>
	)
}, () => (
	<>
		<Field field="type" />
		<Field field="name" />
		<SelectField field="category" label="Kategorie třídy" options="TeamCategory.name" />
		<Repeater field="teamMembers" sortableBy="order" label="Žáci">
			<Field field="name" />
		</Repeater>
		<ImageField field="coverImage" label="Cover fotka" />
	</>
), 'TeamFormTeamManager')
