export const regions = [
	{ value: 'praha', label: 'Praha' },
	{ value: 'stredocesky', label: 'Středočeský' },
	{ value: 'jihocesky', label: 'Jihočeský' },
	{ value: 'plzensky', label: 'Plzeňský' },
	{ value: 'karlovarsky', label: 'Karlovarský' },
	{ value: 'ustecky', label: 'Ústecký' },
	{ value: 'liberecky', label: 'Liberecký' },
	{ value: 'kralovehradecky', label: 'Královehradecký' },
	{ value: 'pardubicky', label: 'Pardubický' },
	{ value: 'vysocina', label: 'Vysočina' },
	{ value: 'jihomoravsky', label: 'Jihomoravský' },
	{ value: 'olomoucky', label: 'Olomoucký' },
	{ value: 'moravskoslezsky', label: 'Moravskoslezský' },
	{ value: 'zlinsky', label: 'Zlínský' },
]
