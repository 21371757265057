export const howWillYouParticipateLabels = [
	{ value: 'digital', label: 'Digitálně v aplikaci' },
	{
		value: 'analogPrintSelf',
		label: 'Na papír (vystiskneme si sami)',
	},
	{
		value: 'analogByPost',
		label: 'Objednat originální papírovou motivační sadu (500,-, objednat do 15. 6.)',
	},
]


export const howDidYouFindOutUsLabels = [
	{ value: 'involvedRegularly', label: 'Účastníme se pravidelně' },
	{ value: 'direct', label: 'Oslovili nás přímo z Pěšky městem' },
	{ value: 'parents', label: 'S iniciativou přisli rodiče' },
	{ value: 'city', label: 'Od obce nebo městské části' },
	{ value: 'socialNetworks', label: 'Ze sociálních sítí' },
	{ value: 'media', label: 'Z médií' },
]

export const willParticipateLabels = [
	{ label: 'Ano', value: true },
	{ label: 'Ne', value: false },
]

export const addressTypeLabels = [
	{ value: 'billing', label: 'Fakturační' },
	{ value: 'delivery', label: 'Doručovací' },
]
