import * as React from 'react'
import { EditPage, LinkButton, PersistButton } from '@contember/admin'
import { AccompanyingActivityForm } from '../../../components/forms/AccompanyingActivityForm'

export default () => (
	<>
		<EditPage entity="Team(id=$id)" rendererProps={{ title: 'Doprovodné aktivity', actions: <><PersistButton labelSave="Uložit" labelSaved="Uložit" /> <LinkButton to="organization/team/edit(id: $entity.id)">Zpět na třídu</LinkButton></> }}>
			<AccompanyingActivityForm />
		</EditPage>
	</>
)
