import { Component } from '@contember/admin'
import * as React from 'react'


export const AnalogByPostNotice = Component(() => (
	<>
		<div className="bg-blue-600 text-white p-4 rounded-lg">
			<p>
				Papírové tabulky vám rádi zašleme na vaši adresu za poplatek 500
				Kč. Pokud nechcete poplatek platit, vytiskněte si soutěžní archy
				sami a nebo použijte digitální verzi (doporučujeme).
			</p>
			<p>
				Tištěnou sadu je potřeba objednat do 15. 6. 2023.
			</p>
			<p>
				Abychom vám mohli poslat papírové verze, doplňte prosím fakturační
				a doručovací údaje.
			</p>
		</div>
	</>
), 'AnalogByPostNotice')
