import { Layout as ContemberLayout, Link, useProjectUserRoles, useRedirect } from '@contember/admin'
import * as React from 'react'
import { ReactNode } from 'react'
import { logo } from './Logo'

import { LogoutLink, Navigation } from './Navigation'
import { LoggedAs } from './LoggedAs'
import { GlobalFooter } from './partials/footer'

export const LayoutOrganization = (props: { children?: ReactNode }) => {
	const roles = useProjectUserRoles()
	const redirect = useRedirect()
	if (!roles.has('organizationManager')) {
		redirect('index')
	}

	return (
		<>
			<ContemberLayout
				sidebarHeader={<a href="/view/list">{logo}</a>}
				navigation={<Navigation layout="organization" />}
				sidebarFooter={<>
					<LoggedAs />
					<div className="mb-2">
						<a href="/view/help">Nápověda</a>
					</div>
					<LogoutLink />
				</>}
				children={props.children}
			/>
			<GlobalFooter />
		</>
	)
}
