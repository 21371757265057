import * as React from 'react'
import {
	AnyUploadField,
	If,
	MultiEditPage,
	PersistButton,
	RadioField,
	SelectField,
	Stack,
	TextField,
} from '@contember/admin'

export default () => {
	return (
		<MultiEditPage
			entities="EducationalMaterial"
			rendererProps={{
				title: 'Materiály ke stažení',
				sortableBy: 'order',
				actions: <PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />,
			}}
		>
			<Stack direction="vertical">
				<Stack direction="horizontal" justify="stretch">
					<TextField field="name" label="Název" />
					<SelectField
						field="category"
						label="Kategorie"
						options="EducationalMaterialCategory.name"
					/>
				</Stack>
				<Stack direction="horizontal" justify="stretch">
					<RadioField
						field="type"
						label="Typ přílohy"
						options={[
							{ value: 'file', label: 'Soubor' },
							{ value: 'video', label: 'Youtube video' },
						]}
						defaultValue="file"
					/>
					<If
						condition={entity =>
							entity.getField<string>('type').value === 'file'
						}
					>
						<AnyUploadField
							baseEntity="file"
							urlField="url"
							label="Soubor"
							fileNameField="name"
							fileTypeField="type"
						/>
					</If>
					<If
						condition={entity =>
							entity.getField<string>('type').value === 'video'
						}
					>
						<TextField label="Youtube video" field="youtubeVideo" />
					</If>
				</Stack>
			</Stack>
		</MultiEditPage>
	)
}
