import { useAuthedContentMutation } from '@contember/admin'
import { useCallback } from 'react'

type MutationPayload = { email: string; personId: string; identityId: string; role: string; organization: { connect: { id: string } } }

export const useCreateUser = () => {
	const [createUserMutation] = useAuthedContentMutation<{ createUser: { ok: boolean; errorMessage: string | null } }, { data: MutationPayload }>(
		`mutation CreateUser($data: UserCreateInput!) {
			createUser(data: $data) {
				ok
				errorMessage
			}
		}
		`,
	)

	return useCallback(
		(data: MutationPayload) => {
			return createUserMutation({ data })
		},
		[createUserMutation],
	)
}
