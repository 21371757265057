import { useCallback } from 'react'
import { RegisterFormType } from '../modules/register'
import { EntityAccessor, EntityId, useAuthedContentMutation } from '@contember/admin'
import { useInviteOrganizationManager } from './useInviteOrganizationManager'
import { useInviteTeamManager } from './useInviteTeamManager'

type UserQueryResult = { getUser: { id: string } }
type OrganizationQueryResult = { getOrganization: { id: string } }

const GET_USER_QUERY = `query GetUser($by: UserUniqueWhere!) {
  			getUser(by: $by) {
    			id
  			}
		}`

const GET_ORGANIZATION_QUERY = `query GetOrganization($by: OrganizationUniqueWhere!) {
  			getOrganization(by: $by) {
    			id
  			}
		}`


const CREATE_USER_MUTATION = `mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    ok
	errorMessage
  }
}`

export const useSendRegistrationForm = () => {

	const inviteOrganizationManager = useInviteOrganizationManager()
	const inviteTeamManager = useInviteTeamManager()
	const [verifyUser] = useAuthedContentMutation<UserQueryResult, { by: { email: string } }>(GET_USER_QUERY)
	const [verifyOrganization] = useAuthedContentMutation<OrganizationQueryResult, { by: { idNo: string } }>(GET_ORGANIZATION_QUERY)

	const [registerMutation] = useAuthedContentMutation<
		{ createUser: { ok: boolean; errorMessage: string | null } },
		{
			data: {
				email: string
				firstName: string
				gdprAgreement: boolean
				identityId: string
				lastName: string
				personId: string
				phone: string
				role: string
				organization?: {
					create: {
						city: string
						idNo: string
						name: string
						postalCode: string
						street: string
						region?: string
						longitude?: number
						latitude?: number
						teamCounts?: number
						editions: [
							{
								connect: {
									id: EntityId
								}
							}
						]
						questionnaire: {
							create: {
								howDidYouFindOutUs?: string
								howWillYouParticipate: string
								wantToGetInvolved: boolean
							}
						}
						addresses?: Array<{
							create: {
								city: string
								idNo: string
								name: string
								postalCode: string
								street: string
								type: string
							}
						}>
					}
				}
				teams?: [
					{
						create: {
							city: string
							region: string
							name: string
							type: string
							edition: {
								connect: {
									id: EntityId
								}
							}
							questionnaire: {
								create: {
									howDidYouFindOutUs?: string
								}
							}
						}
					}
				]
			}
		}
	>(CREATE_USER_MUTATION)

	return useCallback(async (registerForm: RegisterFormType, currentEdition: EntityAccessor | null): Promise<{ state: 'success' | 'error'; error?: string }> => {
		if (!currentEdition?.idOnServer) {
			return { state: 'error', error: 'Registrace pro daný ročník nejsou otevřeny.' }
		}
		if (!registerForm.email) {
			return { state: 'error', error: 'E-mail je povinná položka' }
		}
		if (registerForm.role === 'organizationManager' && !registerForm.organization.idNo) {
			return { state: 'error', error: 'IČ je povinná položka' }
		}
		const userQuery = await verifyUser({ by: { email: registerForm.email } })
		if (userQuery.getUser?.id) {
			return { state: 'error', error: 'Uživatel s tímto e-mailem je již registrován.' }
		}
		if (registerForm.role === 'organizationManager') {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const organizationQuery = await verifyOrganization({ by: { idNo: registerForm.organization.idNo! } })
			if (organizationQuery.getOrganization?.id) {
				return { state: 'error', error: 'Vaše škola je již registrována, přihlaste se přes tlačítko PŘIHLÁSIT na hlavní stránce. Pokud si nepamatujete přihlašovací údaje správce školy, kontaktujte nás na mail marie.civerna@peskymestem.cz.' }
			}
		}

		let personId = undefined
		let identityId = undefined
		if (registerForm.role === 'teamManager') {
			const result = await inviteTeamManager(registerForm.email, 'team')
			if (result?.error) {
				return { state: 'error', error: result.error }
			} else {
				personId = result.personId
				identityId = result.identityId
			}
		} else if (registerForm.role === 'organizationManager') {
			const result = await inviteOrganizationManager(registerForm.email)
			if (result?.error) {
				return { state: 'error', error: result.error }
			} else {
				personId = result.personId
				identityId = result.identityId
			}
		}
		if (personId && identityId) {
			const registration = await registerMutation({
				data: {
					email: registerForm.email,
					firstName: registerForm.name ?? '',
					lastName: registerForm.surname ?? '',
					gdprAgreement: registerForm.agreement,
					identityId: identityId,
					personId: personId,
					phone: registerForm.phone ?? '',
					role: registerForm.role,
					...(registerForm.role === 'teamManager' && {
						teams: [
							{
								create: {
									city: registerForm.team.city ?? '',
									region: registerForm.team.region ?? '',
									name: registerForm.team.name ?? '',
									type: registerForm.team.type,
									edition: {
										connect: {
											id: currentEdition.idOnServer,
										},
									},
									questionnaire: {
										create: {
											howDidYouFindOutUs: registerForm.questionnaire.howDidYouFindOutUs,
										},
									},
								},
							},
						],
					}),
					...(registerForm.role === 'organizationManager' && {
						organization:
						{
							create: {
								city: registerForm.organization.city ?? '',
								idNo: registerForm.organization.idNo ?? '',
								name: registerForm.organization.name ?? '',
								postalCode: registerForm.organization.postalCode ?? '',
								street: registerForm.organization.street ?? '',
								region: registerForm.organization.region ?? undefined,
								longitude: registerForm.organization.longitude ?? undefined,
								latitude: registerForm.organization.latitude ?? undefined,
								teamCounts: registerForm.organization.noClasses ? Number(registerForm.organization.noClasses) : undefined,
								editions: [
									{
										connect: {
											id: currentEdition.idOnServer,
										},
									},
								],
								questionnaire: {
									create: {
										howDidYouFindOutUs: registerForm.questionnaire.howDidYouFindOutUs,
										howWillYouParticipate: registerForm.questionnaire.howWillYouParticipate,
										wantToGetInvolved: registerForm.questionnaire.wantToGetInvolved,
									},
								},
								addresses: [
									{
										create: {
											city: registerForm.deliveryAddress.city ?? '',
											idNo: registerForm.deliveryAddress.idNo ?? '',
											name: registerForm.deliveryAddress.name ?? '',
											postalCode: registerForm.deliveryAddress.postalCode ?? '',
											street: registerForm.deliveryAddress.street ?? '',
											type: 'delivery',
										},
									},
									{
										create: {
											city: registerForm.billingAddress.city ?? '',
											idNo: registerForm.billingAddress.idNo ?? '',
											name: registerForm.billingAddress.name ?? '',
											postalCode: registerForm.billingAddress.postalCode ?? '',
											street: registerForm.billingAddress.street ?? '',
											type: 'billing',
										},
									},
								],
							},
						},
					}),
				},
			})
			if (registration.createUser.ok) {
				return { state: 'success' }
			} else {
				return { state: 'error', error: registration.createUser.errorMessage ?? 'Došlo k neznámé chybě.' }
			}
		}
		return { state: 'error', error: 'Došlo k neznámé chybě.' }
	}, [])
}

