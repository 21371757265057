import { ApplicationEntrypoint, Pages, runReactApp } from '@contember/admin'
import { csCZ } from '@contember/admin-i18n'
import '@contember/admin/style.css'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import * as React from 'react'

import { LoginEntrypoint } from './components/entrypoints/LoginEntrypoint'
import RegisterEntrypoint from './components/entrypoints/RegisterEntrypoint'
import { RegisterForm } from './components/forms/RegisterForm'
import { Layout } from './components/Layout'

import './main.css'

const SENTRY_DSN = import.meta.env.VITE_CONTEMBER_ADMIN_SENTRY_DSN
if (typeof SENTRY_DSN === 'string') {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [
			new CaptureConsoleIntegration({
				levels: ['error'],
			}),
		],
		tracesSampleRate: 0,
		enabled: process.env.NODE_ENV !== 'development',
	})
}

switch (window.location.pathname) {
	case '/register':
		runReactApp(
			<RegisterEntrypoint
				basePath="/register/"
				apiBaseUrl={import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL}
				sessionToken={import.meta.env.VITE_CONTEMBER_ADMIN_INVITE_TOKEN}
				project={import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME}
				stage="live"
				defaultLocale={'cs-CZ'}
				dictionaries={{ 'cs-CZ': csCZ }}
			>
				<RegisterForm />
			</RegisterEntrypoint>,
		)
		break
	case '/login':
		runReactApp(
			<LoginEntrypoint
				heading="Pěšky do školy"
				basePath="/login/"
				apiBaseUrl={import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL}
				loginToken={import.meta.env.VITE_CONTEMBER_ADMIN_LOGIN_TOKEN}
				projects={[import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME]}
				formatProjectUrl={() => '/'}
				defaultLocale={'cs-CZ'}
				dictionaries={{ 'cs-CZ': csCZ }}
			/>,
		)
		break
	case String(window.location.pathname.match(/^\/view\/.*/i)):
		runReactApp(
			<ApplicationEntrypoint
				apiBaseUrl={import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL}
				project={import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME}
				sessionToken={import.meta.env.VITE_CONTEMBER_ADMIN_PUBLIC_TOKEN}
				stage="live"
				children={
					<div id="view-root">
						<Pages
							layout={Layout}
							children={import.meta.globEager('./pages/**/*.tsx')}
						/>
					</div>
				}
				defaultLocale={'cs-CZ'}
				dictionaries={{ 'cs-CZ': csCZ }}
			/>,
		)
		break
	default:
		runReactApp(
			<ApplicationEntrypoint
				apiBaseUrl={import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL}
				project={import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME}
				loginToken={import.meta.env.VITE_CONTEMBER_ADMIN_LOGIN_TOKEN}
				stage="live"
				children={
					<div id="default-root">
						<Pages
							layout={Layout}
							children={import.meta.globEager('./pages/**/*.tsx')}
						/>
					</div>
				}
				defaultLocale={'cs-CZ'}
				dictionaries={{ 'cs-CZ': csCZ }}
				onInvalidIdentity={() => (window.location.href = '/view/list')}
			/>,
		)
}
