
import { Component, EntityId, EntityListSubTree, Field, useEntityListSubTree } from '@contember/admin'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import * as React from 'react'

import mapPinHouseIconPin from '../assets/images/map-pin-house.svg'

type GoogleMapsProps = {
	height?: string
	region?: string
}

export const GoogleMaps = Component(({ height }: GoogleMapsProps) => {
	const organizations = useEntityListSubTree('organizations')
	const markers: Array<{
		id: EntityId
		icon?: React.ComponentProps<typeof Marker>['icon']
		longitude: number
		latitude: number
		name: string
		type: 'organization' | 'team'
	}> = []

	Array.from(organizations).forEach(organization => {
		const longitude = organization.getField<number>('longitude').value
		const latitude = organization.getField<number>('latitude').value
		if (longitude && latitude) {
			markers.push({
				id: organization.idOnServer ?? '',
				icon: mapPinHouseIconPin,
				longitude,
				latitude,
				name: organization.getField<string>('name').value ?? '',
				type: 'organization',
			})
		}
	})

	return (
		<LoadScript
			googleMapsApiKey="AIzaSyDNqV1b9irxgkAJ8NlpGpuInkBDadBk7K4"
		>
			<GoogleMap
				mapContainerStyle={{ width: '100%', height: height ?? '400px', flexGrow: 1 }}
				center={{ lat: 49.8075632, lng: 15.4840425 }}
				zoom={7}
			>
				{markers.map(marker => <Marker
					key={marker.id}
					position={{ lat: marker.latitude, lng: marker.longitude }}
					title={marker.name}
					onClick={e => window.location.href = `/view/${marker.type}/?id=${marker.id}`}
					icon={marker?.icon}
				/>)}
			</GoogleMap>
		</LoadScript>
	)
}, ({ region }: GoogleMapsProps) => (<>
	<EntityListSubTree entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : 'Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]'} alias="organizations">
		<Field field="name" />
		<Field field="isLocationPublic" />
		<Field field="longitude" />
		<Field field="latitude" />
		<Field field="stats.teamsApproved" />
	</EntityListSubTree>
</>), 'GoogleMaps')
