import { Component, TitleBar, useIdentity } from '@contember/admin'
import * as React from 'react'
import { logo } from '../Logo'

export const ViewTitleBar = Component(
	() => {
		const identity = useIdentity()

		return (
			<div className="view-titlebar">
				<TitleBar
					navigation={logo}
					actions={<>{!identity.person ?
						<>
							<strong className="flex items-center text-black gap-3 mr-3"><span className="inline-flex max-w-[250px] text-right">Zúčastníte se ročníku 2024? Přihlaste se nebo se zaregistrujte</span><span>»</span></strong>
							<a href="/view/kids" className="cui-button view-justifyCenter elevation-none" title="Dětská sekce"><div className="cui-button-content">Dětská sekce</div></a>
							<a href="/login" className="cui-button view-justifyCenter elevation-none" title="naše škola už zde má účet"><div className="cui-button-content">Přihlášení</div></a>
							<a href="/register" className="cui-button view-justifyCenter elevation-none" title="naše škola zde ještě nemá účet"><div className="cui-button-content">Registrace</div></a>
						</> :
						<a href="/" className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Administrace</div></a>
					}</>}>
					{false}
				</TitleBar>
			</div>
		)
	},
	() => null,
	'ViewTitleBar',
)
