import * as React from 'react'
import { Button, GenericPage, TextareaInput } from '@contember/admin'
import { useGetMyOrganization } from '../../../hooks/useGetMyOrganization'
import { useInviteMultipleTeamManager } from '../../../hooks/useInviteMultipleTeamManagers'

export default () => {
	const getMyOrganization = useGetMyOrganization()
	const inviteMultipleTeamManagers = useInviteMultipleTeamManager()
	const [emails, setEmails] = React.useState<string>('')
	const [inviteLog, setInviteLog] = React.useState<string[] | undefined>(undefined)
	return (
		<>
			<GenericPage>
				{inviteLog && (
					<div>
						{inviteLog.map((logItem, index) => (
							<div key={index}>{logItem}</div>
						))}
					</div>
				)}
				<div>
					<h1 className="mb-0 mt-8">Hromadně pozvat uživatele</h1>
					<p>Zadejte seznam e-mailů uživatelů k pozvání. Nový řádek = nový email. Seznam kontaktů můžete hromadně nahrát např. z Excelu.</p>
				</div>
				<div className="w-full [&>textarea]:rounded-lg [&>textarea]:w-full">
					<TextareaInput name="email" value={emails} onChange={value => setEmails(value ?? '')} rows={7} />
				</div>
				<Button
					distinction="primary"
					onClick={async () => {
						const response = await inviteMultipleTeamManagers(emails, getMyOrganization?.id)
						setEmails('')
						setInviteLog(response)
					}}
				>
					Pozvat
				</Button>
			</GenericPage>
		</>
	)
}
