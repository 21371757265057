import {
	CreatePage,
	EntityAccessor,
	EntityListSubTree,
	EntitySubTree,
	Field,
	HasOne,
	HiddenField,
	PersistButton,
	useIdentity,
} from '@contember/admin'
import * as React from 'react'
import { TeamFormOrganization } from '../../../components/forms/TeamFormOrganization'
import { OnlyIfCanAddTeamsForCurrentEdition } from '../../../components/OnlyIfCanAddTeamsForCurrentEdition'

export default () => {
	const identity = useIdentity()
	return (
		<>
			<CreatePage
				entity="Team"
				rendererProps={{
					title: 'Přidat třídu',
					actions: <></>,
				}}
				redirectOnSuccess="organization/team/edit(id: $entity.id)"
			>
				<div className="form-box">
					<OnlyIfCanAddTeamsForCurrentEdition closed={<h1>Třídy již nelze přidávat (registrace byly uzavřeny)</h1>}>
						<TeamFormOrganization />
						<PersistButton labelSave="Uložit" labelSaved="Uložit" />

						<HiddenField field="type" defaultValue="class" label={undefined} />
						<EntitySubTree
							entity={`Organization(users.personId='${identity?.person?.id}')`}
							alias="organizationToConnect"
						/>
						<EntityListSubTree
							entities={`Edition`}
							alias="editionsToConnect"
							orderBy="startedAt desc"
						>
							<span className="hidden"><Field field="name" /></span>
						</EntityListSubTree>
						<HasOne
							field="organization"
							onInitialize={(getAccessor, options) => {
								const organizationToConnect = options.getEntitySubTree(
									`organizationToConnect`,
								)
								const parentEntity = getAccessor().getParent()
								if (parentEntity && organizationToConnect) {
									(parentEntity as EntityAccessor).connectEntityAtField(
										'organization',
										organizationToConnect,
									)
								}
							}}
						/>
						<HasOne
							field="edition"
							onInitialize={(getAccessor, options) => {
								const editionsToConnect = Array.from(
									options.getEntityListSubTree(`editionsToConnect`),
								)
								const parentEntity = getAccessor().getParent()
								if (editionsToConnect.length && parentEntity) {
									(parentEntity as EntityAccessor).connectEntityAtField(
										'edition',
										editionsToConnect[0],
									)
								}
							}}
						/>
					</OnlyIfCanAddTeamsForCurrentEdition>
				</div>
			</CreatePage>
		</>
	)
}
