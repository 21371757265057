import { Button, Component, useCurrentContentGraphQlClient } from '@contember/admin'
import * as Papa from 'papaparse'
import * as React from 'react'

const LIST_ORGANIZATION_QUERY = `
	query($filter: OrganizationWhere, $usersFilter: UserWhere) {
		listOrganization(filter: $filter) {
			name
			region
			city
			users(filter: $usersFilter) {
				firstName
				lastName
				email
			}
		}
	}
`

type ListOrganizationQueryResult = {
	listTeam: Organization[]
}

type Organization = {
	name?: string
	region?: string
	city?: string
	users?: Array<{
		firstName: string
		lastName: string
		email: string
	}>
}


export const ActiveOrganizationManagerExport = Component(
	() => {
		const client = useCurrentContentGraphQlClient()
		const [prepareDownload, setPrepareDownload] = React.useState<boolean>(false)
		const [items, setItems] = React.useState<any>(null)
		const [objectUrl, setObjectUrl] = React.useState<string>()
		const handler = React.useCallback(async () => {
			return await client.sendRequest<ListOrganizationQueryResult>(LIST_ORGANIZATION_QUERY, {
				variables: {
					filter: { stats: { isActiveInCurrentEdition: { eq: true } } },
					usersFilter: { role: { eq: "organizationManager" } }
				}
			})
		}, [client])

		React.useEffect(() => {
			if (items) {
				// eslint-disable-next-line no-console
				console.log('items', items)
				const csv = items.data?.listOrganization?.map((organization: Organization) => {
					for (const user of organization.users ?? []) {
						if (!user) {
							continue
						}
						return [
							organization?.name,
							organization?.region,
							organization?.city,
							`${user.firstName ?? ''} ${user.lastName ?? ''}`,
							user.email,
						]
					}
				}).filter(Boolean)

				csv.unshift([
					'Jméno školy',
					'Region',
					'Město',
					'Jméno a příjmení',
					'E-mail',
				])

				const myCsv = Papa.unparse(csv, { delimiter: ';' })
				const blob = new Blob([myCsv], { type: 'text/csv;charset=utf-8;' })
				const url = URL.createObjectURL(blob)
				setObjectUrl(url)
				return () => {
					URL.revokeObjectURL(url)
				}
			}
		}, [items])

		if (objectUrl) {
			return (
				<a href={objectUrl} download="classes.csv">
					<Button distinction="outlined">Stáhnout export</Button>
				</a>
			)
		} else {
			return (
				<Button
					onClick={async () => {
						setPrepareDownload(true)
						setTimeout(async () => setItems(await handler()), 1500)
					}}
					distinction="outlined"
					loading={prepareDownload}
				>
					Exportovat správce aktivních škol
				</Button>
			)
		}
	},
	() => null,
	'ActiveOrganizationManagerExport',
)
