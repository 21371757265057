import * as React from 'react'
import {
	AnyUploadField,
	Box,
	CheckboxField,
	Component,
	DateField,
	EntityAccessor,
	Field,
	FloatField,
	HasMany,
	HasOne,
	If,
	NumberField,
	Repeater,
	SelectField,
	Stack,
	StaticRender,
	TextField,
} from '@contember/admin'
import { ImageField } from '../ImageField'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'

export const TeamFormAdmin = Component(() => {
	return (
		<>
			<TextField field="name" label="Jméno týmu" required />
			<SelectField field="edition" label="Ročník" options="Edition.name" />
			<SelectField
				field="organization"
				label="Škola"
				options="Organization.name"
			/>
			<CheckboxField
				field="isApproved"
				label="Je tým schválen?"
				defaultValue={false}
				notNull={true}
			/>
			<SelectField
				field="category"
				label="Kategorie týmu / třídy"
				options="TeamCategory.name"
			/>
			<SelectField
				field="manager"
				label="Správce týmu / třídy"
				options="User[organization.teams.id = $id]"
				renderOption={(accessor: EntityAccessor) => {
					const lastName = accessor.getField('lastName').value
					const firstName = accessor.getField('firstName').value
					const email = accessor.getField('email').value

					return `${lastName} ${firstName} (${email})`
				}}
				optionsStaticRender={
					<>
						<Field field="lastName" />
						<Field field="firstName" />
						<Field field="email" />
					</>
				}
			/>

			<DateField field="specialCompetitionWeek" label="Speciální soutěžní týden pro tým" description="Lze zadat kterýkoliv den z daného týdne" />
			<Box heading="Doprovodná aktivita">
				<Stack direction="vertical">
					<FloatField
						field="accompanyingActivityPoints"
						label="Body týmu za doprovodnou aktivitu"
						defaultValue={0}
						min={0}
						max={10000}
					/>
					<Repeater
						field={`organization.accompanyingActivities[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}
						orderBy="id"
						label="Dokumentace doprovodné aktivity školy"
						initialEntityCount={0}
					>
						<AccompanyingActivity />
					</Repeater>
					<Repeater
						field="accompanyingActivities"
						orderBy="id"
						label="Dokumentace doprovodné aktivity třídy"
						initialEntityCount={0}
					>
						<AccompanyingActivity />
					</Repeater>
				</Stack>
			</Box>
			<Repeater field="teamMembers" sortableBy="order" label="TeamMember">
				<TextField field="name" label="Jméno" />
			</Repeater>
			<StaticRender>
				<HasOne field="manager"></HasOne>
			</StaticRender>
			<ImageField field="coverImage" label="Cover fotka" />
			<StaticRender>
				<HasMany field="bulkPointSubmissions" />
			</StaticRender>
			<If condition={accessor => Array.from(accessor.getEntityList('bulkPointSubmissions')).length > 0}>
				<Repeater field="bulkPointSubmissions" orderBy="date" label={undefined}>
					<DateField field="date" label="Datum" />
					<NumberField field="totalPresences" label="Počet přítomných žáků" />
					<NumberField field="totalPoints" label=" Celkový počet bodů" />
				</Repeater>
			</If>
		</>
	)
})


const AccompanyingActivity = Component(() => {
	return (<>
		<TextField field="name" label="Název aktivity" required />
		<Repeater field="attachments" sortableBy="order" label="Fotografie">
			<AnyUploadField
				baseEntity="file"
				urlField="url"
				label="Soubor"
				fileNameField="name"
				fileTypeField="type"
			/>
		</Repeater>
	</>)
})
