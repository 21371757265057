import { EditPage, Field, Link } from '@contember/admin'
import * as React from 'react'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import mommySrc from '../../assets/images/mamka.png'
import girlTalk from '../../assets/images/holcicka.png'

export default () => {
	return (
		<>
			<TeamTitleBar />
			<EditPage entity="Team(id=$id)" rendererProps={{ title: (<><Field field="name" /></>), actions: <></> }} >
				<div className="flex w-full items-center justify-between p-8 rounded-full bg-white">
					<h2 className="!text-black mb-0"><Field field="name" /></h2>
					<div>
						<Link to="team/editTeam(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid border-gray-200 hover:bg-gray-50 mr-2">Nastavení</Link>
						<Link to="team/attendanceTeam(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid border-gray-200 hover:bg-gray-50">Zadat docházku</Link>
					</div>
				</div>
				<img
					alt=""
					className="block w-full max-w-[640px] mx-auto"
					src={mommySrc}
				/>
				<div className="flex justify-center items-center">
					<div>
						<div className="text-center bg-white p-2 relative -top-4 rounded-br-none text-black rounded-xl">Výsledné pořadí se může změnit podle počtu bodů za doprovodnou aktivitu, které uděluje pořadatel.</div>
					</div>
					<img src={girlTalk} width="80" />
				</div>
			</EditPage>
		</>
	)
}
