import { Filter } from '@contember/admin'

export const sanitizeFilter = (input: Filter): any => {
	const replacedObject = JSON.parse(JSON.stringify(input))
	replaceValueObjects(replacedObject)
	return replacedObject
}

function replaceValueObjects(obj: any) {
	for (let key in obj) {
		if (typeof obj[key] === 'object') {
			if (obj[key].value && Object.keys(obj[key]).length === 1) {
				obj[key] = obj[key].value
			} else {
				replaceValueObjects(obj[key])
			}
		}
	}
}
