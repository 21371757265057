import { EditPage, EntityListSubTree, Field, HasMany, HasOne, PersistButton, StaticRender } from '@contember/admin'
import * as React from 'react'
import { BulkPointSubmissionEditForm } from '../../../components/forms/BulkPointSubmissionEditForm'


export default () => {
	return (
		<>
			<EditPage entity={`Team(id=$id)`} rendererProps={{
				title: <>Hromadné zadání bodů k existující třídě</>,
				actions: <></>,
			}}>
				<BulkPointSubmissionEditForm hideBackLink={true} />

				<StaticRender>
					<HasOne field="stats">
						<Field field="totalPoints" />
					</HasOne>
					<HasMany field="bulkPointSubmissions">
						<Field field="date" />
						<Field field="totalPresences" />
						<Field field="totalPoints" />
					</HasMany>
				</StaticRender>
				<EntityListSubTree
					entities={`Edition`}
					alias="editionsToConnect"
					orderBy="startedAt desc"
				>
					<StaticRender>
						<Field field="name" />
						<Field field="startedAt" />
						<Field field="finishedAt" />
					</StaticRender>
				</EntityListSubTree>
				<PersistButton labelSave="Uložit" labelSaved="Uložit" />
			</EditPage>
		</>
	)
}

