import * as React from 'react'
import { Component, ImageUploadField, TextField } from '@contember/admin'

type ImageFieldProps = {
	field: string
	label?: string
}

export const ImageField = Component<ImageFieldProps>(
	({ field, label }) => (
		<ImageUploadField label={label ?? 'Obrázek'} baseEntity={field} urlField="url" widthField="width" heightField="height">
			<TextField field="alt" label="Alternativní text" />
		</ImageUploadField>
	),
	'ImageField',
)
