import { Component } from '@contember/admin'
import * as React from 'react'


export const AnalogPrintSelfNotice = Component(() => (
	<>
		<div className="bg-blue-600 text-white p-4 rounded-lg">
			<p>
				Po dokončení registrace si v administraci ve “Vzdělávací sekci”
				můžete stáhnout PDF s motivační sadou.
			</p>
		</div>
	</>
), 'AnalogPrintSelfNotice')
