import * as React from 'react'
import { CreatePage } from '@contember/admin'
import {
	OrganizationForm,
	OrganizationSideForm,
} from '../../../components/forms/OrganizationForm'

export default () => (
	<>
		<CreatePage
			entity="Organization"
			rendererProps={{ title: 'Přidat školu', side: <OrganizationSideForm /> }}
			redirectOnSuccess="admin/organization/edit(id: $entity.id)"
		>
			<OrganizationForm />
		</CreatePage>
	</>
)
