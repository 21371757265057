import { useAuthedContentQuery, useIdentity } from '@contember/admin'

export const useGetMyOrganization = () => {
	const identity = useIdentity()
	const { state } = useAuthedContentQuery(
		`query GetOrganization($by: OrganizationUniqueWhere!) {
  			getOrganization(by: $by) {
    			id
  			}
		}`,
		{ by: { users: { personId: identity?.person?.id } } },
	)

	if (state.state === 'success') {
		const { getOrganization } = state.data as {
			getOrganization: { id: string }
		}
		return getOrganization ?? {}
	}

	return undefined
}
