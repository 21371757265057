import { useProjectSlug } from '@contember/admin'
import { useCallback } from 'react'

import { useInvite } from './useInvite'

export const useInviteTeamManager = () => {
	const project = useProjectSlug()
	const invite = useInvite()
	return useCallback(async (email: string, type?: 'team' | 'class'): Promise<({ personId?: string; identityId?: string; error?: string })> => {

		if (project && email) {
			const inviteResponse = await invite({
				projectSlug: project,
				email,
				memberships: [{ role: 'teamManager', variables: [] }],
				method: 'RESET_PASSWORD',
				mailVariant: type === 'team' ? 'teamManager' : 'teacher',
			})

			if (inviteResponse.ok) {
				if (!inviteResponse.result.isNew) {
					return { error: 'E-mail je již registrován. Zkuste se přihlásit.' }
				} else {
					const personId = inviteResponse.result.person.id
					const identityId = inviteResponse.result.person.identity.id
					return { personId: personId, identityId: identityId }
				}
			} else {
				const errorMsg = (code: string) => {
					switch (code) {
						case 'ALREADY_MEMBER':
							return 'E-mail je již registrován. Zkuste se přihlásit.'
						default:
							return 'Došlo k neznámé chybě.'
					}
				}

				return { error: errorMsg(inviteResponse.error?.code) }
			}
		}
		return { error: 'Došlo k neznámé chybě.' }
	}, [])
}
