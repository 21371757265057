import { DataBindingProvider, FeedbackRenderer } from '@contember/admin'
import * as React from 'react'
import { GoogleMaps } from '../../components/GoogleMaps'

export default () => {
	const urlParams = new URLSearchParams(window.location.search)
	const regionParam = urlParams.get('region')

	return (
		<>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<GoogleMaps region={regionParam ?? undefined} height="100%" />
			</DataBindingProvider>
		</>
	)
}
