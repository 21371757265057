import { EditPage, LinkButton, PersistButton, useOnPersistSuccess } from '@contember/admin'
import * as React from 'react'
import { AttendanceForm } from '../../components/forms/AttendanceForm'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { scrollToPosition } from '../../utils/attendance'

export default () => {
	const [scrollPosition, setScrollPosition] = React.useState(0)
	const [formType, setFormType] = React.useState<'simplified' | 'complex'>('simplified')
	const [attendanceNonCompetitive, setAttendanceNonCompetitive] = React.useState(false)

	return (
		<>
			<TeamTitleBar />
			<div className="page-has-school-image-at-top">
				<EditPage
					entity="Team(id=$id)"
					rendererProps={{
						title: 'Zadat docházku', actions: <><LinkButton to="team/editActivity(id: $entity.id)">Přidat doprovodnou aktivitu</LinkButton><PersistButton
							labelSave="Uložit"
							labelSaved="Uložit" />
						</>,
						pageContentLayout: 'stretch',
					}}
					onBeforePersist={() => {
						setScrollPosition(window.scrollY)
					}}
					onPersistSuccess={useOnPersistSuccess({
						onPersistSuccess: () => scrollToPosition(scrollPosition),
					})}>
					<AttendanceForm
						formType={formType}
						setFormType={setFormType}
						attendanceNonCompetitive={attendanceNonCompetitive}
						setAttendanceNonCompetitive={setAttendanceNonCompetitive}
					/>
				</EditPage>
			</div>
		</>
	)
}
