import { useAuthedContentMutation } from '@contember/admin'
import { useCallback } from 'react'



export const useGetUser = () => {
	const [getUserQuery] = useAuthedContentMutation<{ getUser: { id?: string } }, { by: { personId: string } }>(
		`query GetUser($by: UserUniqueWhere!) {
  			getUser(by: $by) {
    			id
  			}
		}
		`,
	)

	return useCallback(
		(variables: { by: { personId: string } }) => {
			return getUserQuery(variables)
		},
		[getUserQuery],
	)


}
