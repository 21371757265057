import * as React from 'react'
import { BooleanCell, Component, ControlledDataGrid, DataBindingProvider, DataGrid, DataGridPageRenderer, DeleteEntityButton, EnumCell, FeedbackRenderer, GenericCell, Icon, Link, LinkButton, NumberCell, Stack, TextCell, useDataGrid } from '@contember/admin'
import { OrganizationExport } from '../../../components/exports/OrganizationExport'
import { decimalFormat } from '../../../utils/format'
import { regions } from '../../../modules/regions'
import { createEnumOptions } from '../../../utils/createEnumOptions'

export default () => (
	<>
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<OrganizationDataGrid />
		</DataBindingProvider>
	</>
)

const OrganizationDataGrid = Component(
	() => {
		const dataGrid = useDataGrid({
			entities: 'Organization',
			children: React.useMemo(
				() => (<OrganizationCells />), []),
		})

		return (<>
			<ControlledDataGrid
				component={DataGridPageRenderer}
				componentProps={{
					title: 'Škola',
					actions: <>
						<OrganizationExport filter={dataGrid.state.filter} />
						<LinkButton to="admin/organization/create">Vytvořit školu</LinkButton>
					</>,
				}} {...dataGrid} />
		</>)
	},
	() => (<DataGrid entities="Organization"><OrganizationCells /></DataGrid>),
	'OrganizationDataGrid',
)

const OrganizationCells = Component(() => (<>
	<GenericCell shrunk canBeHidden={false}>
		<Stack direction="horizontal" gap="small" align="center">
			<Link to="admin/organization/bulkPointSubmission(organizationId: $entity.id)">
				<Icon blueprintIcon="multi-select" />
			</Link>
			<LinkButton to="admin/organization/detail(id: $entity.id)">
				Otevřít
			</LinkButton>
		</Stack>
	</GenericCell>
	<TextCell key="name" field="name" header="Jméno školy" />
	<NumberCell key="teamCounts" field="teamCounts" header="Nahlášené týmy" />
	<NumberCell field="stats.teamsTotal" header="Celkem týmů" />
	<TextCell key="idNo" field="idNo" header="IČ" />
	<TextCell key="street" field="street" header="Ulice a č.p." />
	<TextCell key="city" field="city" header="Město" />
	<TextCell key="postalCode" field="postalCode" header="PSČ" />
	<EnumCell key="region" field="region" header="Kraj" hidden options={createEnumOptions(regions)} />
	<BooleanCell field="stats.isActiveInCurrentEdition" header="Aktivní v aktuálním ročníku" initialOrder={'desc'} />
	<NumberCell field="stats.walkingTotalPoints" header="Body za docházku" format={decimalFormat} />
	<NumberCell field="stats.accompanyingActivitiesTotalPoints" header="Body za doprovodnou aktivitu" format={decimalFormat} />
	<NumberCell field="stats.teamsTotalPoints" header="Body celkem" format={decimalFormat} />

	<GenericCell shrunk canBeHidden={false}>
		<DeleteEntityButton immediatePersist style={{ color: 'red' }} />
	</GenericCell>
</>))
