import {
	CheckboxField,
	MultiEditPage,
	Repeater,
	SlugField,
	Stack,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../../../components/ImageField'

export default () => {
	return (
		<MultiEditPage
			entities="Quiz"
			rendererProps={{ title: 'Kvízy' }}
		>
			<Stack direction="vertical">
				<TextField field="title" label="Název kvízu" />
				<SlugField field="slug" label="url kvízu" derivedFrom="title" />
				<ImageField field="image" label="Dlaždice na začátek kvízu" />
				<Repeater field="questions" sortableBy="order" label="Otázky">
					<TextField field="question" label="Otázka" />
					<ImageField field="imageQuestion" label="Obrázek k otázce" />
					<Repeater field="options" sortableBy="order" label="Odpovědi">
						<TextField field="response" label="Odpověď" />
						<CheckboxField field="isCorrect" label="Toto je správná odpověď" defaultValue={false} />
						<ImageField field="imageResponse" label="Obrázek k odpovědi" />
					</Repeater>
				</Repeater>
			</Stack>
		</MultiEditPage>
	)
}
