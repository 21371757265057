import * as React from 'react'
import {
	Component,
	EntityListSubTree,
	Field,
	FieldContainer,
	Select,
	TextareaInput,
	useEntityListSubTree,
} from '@contember/admin'
import { ImportCsvFormState } from '../../pages/admin/organization/importCsv'

type ImportCsvFormProps = {
	formState: ImportCsvFormState
	setFormState: React.Dispatch<React.SetStateAction<ImportCsvFormState>>
}

export const ImportCsvForm = Component<ImportCsvFormProps>(({ formState, setFormState }) => {
	const organizations = Array.from(useEntityListSubTree('organizations'))
	return (
		<>
			<FieldContainer label="Škola">
				<Select isSearchable options={organizations.map(organization => ({ label: organization.getField<string>('name').value ?? '', value: organization.idOnServer }))} value={formState.organization} onChange={value => setFormState({ ...formState, organization: value ?? '' })} />
			</FieldContainer>
			<TextareaInput value={formState.csv} onChange={value => setFormState({ ...formState, csv: value ?? '' })} />
		</>
	)
}, () => (<>
	<EntityListSubTree entities="Organization" alias="organizations">
		<Field field="name" />
	</EntityListSubTree>
</>), 'ImportCsvForm')
