import { Component } from '@contember/admin'
import * as React from 'react'


export const DigitalNotice = Component(() => (
	<>
		<div className="bg-blue-600 text-white p-4 rounded-lg">
			<p>Můžete vyplňovat online, ale můžete si i stáhnout PDF s motivační sadou.</p>
		</div>
	</>
), 'DigitalNotice')
