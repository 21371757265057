import { CreatePage, EntityAccessor, EntityListSubTree, EntitySubTree, Field, HasMany, HasOne, HiddenField, PersistButton, Stack, StaticRender, useIdentity } from '@contember/admin'
import * as React from 'react'
import { SelectTeamForTeachers } from '../../components/SelectTeamForTeachers'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { CanAccessBulkPointSubmission } from '../../components/CanAccessBulkPointSubmission'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'


export default () => {
	const identity = useIdentity()
	return (
		<>
			<TeamTitleBar />
			<CreatePage entity={`Team`} rendererProps={{
				title: <>Hromadné zadání bodů</>,
				actions: <PersistButton labelSave="Uložit a přidat další třídu" labelSaved="Uložit a přidat další třídu" />,
			}}>
				<CanAccessBulkPointSubmission
					disabled={<div className="bg-red-100 shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6">
							<div className="max-w-xl text-sm text-gray-500">
								<p>Body lze hromadně zadat až po skončení ročníku.</p>
							</div>
						</div>
					</div>}
				>
					<>
						<div className="bg-white shadow sm:rounded-lg">
							<div className="px-4 py-5 sm:p-6">
								<div className="max-w-xl text-sm text-gray-500">
									<p>Pokud jste body počítaly mimo aplikaci, zda je můžete pro každou třídu rychle doplnit, aby se třída objevila v celkovém žebříčku.</p>
								</div>
							</div>
						</div>
						<Stack direction="horizontal" align="center">
							<SelectTeamForTeachers />
						</Stack>

						<HiddenField field="type" defaultValue="class" />
						<EntitySubTree
							entity={`Organization(users.personId='${identity?.person?.id}')`}
							alias="organizationToConnect"
						>
							<StaticRender>
								<HasMany field={`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
									<Field field="name" />
									<HasOne field="manager">
										<Field field="personId" />
									</HasOne>
								</HasMany>
							</StaticRender>
						</EntitySubTree>
						<EntityListSubTree
							entities={`Edition`}
							alias="editionsToConnect"
							orderBy="startedAt desc"
						>
							<StaticRender>
								<Field field="name" />
								<Field field="startedAt" />
								<Field field="finishedAt" />
							</StaticRender>
						</EntityListSubTree>
						<HasOne
							field="organization"
							onInitialize={(getAccessor, options) => {
								const organizationToConnect = options.getEntitySubTree(
									`organizationToConnect`,
								)
								const parentEntity = getAccessor().getParent()
								if (parentEntity && organizationToConnect) {
									(parentEntity as EntityAccessor).connectEntityAtField(
										'organization',
										organizationToConnect,
									)
								}
							}}
						/>
						<HasOne
							field="edition"
							onInitialize={(getAccessor, options) => {
								const editionsToConnect = Array.from(
									options.getEntityListSubTree(`editionsToConnect`),
								)
								const parentEntity = getAccessor().getParent()
								if (editionsToConnect.length && parentEntity) {
									(parentEntity as EntityAccessor).connectEntityAtField(
										'edition',
										editionsToConnect[0],
									)
								}
							}}
						/>
					</>
				</CanAccessBulkPointSubmission>
			</CreatePage>
		</>
	)
}
