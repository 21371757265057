import { CreatePage, PersistButton } from '@contember/admin'
import * as React from 'react'
import { TeamFormAdminCreate } from '../../../components/forms/TeamFormAdminCreate'

export default () => (
	<>
		<CreatePage entity="Team" rendererProps={{ title: 'Přidat tým', actions: <></> }} redirectOnSuccess="admin/team/edit(id: $entity.id)">
			<div className="form-box">
				<TeamFormAdminCreate />
				<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
			</div>
		</CreatePage>
	</>
)
