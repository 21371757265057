import {
	Field,
	FloatField,
	MultiEditPage,
	Stack,
	StaticRender,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../../../components/ImageField'

export default () => {
	return (
		<MultiEditPage
			entities="AttendanceType"
			rendererProps={{ title: 'Typ docházky', sortableBy: 'order' }}
			onBeforePersist={entityListAccessor => {
				const entities = Array.from(entityListAccessor())
				entities.forEach(entity => {
					if (
						entity.idOnServer &&
						entity.getField('name').value !==
						entity.getField('name').valueOnServer
					) {
						entity.getField('updatedAt').updateValue('now')
					}
				})
			}}
		>
			<Stack
				direction="horizontal"
				onResize={undefined}
				onResizeCapture={undefined}
			>
				<TextField field="name" label="Název" />
				{/* <FloatField field="points" label="Počet bodů" /> */}
			</Stack>
			<ImageField field="icon" label="Ikonka" />
			<StaticRender>
				<Field field="updatedAt" />
			</StaticRender>
		</MultiEditPage>
	)
}
