import * as React from 'react'
import { Field, MultiEditPage, PersistButton, StaticRender, TextField } from '@contember/admin'

export default () => {
	return (
		<MultiEditPage
			entities="EducationalMaterialCategory"
			rendererProps={{
				title: 'Kategorie edukačních materiálů',
				sortableBy: 'order',
				actions: <PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />,
			}}
			onBeforePersist={entityListAccessor => {
				const entities = Array.from(entityListAccessor())
				entities.forEach(entity => {
					if (
						entity.idOnServer &&
						entity.getField('name').value !==
							entity.getField('name').valueOnServer
					) {
						entity.getField('updatedAt').updateValue('now')
					}
				})
			}}
		>
			<TextField field="name" label="Název" />
			<StaticRender>
				<Field field="updatedAt" />
			</StaticRender>
		</MultiEditPage>
	)
}
