import * as React from 'react'
import { Box, Component, DateInput, EditPage, Field, FieldContainer, FieldView, HasMany, Heading, LinkButton, PersistButton, Stack, Table, TableCell, TableHeaderCell, TableRow, useEntity } from '@contember/admin'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../../utils/constant'

export default () => (
	<>
		<EditPage entity="Organization(id=$id)" rendererProps={{ title: 'Upravit soutěžní týden pro školu', actions: '' }}>
			<div><LinkButton to="admin/organization/detail(id: $entity.id)">← Zpět na detail školy</LinkButton></div>
			<OrganizationEditSpecialCompetitionWeek />
			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)

const OrganizationEditSpecialCompetitionWeek = Component(
	() => {
		const [specialWeek, setSpecialWeek] = React.useState<string | undefined>(undefined)
		const entity = useEntity()
		const classes = Array.from(entity.getEntityList(`teams[isDeleted=false && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`))

		React.useEffect(() => {
			if (specialWeek) {
				for (const classEntity of classes) {
					classEntity.getField('specialCompetitionWeek').updateValue(specialWeek)
				}
			} else {
				for (const classEntity of classes) {
					classEntity.getField('specialCompetitionWeek').updateValue(null)
				}
			}
		}, [specialWeek])



		return (<>
			<Box>
				<FieldContainer label="Nastavit speciální soutěžní týden pro všechny třídy" description="Lze zadat kterýkoliv den z daného týdne">
					<DateInput onChange={value => setSpecialWeek(value ?? undefined)} value={specialWeek} />
				</FieldContainer>

				<Heading depth={4} className="mt-6">Dotčené třídy</Heading>
				<Table>
					<TableRow>
						<TableHeaderCell>Jméno třídy</TableHeaderCell>
						<TableHeaderCell>Speciální soutěžní týden</TableHeaderCell>
					</TableRow>
					<HasMany field={`teams[isDeleted=false && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
						<TableRow>
							<TableCell>
								<Field field="name" />
							</TableCell>
							<TableCell>
								<FieldView<string>
									field="specialCompetitionWeek"
									render={({ valueOnServer }) => {
										if (!valueOnServer) {
											return `nenastaveno${specialWeek ? ` (nově ${new Date(specialWeek).toLocaleDateString()})` : ''}`
										} else {
											return `${new Date(valueOnServer).toLocaleDateString()}${specialWeek ? ` (nově ${new Date(specialWeek).toLocaleDateString()})` : ''}`
										}
									}} />
							</TableCell>
						</TableRow>
					</HasMany>
				</Table>
			</Box>
		</>)
	},
	() => (<>
		<HasMany field={`teams[isDeleted=false && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
			<Field field="name" />
			<Field field="specialCompetitionWeek" />
		</HasMany>
	</>),
	'OrganizationEditSpecialCompetitionWeek',
)
