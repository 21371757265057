import * as React from 'react'
import { Component, SelectField, TextField } from '@contember/admin'

export const OrganizationAddress = Component(() => (
	<>
		<SelectField
			field="type"
			label="Typ"
			options={[
				{ value: 'billing', label: 'Fakturační údaje' },
				{ value: 'delivery', label: 'Doručovací údaje' },
			]}
		/>
		<TextField field="name" label="Jméno školy" />
		<TextField field="idNo" label="IČ" />
		<TextField field="street" label="Ulice a č.p." />
		<TextField field="city" label="Město" />
		<TextField field="postalCode" label="PSČ" />
	</>
))
