import * as React from 'react'
import { Component, ControlledDataGrid, DataBindingProvider, DataGrid, DataGridPageRenderer, DeleteEntityButton, FeedbackRenderer, GenericCell, LinkButton, NumberCell, TextCell, useDataGrid } from '@contember/admin'
import { OrganizationExportPrint } from '../../../components/exports/OrganizationExportPrint'

export default () => (
	<>
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<OrganizationDataGrid />
		</DataBindingProvider>
	</>
)

const OrganizationDataGrid = Component(
	() => {
		const dataGrid = useDataGrid({
			entities: 'Organization[stats.isAnalogByPostInCurrentEdition = true]',
			children: React.useMemo(
				() => (<OrganizationCells />), []),
		})

		return (<>
			<ControlledDataGrid
				component={DataGridPageRenderer}
				componentProps={{
					title: 'Školy, které chtějí tištěnou sadu',
					actions: <>
						<OrganizationExportPrint filter={dataGrid.state.filter} />
						<LinkButton to="admin/organization/create">Vytvořit školu</LinkButton>
					</>,
				}} {...dataGrid} />
		</>)
	},
	() => (<DataGrid entities="Organization[stats.isAnalogByPostInCurrentEdition = true]"><OrganizationCells /></DataGrid>),
	'OrganizationDataGrid',
)

const OrganizationCells = Component(() => (<>
	<GenericCell shrunk canBeHidden={false}>
		<LinkButton to="admin/organization/detail(id: $entity.id)">
			Otevřít
		</LinkButton>
	</GenericCell>
	<TextCell key="name" field="name" header="Jméno školy" format={value => <strong>{value}</strong>} />
	<NumberCell key="teamCounts" field="teamCounts" header="Nahlášené týmy" />
	<TextCell key="idNo" field="idNo" header="IČ" />
	<TextCell key="street" field="street" header="Ulice a č.p." />
	<TextCell key="city" field="city" header="Město" />
	<TextCell key="postalCode" field="postalCode" header="PSČ" />
	<GenericCell shrunk canBeHidden={false}>
		<DeleteEntityButton immediatePersist style={{ color: 'red' }} />
	</GenericCell>
</>))
