
import * as React from 'react'
import { Component, FieldContainer, Select, useEntitySubTree, useIdentity, useRedirect } from '@contember/admin'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../utils/constant'

export const SelectTeamForTeachers = Component(
	() => {
		const identity = useIdentity()
		const redirect = useRedirect()
		const organizationToConnect = useEntitySubTree('organizationToConnect')
		return <>
			<FieldContainer label="Výběr existující třídy">
				<Select options={Array.from(organizationToConnect.getEntityList(`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`)).filter(team => team.getEntity('manager').getField('personId').value === identity.person?.id).map(team => ({
					value: team.idOnServer,
					label: team.getField<string>('name').value ?? '',
				}))} onChange={value => value && redirect({ pageName: 'team/bulkPointSubmissionEdit', parameters: { id: value } })} />
			</FieldContainer>
		</>
	},
	() => (<></>),
	'SelectTeam',
)