import * as React from 'react'

import backgroundFooter from '../../assets/images/background-footer.png'
import logoFooter from '../../assets/images/logo_1ks22ctc.svg'
import partnersBanner from '../../assets/images/partnersv3.jpg'

export const GlobalFooter = React.memo(() => {
	return (
		<div
			className="footer bg-white pb-24 bg-[length:650px_auto] bg-repeat-x bg-bottom px-12 py-10"
			style={{ backgroundImage: `url('${backgroundFooter}')` }}
		>
			<div className="flex items-start flex-col md:flex-row gap-5">
				<img className="logo self-start" src={logoFooter} alt="Pešky městem" />
				<span>Provozuje <a target="_blank" className="!underline text-black" href="https://peskymestem.cz/">Pěšky městem, z. s.</a></span>
				<a className="!underline text-black" href="/files/gdpr-pesky-mestem.pdf">Informace o ochraně a zpracování osobních údajů</a>
				<a className="!underline text-black" href="/files/pravidla_souteze.pdf">Pravidla soutěže</a>
				<span>Kontakt: <a className="!underline text-black" href="mailto:marie.civerna@peskymestem.cz">marie.civerna@peskymestem.cz</a></span>
			</div>
			<div>
				<img className="max-w-full mt-6" src={partnersBanner} alt="Partneři" />
			</div>
		</div>
	)
})
GlobalFooter.displayName = 'GlobalFooter'
