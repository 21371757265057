import { CreatePage, EntityAccessor, EntitySubTree, GenericPage, HasOne, PersistButton, useIdentity } from '@contember/admin'
import * as React from 'react'
import { UserFormOrganizationManager } from '../../../components/forms/UserFormOrganizationManager'
import { useInviteUser } from '../../../hooks/useInviteUser'

export default () => {
	const urlParams = new URLSearchParams(window.location.search)
	const organizationIdParam = urlParams.get('organizationId')
	if (!organizationIdParam) {
		return <GenericPage>Není zvolena organizace</GenericPage>
	}
	return (
		<>
			<CreatePage
				entity="User"
				onBeforePersist={useInviteUser()}
				rendererProps={{ title: 'Přidat uživatele k organizaci', actions: <></> }}
				redirectOnSuccess={`admin/organization/detail(id: '${organizationIdParam}')`}
			>
				<div className="form-box">
					<UserFormOrganizationManager />

					<EntitySubTree entity={`Organization(id='${organizationIdParam}')`} alias="organizationToConnect" />
					<HasOne
						field="organization"
						onInitialize={(getAccessor, options) => {
							const organizationToConnect = options.getEntitySubTree(`organizationToConnect`)
							const parentEntity = getAccessor().getParent()
							if (parentEntity && organizationToConnect) {
								(parentEntity as EntityAccessor).connectEntityAtField('organization', organizationToConnect)
							}
						}}
					/>
					<PersistButton distinction="primary" labelSave="Uložit" labelSaved="Uložit" />
				</div>
			</CreatePage>
		</>
	)
}
