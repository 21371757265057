import { DataBindingProvider, EntityListSubTree, FeedbackRenderer, Field, FieldView, GenericPage, HasMany, HasOne, If, Link, LinkButton, Spacer, StaticRender, useIdentity } from '@contember/admin'
import * as React from 'react'
import threeKidsSrc from '../../assets/images/3_deti.png'
import kidsSrc from '../../assets/images/deti.png'
import girlOnScooterSrc from '../../assets/images/holka-s-kolobezkou.png'
import bikeSrc from '../../assets/images/kolo.png'
import mommySrc from '../../assets/images/mamka.png'
import { FeedbackRendererNoSpinner } from '../../components/FeedBackRendererNoSpinner'
import { IsTeamOrClass } from '../../components/IsTeamOrClass'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { decimalFormat } from '../../utils/format'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'

const dayIllustrations: Record<number, string> = {
	1: mommySrc,
	2: threeKidsSrc,
	3: kidsSrc,
	4: girlOnScooterSrc,
	5: bikeSrc,
}

export default () => {
	const identity = useIdentity()
	const daySrc = new Date().getDay()

	return (
		<>
			<TeamTitleBar />
			<GenericPage
				actions={
					<LinkButton to="team/createTeam">
						<DataBindingProvider stateComponent={FeedbackRendererNoSpinner}>
							<IsTeamOrClass teamOutput="Přidat tým" classOutput="Přidat třídu" personId={identity.person?.id} />
						</DataBindingProvider>
					</LinkButton>}
				title={
					<DataBindingProvider stateComponent={FeedbackRendererNoSpinner}>
						<IsTeamOrClass teamOutput="Týmy" classOutput="Třídy" personId={identity.person?.id} />
					</DataBindingProvider >
				}>
				<DataBindingProvider stateComponent={FeedbackRenderer}>
					<h1 className="pt-4 mb-2">Moje třídy</h1>
					<EntityListSubTree entities={`Team[manager.personId='${identity.person?.id}' && isDeleted=false && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
						<div className="flex w-full items-center justify-between p-8 rounded-full bg-white">
							<div>
								<h2 className="!text-black mb-0"><Field field="name" /></h2>
								<p className="text-lg">
									<HasOne field="stats">
										<FieldView<number> field="totalStandardizedPointsWithAccompanyingActivities" render={({ value }) => {
											return <>{value ? decimalFormat(value) : 0}</>
										}} />
									</HasOne> bodů</p>
							</div>
							<div className="">
								<Link to="team/editTeam(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid border-gray-200 hover:bg-gray-50 mr-2">Nastavení</Link>
								<Link to="team/editActivity(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid border-gray-200 hover:bg-gray-50 mr-2">Přidat aktivitu</Link>
								<StaticRender>
									<HasMany field="bulkPointSubmissions" />
								</StaticRender>
								<If condition={accessor => Array.from(accessor.getEntityList('bulkPointSubmissions')).length === 0}>
									<Link to="team/attendanceTeam(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid border-gray-200 hover:bg-gray-50">Zadat docházku</Link>
								</If>
							</div>
						</div>
					</EntityListSubTree>
					<h1 className="pt-4 mb-2">Ostatní třídy ve škole</h1>
					<EntityListSubTree entities={`Team[organization.users.personId='${identity.person?.id}' && isDeleted=false && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
						<If condition="[manager.personId=null]">
							<div className="flex w-full items-center justify-between p-8 rounded-full bg-white">
								<div>
									<h2 className="!text-black mb-0"><Field field="name" /></h2>
									<p className="text-lg">
										<HasOne field="stats">
											<FieldView<number> field="totalStandardizedPointsWithAccompanyingActivities" render={({ value }) => {
												return <>{value ? decimalFormat(value) : 0}</>
											}} />
										</HasOne> bodů</p>
								</div>
							</div>
						</If>
					</EntityListSubTree>
				</DataBindingProvider>

				<Spacer className="flex-grow" />

				<img
					alt=""
					className="block w-full max-w-[640px] mx-auto"
					src={dayIllustrations[daySrc] ?? mommySrc}
				/>
			</GenericPage>
		</>
	)
}
