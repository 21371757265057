import * as React from 'react'
import { Button, Component, Field, HasMany, HasOne, useEntity } from '@contember/admin'
import { regions } from '../../modules/regions'

type EditionSummaryPrepareProps = {
}

export const EditionSummaryPrepare = Component<EditionSummaryPrepareProps>(
	() => {
		const entity = useEntity()
		const teams = entity.getEntityList('teams')
		const regionSumUps = entity.getEntityList('regionSumUps')

		return (
			<>
				<Button
					distinction="toned"
					onClick={() => {
						const results: Array<{
							region: string
							schools: number
							teams: number
							points: number
						}> = regions.map(region => ({
							region: region.value,
							schools: 0,
							teams: 0,
							points: 0,
						}))
						Array.from(teams).forEach(team => {
							const region = team.getField<string>('organization.region').value
							if (region) {
								const points = team.getField<number>('stats.totalStandardizedPointsWithAccompanyingActivities').value ?? 0
								const foundRegion = results.findIndex(r => r.region === region)
								if (foundRegion > -1) {
									results[foundRegion].schools++
									results[foundRegion].teams++
									results[foundRegion].points += points
								}
							}
						})
						results.forEach(result => {
							regionSumUps.createNewEntity(newEntity => {
								newEntity().updateValues({
									region: result.region,
									schoolCount: result.schools,
									classCount: result.teams,
									totalPoints: result.points,
								})
							})
						})
					}}
				>
					Předvyplnit data
				</Button>
			</>
		)
	},
	() => (<>
		<HasMany field="teams">
			<HasOne field="stats">
				<Field field="totalStandardizedPointsWithAccompanyingActivities" />
			</HasOne>
			<HasOne field="organization">
				<Field field="region" />
			</HasOne>
		</HasMany>
	</>),
	'EditionSummaryPrepare',
)
