import { EditPage, useIdentity } from '@contember/admin'
import * as React from 'react'
import { AccompanyingActivityForm } from '../../components/forms/AccompanyingActivityForm'

export default () => {
	const identity = useIdentity()
	return (
		<EditPage entity={`Organization(users.personId='${identity.person?.id}')`} rendererProps={{ title: 'Přidat doprovodnou aktivitu za školu' }}>
			<div className="bg-white shadow sm:rounded-lg">
				<div className="px-4 py-5 sm:p-6">
					<div className="max-w-xl text-sm text-gray-500">
						<p>Můžete zadat doprovodnou aktivitu za celou školu, která se následně propíše k profilu všech tříd.</p>
						<p>Doprovodnou aktivitu lze zadávat také jednotlivě ke konkrétním třídám.</p>
					</div>
				</div>
			</div>
			<AccompanyingActivityForm />
		</EditPage>
	)
}
