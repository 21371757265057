import { EditPage, LayoutPageProps } from '@contember/admin'
import * as React from 'react'
import { AttendanceForm } from '../../../components/forms/AttendanceForm'

export default () => {
	const [formType, setFormType] = React.useState<'simplified' | 'complex'>('simplified')
	const [attendanceNonCompetitive, setAttendanceNonCompetitive] = React.useState(false)

	return (
		<>
			<div className="page-has-school-image-at-top">
				<EditPage
					entity="Team(id=$id)"
					rendererProps={{ title: 'Zadat docházku', pageContentLayout: 'stretch', actions: <></> }}
					refreshDataBindingOnPersist={false}>
					<AttendanceForm
						formType={formType}
						setFormType={setFormType}
						attendanceNonCompetitive={attendanceNonCompetitive}
						setAttendanceNonCompetitive={setAttendanceNonCompetitive}
					/>
				</EditPage>
			</div>
		</>
	)
}
