import { useProjectSlug, useShowToast, EntityAccessor, useAuthedTenantMutation, Membership } from '@contember/admin'
import { useCallback } from 'react'

import { useUpdate } from './useUpdate'

const LIST_MEMBERSHIPS_QUERY = `
	query($slug: String!, $identityId: String!) {
		memberships: projectMemberships(projectSlug: $slug, identityId: $identityId) {
			role
			variables {
				name
				values
			}
		}
	}
`
interface ListUserQueryResult {
	memberships: Membership[]
}

export const useUpdateUser = () => {
	const update = useUpdate()
	const project = useProjectSlug()
	const toast = useShowToast()
	const [getMemberships] = useAuthedTenantMutation<ListUserQueryResult, { slug: string; identityId: string }>(LIST_MEMBERSHIPS_QUERY)

	return useCallback(async (getAccessor: () => EntityAccessor) => {
		const accessor = getAccessor()
		const identityId = accessor.getField<string>('identityId').value
		const role = accessor.getField<string>('role').value
		const isBlocked = accessor.getField<boolean>('isBlocked').value

		if (!project || !identityId || !role) {
			return
		}
		const membershipsResult = await getMemberships({ slug: project, identityId: identityId })
		let memberships = membershipsResult.memberships
		const hasBlockedRole = memberships.find(item => item.role === 'blocked')

		if (isBlocked) {
			if (!hasBlockedRole) {
				memberships.push({ role: 'blocked', variables: [] })
			}
		} else {
			//if (hasBlockedRole) {
			memberships = [{ role: role, variables: [] }]
			//}
		}

		const result = await update({
			identityId: identityId,
			projectSlug: project,
			memberships: memberships,
		})
		if (!result.ok) {
			toast({
				message: `Nepodařilo se upravit uživatele: ${result.error.developerMessage}`,
				dismiss: true,
				type: 'error',
			})
			return
		}
	}, [])
}
