import { Button, Component, Collapsible as ContemberCollapsible, Stack } from '@contember/admin'
import * as React from 'react'

type CollapsibleProps = {
	children: React.ReactNode
	expandText?: string
	collapseText?: string
}

export const Collapsible = Component<CollapsibleProps>(
	props => {
		const [expanded, setExpanded] = React.useState(false)

		return (
			<Stack direction={'vertical'}>
				<ContemberCollapsible expanded={expanded}>
					{props.children}
				</ContemberCollapsible>
				<Button onClick={() => setExpanded(!expanded)}>{expanded ? props?.collapseText ?? 'Skrýt' : props?.expandText ?? 'Zobrazit'}</Button>
			</Stack>
		)
	},
	props => <>{props.children}</>,
	'Collapsible',
)
