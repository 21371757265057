import * as React from 'react'
import { BooleanCell, Component, ControlledDataGrid, DataBindingProvider, DataGrid, DataGridPageRenderer, DeleteEntityButton, EnumCell, FeedbackRenderer, GenericCell, Icon, Link, LinkButton, NumberCell, Stack, TextCell, useDataGrid } from '@contember/admin'
import { OrganizationExport } from '../../../components/exports/OrganizationExport'
import { decimalFormat } from '../../../utils/format'
import { regions } from '../../../modules/regions'
import { createEnumOptions } from '../../../utils/createEnumOptions'

export default () => (
	<>
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<OrganizationDataGrid />
		</DataBindingProvider>
	</>
)

const OrganizationDataGrid = Component(
	() => {
		const dataGrid = useDataGrid({
			entities: 'Organization',
			children: React.useMemo(
				() => (<OrganizationCells />), []),
		})

		return (<>
			<ControlledDataGrid
				component={DataGridPageRenderer}
				componentProps={{
					title: 'Škola',
					actions: <>
						<OrganizationExport filter={dataGrid.state.filter} />
						<LinkButton to="admin/organization/create">Vytvořit školu</LinkButton>
					</>,
				}} {...dataGrid} />
		</>)
	},
	() => (<DataGrid entities="Organization"><OrganizationCells /></DataGrid>),
	'OrganizationDataGrid',
)

const OrganizationCells = Component(() => (<>
	<GenericCell shrunk canBeHidden={false}>
		<Stack direction="horizontal" gap="small" align="center">
			<LinkButton to="admin/organization/detailHistoryArchive(id: $entity.id)">
				Otevřít
			</LinkButton>
		</Stack>
	</GenericCell>
	<TextCell key="name" field="name" header="Jméno školy" />
	<TextCell key="idNo" field="idNo" header="IČ" />
	<TextCell key="street" field="street" header="Ulice a č.p." />
	<TextCell key="city" field="city" header="Město" />
	<TextCell key="postalCode" field="postalCode" header="PSČ" />
	<EnumCell key="region" field="region" header="Kraj" hidden options={createEnumOptions(regions)} />
</>))
