import { CreatePage, EntityAccessor, EntitySubTree, HasOne, PersistButton, useIdentity } from '@contember/admin'
import * as React from 'react'
import { UserFormOrganizationManager } from '../../../components/forms/UserFormOrganizationManager'
import { useInviteUser } from '../../../hooks/useInviteUser'

export default () => {
	const identity = useIdentity()
	return (
		<>
			<CreatePage
				entity="User"
				onBeforePersist={useInviteUser()}
				rendererProps={{ title: 'Přidat uživatele', actions: <></> }}
				redirectOnSuccess="organization/user/edit(id: $entity.id)"
			>
				<div className="form-box">
					<UserFormOrganizationManager />

					<EntitySubTree entity={`Organization(users.personId='${identity?.person?.id}')`} alias="organizationToConnect" />
					<HasOne
						field="organization"
						onInitialize={(getAccessor, options) => {
							const organizationToConnect = options.getEntitySubTree(`organizationToConnect`)
							const parentEntity = getAccessor().getParent()
							if (parentEntity && organizationToConnect) {
								(parentEntity as EntityAccessor).connectEntityAtField('organization', organizationToConnect)
							}
						}}
					/>
					<PersistButton distinction="primary" labelSave="Uložit" labelSaved="Uložit" />
				</div>
			</CreatePage>
		</>
	)
}
