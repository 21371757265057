import { Box, Button, Checkbox, Component, DataBindingProvider, EntityAccessor, EntityListSubTree, FeedbackRenderer, Field, FieldContainer, LayoutChrome, NumberInput, Radio, Select, Stack, TextInput, useEntityListSubTree, useShowToast } from '@contember/admin'
import * as React from 'react'
import detiKonfetySrc from '../../assets/images/deti-konfety.png'
import { useGetCurrentEdition } from '../../hooks/useGetCurrentEdition'
import { useSMap } from '../../hooks/useSMap'
import { useSendRegistrationForm } from '../../hooks/useSendRegistrationForm'
import { regions } from '../../modules/regions'
import { RegisterFormType, isRegisterFormSecondStepValid, isRegisterFormValid, step1ValidationErrors } from '../../modules/register'
import { BillingAddress } from '../register/BillingAddress'
import { DeliveryAddress } from '../register/DeliveryAddress'
import { Questionnaire } from '../register/Questionnaire'

type RegisterIsOpenProps = { children: React.ReactNode; setCurrentEdition: React.Dispatch<React.SetStateAction<EntityAccessor | null>> }

const IsRegisterOpen = Component(
	({ children, setCurrentEdition }: RegisterIsOpenProps) => {
		const editions = useEntityListSubTree('editions')
		const currentEdition = editions ? useGetCurrentEdition(editions) : null

		// disable all registrations
		/*
		return (
			<>
				<h1 className="text-center">Registrace nejsou otevřené</h1>
			</>
		)
		*/

		if (currentEdition && currentEdition.getField('isOpen').value === true) {
			setCurrentEdition(currentEdition)
			return <>{children}</>
		} else {
			return (
				<>
					<h1 className="text-center">Registrace nejsou otevřené</h1>
				</>
			)
		}
	},
	({ children }: RegisterIsOpenProps) => (
		<>
			{children}
			<EntityListSubTree
				entities="Edition"
				alias="editions"
				orderBy="startedAt desc"
			>
				<Field field="startedAt" />
				<Field field="finishedAt" />
				<Field field="isOpen" />
			</EntityListSubTree>
		</>
	),
	'RegisterIsOpen',
)

export const RegisterForm = Component(() => {
	const toast = useShowToast()
	const [isSuggestLoaded, setIsSuggestLoaded] = React.useState(false)
	const [step, setStep] = React.useState<number>(1)
	const [isSubmitting, setIsSubmitting] = React.useState(false)
	const [submitted, setSubmitted] = React.useState(false)
	const [currentEdition, setCurrentEdition] = React.useState<EntityAccessor | null>(null)
	const [validationErrors, setValidationErrors] = React.useState<Record<string, string>>({})
	const [suggestedData, setSuggestedData] = React.useState<any>(undefined)
	const [registerForm, setRegisterForm] = React.useState<RegisterFormType>({
		role: 'organizationManager',
		name: undefined,
		surname: undefined,
		phone: undefined,
		email: undefined,
		team: {
			name: undefined,
			city: undefined,
			region: undefined,
			type: 'team',
		},
		organization: {
			name: undefined,
			idNo: undefined,
			street: undefined,
			city: undefined,
			postalCode: undefined,
			region: undefined,
			longitude: undefined,
			latitude: undefined,
			noClasses: undefined,
		},
		questionnaire: {
			wantToGetInvolved: true,
			howWillYouParticipate: 'digital',
			howDidYouFindOutUs: undefined,
		},
		billingAddress: {
			name: undefined,
			idNo: undefined,
			street: undefined,
			city: undefined,
			postalCode: undefined,
		},
		deliveryAddress: {
			name: undefined,
			idNo: undefined,
			street: undefined,
			city: undefined,
			postalCode: undefined,
		},
		agreement: false,

	})
	useSMap(() => setIsSuggestLoaded(true), { suggest: true })

	const addressFieldRef = React.useRef(null)

	const handleSuggest = (suggestData: any) => {
		setSuggestedData(suggestData)
	}

	React.useEffect(() => {
		if (!suggestedData) {
			return
		}
		const street = suggestedData.phrase
		const secondRowArray = suggestedData.data.secondRow.split(', ')
		let city = ''
		if (secondRowArray.length > 2) {
			city = secondRowArray[1].trim()
		}
		const latitude = suggestedData.data.latitude
		const longitude = suggestedData.data.longitude
		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, street: street, city, latitude, longitude } })
	}, [suggestedData, setRegisterForm])

	React.useEffect(() => {
		if (!isSuggestLoaded || !addressFieldRef.current) {
			return
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const suggest = new window.SMap.Suggest(addressFieldRef.current)
		suggest.urlParams({
			bounds: '48.5370786,12.0921668|51.0746358,18.8927040',
		})

		suggest.addListener('suggest', handleSuggest)
		return () => suggest.removeListener('suggest', handleSuggest)
	}, [isSuggestLoaded, addressFieldRef])

	const sendRegistrationForm = useSendRegistrationForm()

	return (
		<div id="registration-root">
			<LayoutChrome>
				<div className="cui-layout-page-content">
					<div className="cui-layout-page-content-container layout-center">
						<DataBindingProvider stateComponent={FeedbackRenderer}>
							<IsRegisterOpen setCurrentEdition={setCurrentEdition}>
								{submitted ? (
									<div className="py-9 max-w-[800px] mx-auto text-left typo">
										<h1 className="h1 mb-16 text-center">Registrace dokončena</h1>
										<h3 className="h3 mb-16 text-center">
											Do e-mailu jsme vám poslali uvítací e-mail, prosím dokončete registraci kliknutím na odkaz v něm.
										</h3>
										<img
											className="block w-full max-w-[400px] mx-auto"
											src={detiKonfetySrc}
										/>
									</div>
								) : (
									<>
										<header>
											<h2>Registrace</h2>
										</header>

										<main className="registerForm">
											{step === 1 ? (
												<>
													<div className="form-box">
														<Box heading="Kontaktní osoba" distinction="seamless" gap="large" className="floating-labels-box responsive-two-columns-box">
															<FieldContainer label="Jméno kontaktní osoby*" description={validationErrors?.name}>
																<TextInput label="Jméno kontaktní osoby" onChange={value => {
																	setValidationErrors({ ...validationErrors, name: '' })
																	setRegisterForm({ ...registerForm, name: value ? String(value) : undefined })
																}} value={registerForm.name} required={true} />
															</FieldContainer>
															<FieldContainer label="Příjmení kontaktní osoby*" description={validationErrors?.surname}>
																<TextInput label="Příjmení kontaktní osoby" onChange={value => {
																	setValidationErrors({ ...validationErrors, surname: '' })
																	setRegisterForm({ ...registerForm, surname: value ? String(value) : undefined })
																}} value={registerForm.surname} required={true} />
															</FieldContainer>
															<FieldContainer label="Kontaktní telefon*" description={validationErrors?.phone}>
																<TextInput label="Kontaktní telefon" onChange={value => {
																	setValidationErrors({ ...validationErrors, phone: '' })
																	setRegisterForm({ ...registerForm, phone: value ? String(value) : undefined })
																}} value={registerForm.phone} required={true} />
															</FieldContainer>
															<FieldContainer label="Kontaktní e-mail*" description={validationErrors?.email}>
																<TextInput label="Kontaktní e-mail" onChange={value => {
																	setValidationErrors({ ...validationErrors, email: '' })
																	setRegisterForm({ ...registerForm, email: value ? String(value) : undefined })
																}
																} value={registerForm.email} required={true} />
															</FieldContainer>
														</Box>
														{registerForm.role === 'teamManager' &&
															<div className="hide-label-fix">
																<Stack direction="vertical" gap="small">
																	<FieldContainer label="Název týmu*" description={validationErrors?.teamName}>
																		<TextInput label="Název týmu" onChange={value => {
																			setValidationErrors({ ...validationErrors, teamName: '' })
																			setRegisterForm({ ...registerForm, team: { ...registerForm.team, name: value ? String(value) : undefined } })
																		}} value={registerForm.team.name} />
																	</FieldContainer>
																	<Box distinction="seamless" gap="large" className="floating-labels-box responsive-two-columns-box">
																		<FieldContainer label="Město*" description={validationErrors?.teamCity}>
																			<TextInput label="Město" onChange={value => {
																				setValidationErrors({ ...validationErrors, teamCity: '' })
																				setRegisterForm({ ...registerForm, team: { ...registerForm.team, city: value ? String(value) : undefined } })
																			}} value={registerForm.team.city} />
																		</FieldContainer>
																		<FieldContainer label="Kraj*" description={validationErrors?.teamRegion}>
																			<Select options={regions} onChange={value => {
																				setValidationErrors({ ...validationErrors, teamRegion: '' })
																				setRegisterForm({ ...registerForm, team: { ...registerForm.team, region: value ? String(value) : undefined } })
																			}} value={registerForm.team.region} />
																		</FieldContainer>
																	</Box>
																</Stack>
															</div>
														}
														{registerForm.role === 'organizationManager' &&
															<Box heading="Škola" distinction="seamless" gap="large" className="floating-labels-box responsive-two-columns-box">
																<FieldContainer label="Jméno školy*" description={validationErrors?.organizationName}>
																	<TextInput label="Jméno školy" onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationName: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, name: value ? String(value) : undefined } })
																	}} required={true} value={registerForm.organization.name} />
																</FieldContainer>
																<FieldContainer label="IČ*" description={validationErrors?.organizationIdNo}>
																	<TextInput label="IČ" onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationIdNo: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, idNo: value ? String(value) : undefined } })
																	}} required={true} value={registerForm.organization.idNo} />
																</FieldContainer>
																<FieldContainer label="Ulice a č.p.*" className="relative" description={validationErrors?.organizationStreet}>
																	<TextInput ref={addressFieldRef} label="Ulice a č.p." onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationStreet: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, street: value ? String(value) : undefined } })
																	}} value={registerForm.organization.street} required={true} />
																</FieldContainer>
																<FieldContainer label="Město*" description={validationErrors?.organizationCity}>
																	<TextInput label="Město" onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationCity: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, city: value ? String(value) : undefined } })
																	}} value={registerForm.organization.city} required={true} />
																</FieldContainer>
																<FieldContainer label="PSČ*" description={validationErrors?.organizationPostalCode}>
																	<TextInput label="PSČ" onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationPostalCode: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, postalCode: value ? String(value) : undefined } })
																	}} value={registerForm.organization.postalCode} required={true} />
																</FieldContainer>
																<FieldContainer label="Kraj*" description={validationErrors?.organizationRegion}>
																	<Select options={regions} onChange={value => {
																		setValidationErrors({ ...validationErrors, organizationRegion: '' })
																		setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, region: value ? String(value) : undefined } })
																	}} value={registerForm.organization.region} />
																</FieldContainer>
																<FieldContainer label="Kolik tříd se zúčastní?">
																	<NumberInput label="Kolik tříd se zúčastní?" onChange={value => setRegisterForm({ ...registerForm, organization: { ...registerForm.organization, noClasses: value ? value : undefined } })} value={registerForm.organization.noClasses} />
																</FieldContainer>
															</Box>}

														<FieldContainer label="Souhlasím s informacemi o ochraně a zpracování osobních údajů" labelPosition="labelInlineRight">
															<Checkbox
																label="Souhlasím s informacemi o ochraně a zpracování osobních údajů"
																defaultValue={false}
																notNull={true}
																required={true}
																onChange={value => setRegisterForm({ ...registerForm, agreement: Boolean(value) })}
															/>
														</FieldContainer>
														<p className="mt-0">
															<a className="!underline text-black text-sm" target="_blank" href="/files/gdpr-pesky-mestem.pdf">Informace o ochraně a zpracování osobních údajů</a>
															<br />
															<a className="!underline text-black text-sm" target="_blank" href="/files/pravidla_souteze.pdf">Pravidla soutěže</a>
														</p>


														<Button distinction="primary" onClick={() => {
															setValidationErrors({})
															const validationErrors = step1ValidationErrors(registerForm)
															if (Object.keys(validationErrors).length === 0) {
																setStep(2)
															} else {
																setValidationErrors(validationErrors)
															}
														}} disabled={!isRegisterFormValid(registerForm)}>
															Pokračovat
														</Button>
													</div>
												</>
											) : (
												<div className="form-box">
													<Box distinction="seamless" gap="large">
														<Box distinction="seamless" gap="large" className="w-full">
															<Questionnaire registerForm={registerForm} setRegisterForm={setRegisterForm} />
														</Box>
														{registerForm.questionnaire.howWillYouParticipate === 'analogByPost' &&
															<Box distinction="seamless" gap="large">
																<DeliveryAddress registerForm={registerForm} setRegisterForm={setRegisterForm} />
																<BillingAddress registerForm={registerForm} setRegisterForm={setRegisterForm} />
															</Box>}
													</Box>

													<Button distinction="primary" onClick={async () => {
														setIsSubmitting(true)
														const response = await sendRegistrationForm(registerForm, currentEdition)
														setIsSubmitting(false)
														if (response.state === 'error') {
															toast({
																message: response.error,
																dismiss: true,
																type: 'error',
															})
															setStep(1)
														} else {
															setSubmitted(true)
														}
													}} disabled={!isRegisterFormSecondStepValid(registerForm) || isSubmitting}>
														{isSubmitting ? 'Odesílám' : 'Registrovat'}
													</Button>
												</div>
											)}
										</main>
									</>
								)}
							</IsRegisterOpen>
						</DataBindingProvider>
					</div>
				</div>
			</LayoutChrome>
		</div >
	)
}, 'RegisterForm')
