import { Button, Component, Filter, useCurrentContentGraphQlClient } from '@contember/admin'
import * as Papa from 'papaparse'
import * as React from 'react'
import { sanitizeFilter } from '../../utils/filter'

const LIST_TEAM_QUERY = `
	query($filter: TeamWhere) {
		listTeam(filter: $filter) {
			organization {
				name
				region
				city
			}
			name
			category {
				name
			}
			stats {
				totalStandardizedPoints
				accompanyingActivities
				totalStandardizedPointsWithAccompanyingActivities
			}
			manager {
				firstName
				lastName
				email
			}
		}
	}
`

type ListTeamQueryResult = {
	listTeam: Team[]
}

type Team = {
	organization?: {
		name?: string
		region?: string
		city?: string
	}
	category?: {
		name?: string
	}
	name: string
	stats: {
		totalStandardizedPoints?: number
		accompanyingActivities?: number
		totalStandardizedPointsWithAccompanyingActivities?: number
	}
	manager: {
		firstName: string
		lastName: string
		email: string
	}
}

type TeamClassExportProps = {
	filter: Filter
}

export const TeamClassExport = Component<TeamClassExportProps>(
	({ filter }) => {
		const client = useCurrentContentGraphQlClient()
		const [prepareDownload, setPrepareDownload] = React.useState<boolean>(false)
		const [items, setItems] = React.useState<any>(null)
		const [objectUrl, setObjectUrl] = React.useState<string>()
		const sanitizedFilter = React.useMemo(() => sanitizeFilter(filter), [filter])
		const handler = React.useCallback(async () => {
			return await client.sendRequest<ListTeamQueryResult>(LIST_TEAM_QUERY, { variables: { filter: sanitizedFilter } })
		}, [client, sanitizedFilter])

		React.useEffect(() => {
			setObjectUrl(undefined)
			setItems(null)
			setPrepareDownload(false)
		}, [filter])

		React.useEffect(() => {
			if (items) {
				// eslint-disable-next-line no-console
				console.log('items', items)
				const csv = items.data?.listTeam?.map((team: Team) => {
					return [
						team?.organization?.name,
						team?.organization?.region,
						team?.organization?.city,
						team.name,
						team?.category?.name,
						team?.stats?.totalStandardizedPoints ?? 0,
						team?.stats?.accompanyingActivities ?? 0,
						team?.stats?.totalStandardizedPointsWithAccompanyingActivities ?? 0,
						team.manager ? `${team.manager.firstName} ${team.manager.lastName}` : '',
						team?.manager?.email ?? '',
					]
				})

				csv.unshift([
					'Jméno školy',
					'Region',
					'Město',
					'Název třídy',
					'Kategorie týmu',
					'Body za docházku',
					'Body za doprovodnou aktivitu',
					'Body celkem',
					'Jméno a příjmení',
					'E-mail',
				])

				const myCsv = Papa.unparse(csv, { delimiter: ';' })
				const blob = new Blob([myCsv], { type: 'text/csv;charset=utf-8;' })
				const url = URL.createObjectURL(blob)
				setObjectUrl(url)
				return () => {
					URL.revokeObjectURL(url)
				}
			}
		}, [items])

		if (objectUrl) {
			return (
				<a href={objectUrl} download="classes.csv">
					<Button distinction="outlined">Stáhnout export</Button>
				</a>
			)
		} else {
			return (
				<Button
					onClick={async () => {
						setPrepareDownload(true)
						setTimeout(async () => setItems(await handler()), 1500)
					}}
					distinction="outlined"
					loading={prepareDownload}
				>
					Exportovat
				</Button>
			)
		}
	},
	() => null,
	'TeamClassExport',
)
