import { DataBindingProvider, FeedbackRenderer, FieldContainer, GenericPage, Select, Stack, useRedirect } from '@contember/admin'
import * as React from 'react'
import girlTalk from '../../assets/images/holcicka.png'
import { GoogleMaps } from '../../components/GoogleMaps'
import { regions } from '../../modules/regions'
import { OrganizationList } from '../../components/OrganizationList'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'

export default () => {
	const redirect = useRedirect()


	const urlParams = new URLSearchParams(window.location.search)
	const regionParam = urlParams.get('region')
	const pageParam = urlParams.get('page')

	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<DataBindingProvider stateComponent={FeedbackRenderer}>
					<div>
						<Stack direction="horizontal" align="center" justify="space-between" className="header-with-search my-8">
							<h1 className="my-0">Zúčastněné školy</h1>
							<FieldContainer label="Kraj">
								<Select options={regions} value={regionParam} onChange={value => value ? redirect({ pageName: 'view/list', parameters: { region: value } }) : redirect('view/list')} />
							</FieldContainer>
						</Stack>

						<GoogleMaps region={regionParam ?? undefined} />
						<div className="flex justify-center items-center">
							<div>
								<div className="text-center bg-white p-2 relative -top-4 rounded-br-none text-black rounded-xl">Výsledné pořadí se může změnit podle počtu bodů za doprovodnou aktivitu, které uděluje pořadatel.</div>
							</div>
							<img src={girlTalk} width="80" />
						</div>
						<OrganizationList region={regionParam ?? undefined} page={pageParam ? Number(pageParam) : undefined} />
					</div>

				</DataBindingProvider>
			</GenericPage>
		</>
	)
}
