import * as React from 'react'
import { DataBindingProvider, useIdentity, useProjectUserRoles } from '@contember/admin'
import { FeedbackRendererNoSpinner } from './FeedBackRendererNoSpinner'
import { IsTeamOrClass } from './IsTeamOrClass'

export const LoggedAs = () => {
	const identity = useIdentity()
	const roles = useProjectUserRoles()
	let loggedAsRole: string | undefined = undefined
	if (roles.has('admin')) {
		loggedAsRole = 'správce'
	} else if (roles.has('organizationManager')) {
		loggedAsRole = 'škola'
	} else if (roles.has('teamManager')) {
		loggedAsRole = 'třída'
		return (<div className="fixed bottom-1 left-1 text-xs">Přihlášen jako: <strong><DataBindingProvider stateComponent={FeedbackRendererNoSpinner}><IsTeamOrClass teamOutput="tým" classOutput="třída" personId={identity.person?.id} /></DataBindingProvider></strong></div>)
	}
	return (<div className="text-xs mb-3">Přihlášen jako: <strong>{loggedAsRole}</strong></div>)
}
