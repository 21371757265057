/* eslint-disable keyword-spacing */
import { DataBindingProvider, EntityListSubTree, FeedbackRenderer, Field, FieldView, GenericPage, HasOne, LinkButton, StaticRender } from '@contember/admin'
import * as React from 'react'
import pesSrc from '../../assets/images/pes.png'
import ptakSrc from '../../assets/images/ptak.png'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'

export default () => (
	<>
		<TeamTitleBar />
		<GenericPage>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<h2 className="mb-0 pt-4">Materiály</h2>
				<img className="block max-h-[100px] ml-auto" src={ptakSrc} />

				<div>
					<EntityListSubTree entities="EducationalMaterialCategory" orderBy="order">
						<FieldView
							fields={['name', 'id']}
							render={({ value: name }, { value: id }) => (
								<LinkButton className="w-auto mr-2" to={{ pageName: 'team/materialsCategory', parameters: { id: id as string } }}>{name as string}</LinkButton>
							)}
						/>
					</EntityListSubTree>
				</div>
				<img className="block w-full max-w-[200px] mr-auto" src={pesSrc} />
			</DataBindingProvider>
		</GenericPage>
	</>
)
