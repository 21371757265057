import { DataBindingProvider, EntityListSubTree, FeedbackRenderer, FieldView, GenericPage, LinkButton } from '@contember/admin'
import * as React from 'react'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { MaterialCategoryFiles } from '../../components/MaterialCategoryFiles'

export default () => (
	<>
		<TeamTitleBar />
		<GenericPage>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<h2 className="mb-0 pt-4">Materiály</h2>
				<div>
					<EntityListSubTree entities="EducationalMaterialCategory" orderBy="order">
						<FieldView
							fields={['name', 'id']}
							render={({ value: name }, { value: id }) => (
								<LinkButton className="w-auto mr-2" to={{ pageName: 'team/materialsCategory', parameters: { id: id as string } }}>{name as string}</LinkButton>
							)}
						/>
					</EntityListSubTree>
				</div>
				<MaterialCategoryFiles headline="Dokumenty" type="file" />
				<MaterialCategoryFiles headline="Videa" type="video" />
			</DataBindingProvider>
		</GenericPage>
	</>
)
