import { Button, EditPage, HasOne, StaticRender } from '@contember/admin'
import * as React from 'react'
import { UserFormOrganizationManagerEdit } from '../../../components/forms/UserFormOrganizationManagerEdit'
import { useUpdateUser } from '../../../hooks/useUpdateUser'
import { RemoveUserButton } from '../../../components/RemoveUserButton'

export default () => (
	<>
		<EditPage entity="User(id=$id)" onBeforePersist={useUpdateUser()} rendererProps={{ title: 'Upravit uživatele' }}>
			<div className="form-box">
				<UserFormOrganizationManagerEdit />
			</div>
			<StaticRender>
				<HasOne field="organization" />
			</StaticRender>
			<div className="form-box">
				<h3>Odepnutí uživatele od instituce je nevratné</h3>
				<div className="inline-flex mx-auto">
					<RemoveUserButton />
				</div>
			</div>
		</EditPage>
	</>
)
