import * as React from 'react'
import { Component, FieldContainer, TextInput } from '@contember/admin'
import { RegisterFormType } from '../../modules/register'

type BillingAddressProps = {
	registerForm: RegisterFormType
	setRegisterForm: React.Dispatch<React.SetStateAction<RegisterFormType>>
}

export const BillingAddress = Component(({ registerForm, setRegisterForm }: BillingAddressProps) => (
	<FieldContainer label="Fakturační údaje">
		<FieldContainer label="Jméno školy">
			<TextInput label="Jméno školy" value={registerForm.billingAddress.name} onChange={value => setRegisterForm({ ...registerForm, billingAddress: { ...registerForm.billingAddress, name: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="IČ">
			<TextInput label="IČ" value={registerForm.billingAddress.idNo} onChange={value => setRegisterForm({ ...registerForm, billingAddress: { ...registerForm.billingAddress, idNo: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="Ulice a č.p.">
			<TextInput label="Ulice a č.p." value={registerForm.billingAddress.street} onChange={value => setRegisterForm({ ...registerForm, billingAddress: { ...registerForm.billingAddress, street: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="Město">
			<TextInput label="Město" value={registerForm.billingAddress.city} onChange={value => setRegisterForm({ ...registerForm, billingAddress: { ...registerForm.billingAddress, city: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="PSČ">
			<TextInput label="PSČ" value={registerForm.billingAddress.postalCode} onChange={value => setRegisterForm({ ...registerForm, billingAddress: { ...registerForm.billingAddress, postalCode: value ? String(value) : undefined } })} />
		</FieldContainer>
	</FieldContainer>
))
