import { TitleBar } from '@contember/admin'
import * as React from 'react'
import { logo } from '../Logo'
import { LogoutButton, LogoutLink, Navigation } from '../Navigation'
import { LoggedAs } from '../LoggedAs'

export const TeamTitleBar = React.memo(() => {
	return (
		<div className="extra-title-bar">
			<TitleBar
				navigation={<a href="/">{logo}</a>}
				actions={<LogoutButton />}
				after={<><LoggedAs /><Navigation layout="team" /></>}
			>{false}</TitleBar>
		</div>
	)
})
