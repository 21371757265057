import * as React from 'react'
import { DataGridPage, DeleteEntityButton, GenericCell, Icon, Link, LinkButton, NumberCell, TextCell } from '@contember/admin'
import { TeamTeamExport } from '../../../components/exports/TeamTeamExport'
import { decimalFormat } from '../../../utils/format'

export default () => (
	<>
		<DataGridPage
			entities="Team"
			itemsPerPage={50}
			rendererProps={{ title: 'Týmy a třídy' }}
		>
			<GenericCell shrunk canBeHidden={false}>
				<LinkButton to="admin/team/attendanceEdit(id: $entity.id)">
					Spravovat docházku
				</LinkButton>
			</GenericCell>
			<TextCell field="name" header="Název týmu" />
			<NumberCell field="stats.totalStandardizedPoints" header="Body za docházku" format={decimalFormat} />
			<NumberCell field="stats.accompanyingActivities" header="Body za doprovodnou aktivitu" format={decimalFormat} />
		</DataGridPage>
	</>
)
