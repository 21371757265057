import * as React from 'react'
import {
	Box,
	CheckboxField,
	Component,
	DateField,
	HasOne,
	HiddenField,
	If,
	LocationField,
	NumberField,
	Repeater,
	SelectField,
	StaticRender,
	TextField,
} from '@contember/admin'
import { ImageField } from '../ImageField'
import { regions } from '../../modules/regions'

export const TeamFormAdminCreate = Component(() => {
	return (
		<>
			<TextField field="name" label="Jméno týmu" required />
			<SelectField field="edition" label="Ročník" options="Edition.name" />
			<SelectField
				field="organization"
				label="Škola"
				options="Organization.name"
			/>
			<CheckboxField
				field="isApproved"
				label="Je tým schválen?"
				defaultValue={false}
				notNull={true}
			/>
			<SelectField
				field="category"
				label="Kategorie týmu"
				options="TeamCategory.name"
			/>
			<HiddenField field="type" defaultValue="class" />

			<DateField field="specialCompetitionWeek" label="Speciální soutěžní týden pro tým" description="Lze zadat kterýkoliv den z daného týdne" />
			<Box title="Doprovodná aktivita">
				<NumberField
					field="accompanyingActivityPoints"
					label="Body týmu za doprovodnou aktivitu"
					defaultValue={0}
					min={0}
					max={10}
				/>
				<Repeater
					field="accompanyingActivities"
					orderBy="id"
					label="Dokumentace doprovodné aktivity"
					initialEntityCount={0}
				>
					<TextField field="name" label="Název aktivity" required />
					<Repeater field="attachments" sortableBy="order" label="Fotografie">
						<ImageField field="image" />
					</Repeater>
				</Repeater>
			</Box>
			<If condition={entity => entity.getAccessor().getField('type').value === 'team'}>
				<TextField field="city" label="Město" />
				<SelectField field="region" label="Kraj" options={regions} required />
				<NumberField field="numberOfAdults" label="Počet dospělých" />
				<NumberField field="numberOfChildren" label="Počet dětí" />

				<LocationField latitudeField="latitude" longitudeField="longitude" label="Lokalita" />
				<CheckboxField field="isLocationPublic" label="Zobrazovat na veřejné mapě" />
			</If>
			<If condition={entity => entity.getAccessor().getField('type').value === 'class'}></If>
			<Repeater field="teamMembers" sortableBy="order" label="TeamMember">
				<TextField field="name" label="Jméno" />
			</Repeater>
			<StaticRender>
				<HasOne field="manager"></HasOne>
			</StaticRender>
		</>
	)
}, 'TeamFormAdminCreate')
