import * as React from 'react'
import { EditPage, LinkButton, PersistButton } from '@contember/admin'
import { OrganizationForm, OrganizationSideForm } from '../../../components/forms/OrganizationForm'

export default () => (
	<>
		<EditPage entity="Organization(id=$id)" rendererProps={{ title: 'Upravit školu', side: <OrganizationSideForm />, actions: '' }}>
			<div><LinkButton to="admin/organization/detail(id: $entity.id)">← Zpět na detail školy</LinkButton></div>
			<OrganizationForm />
			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)
