import * as React from 'react'
import { DataGridPage, EnumCell, GenericCell, HasOneSelectCell, Icon, Link, TextCell } from '@contember/admin'
import { userRoles } from '../../../modules/userRoles'
import { ActiveOrganizationManagerExport } from '../../../components/exports/ActiveOrganizationManagerExport'

export default () => (
	<>
		<DataGridPage entities="User" itemsPerPage={50} rendererProps={{ title: 'Správci', actions: <ActiveOrganizationManagerExport /> }}>
			<GenericCell shrunk canBeHidden={false}>
				<Link to="admin/user/edit(id: $entity.id)">
					<Icon blueprintIcon="edit" />
				</Link>
			</GenericCell>
			<TextCell field="lastName" header="Příjmení" />
			<TextCell field="firstName" header="Jméno" />
			<TextCell key="email" field="email" header="E-mail" />
			<EnumCell
				field="role"
				header="Role"
				options={Object.fromEntries(userRoles.map(role => [role.value, role.label]))}
			/>
			<HasOneSelectCell field="organization" header="Škola" options="Organization.name" />
			{/* TODO: cascade <GenericCell>
				<DeleteEntityButton immediatePersist />
			</GenericCell> */}
		</DataGridPage>
	</>
)
