import { EditPage, EntityListSubTree, Field, HasMany, HasOne, PersistButton, StaticRender } from '@contember/admin'
import * as React from 'react'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { CanAccessBulkPointSubmission } from '../../components/CanAccessBulkPointSubmission'
import { BulkPointSubmissionEditForm } from '../../components/forms/BulkPointSubmissionEditForm'


export default () => {
	return (
		<>
			<TeamTitleBar />
			<EditPage entity={`Team(id=$id)`} rendererProps={{
				title: <>Hromadné zadání bodů k existující třídě</>,
				actions: <></>,
			}}>
				<CanAccessBulkPointSubmission
					disabled={<div className="bg-red-100 shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6">
							<div className="max-w-xl text-sm text-gray-500">
								<p>Body lze hromadně zadat až po skončení ročníku.</p>
							</div>
						</div>
					</div>}
				>
					<>
						<BulkPointSubmissionEditForm />

						<StaticRender>
							<HasOne field="stats">
								<Field field="totalPoints" />
							</HasOne>
							<HasMany field="bulkPointSubmissions">
								<Field field="date" />
								<Field field="totalPresences" />
								<Field field="totalPoints" />
							</HasMany>
						</StaticRender>
						<EntityListSubTree
							entities={`Edition`}
							alias="editionsToConnect"
							orderBy="startedAt desc"
						>
							<StaticRender>
								<Field field="name" />
								<Field field="startedAt" />
								<Field field="finishedAt" />
							</StaticRender>
						</EntityListSubTree>
						<PersistButton labelSave="Uložit" labelSaved="Uložit" />
					</>
				</CanAccessBulkPointSubmission>
			</EditPage>
		</>
	)
}
