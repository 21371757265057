import { EditPage, Field, FieldView, LinkButton, PersistButton } from '@contember/admin'
import * as React from 'react'
import { AccompanyingActivityForm } from '../../components/forms/AccompanyingActivityForm'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'

export default () => (
	<>
		<TeamTitleBar />
		<EditPage entity="Team(id=$id)" rendererProps={{
			title: (<>Upravit <Field field="name" /></>), actions: <>
				<LinkButton to="team/attendanceTeam(id: $entity.id)">Zadat docházku</LinkButton>
				<LinkButton to="team/editActivity(id: $entity.id)"><FieldView field="type" render={({ value }) => value === 'team' ? 'Upravit tým' : 'Upravit třídu'} /></LinkButton>
			</>,
		}} >
			<div className="form-box">
				<h2>Doprovodné aktivity</h2>
				<AccompanyingActivityForm />
				<PersistButton labelSave="Uložit" labelSaved="Uložit" />
			</div>
		</EditPage>
	</>
)
