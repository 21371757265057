import { GenericPage, useProjectUserRoles } from '@contember/admin'
import * as React from 'react'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'


export default () => {
	const roles = useProjectUserRoles()

	const HelpText = () => {
		if (roles.has('admin')) {
			return (<><h1>Návod pro webovou aplikaci</h1>
				<p>V následujícím návodu vám ukážeme, jak spravovat celou soutěž jako administrátor.</p>
				<h2>Registrace</h2>
				<ol>
					<li><strong>Nastavení hesla</strong>: Obdrželi jste pozvánku pro administrátora. Je potřeba si nastavit heslo. Poté už můžete začít administrovat celou soutěž.</li>
				</ol>
				<h2>Ročníky</h2>
				<ol>
					<li><strong>Otevření registrace</strong>: Aby se lidé mohli registrovat, musí být otevřená registrace. V sekci <strong>Ročníky</strong> tedy zadejte nový ročník. Ten má název, zda je registrace otevřena (on/off) a od kdy do kdy ročník funguje. Od kdy - do kdy je myšleno tak, že to je ten daný týden, kdy lidé soutěží (takže “od” by mělo být pondělí daného týdne, kdy zadávají docházku).</li>
				</ol>
				<h2>Typ docházky</h2>
				<ol>
					<li><strong>Správa typů docházky</strong>: V typu docházky spravujete jednotlivé typy docházky. Z těch si pak uživatelé při zadávání docházky vybírají.</li>
					<li><strong>Název a počet bodů</strong>: Každý typ docházky má název a počet bodů. Počet bodů můžete v průběhu soutěže měnit, body se přepočítají (ale bude to matoucí pro uživatele).</li>
					<li><strong>Ikonka</strong>: Každý typ docházky má i ikonku, může to být jakýkoli obrázek, ale nahrávejte jej prosím ve čtvercovém formátu.</li>
				</ol>
				<h2>Kategorie týmu</h2>
				<ol>
					<li><strong>Kategorie týmu</strong>: Pro třídy můžete zadat kategorie týmu, ze kterých správci vybírají.</li>
				</ol>
				<h2>Správa</h2>
				<p>V kategorii <strong>Správa</strong> vidíte všechny zaregistrované školy, třídy, týmy a správce. Jako administrátor můžete cokoli změnit.</p>
				<ol>
					<li><strong>Speciální soutěžní týden pro tým</strong>: Najdete ji v editaci týmu a tím můžete pro daný tým změnit, na který týden zadávají docházku.</li>
					<li><strong>Hodnocení doprovodných aktivit</strong>: V editaci týmu také vidíte jednotlivé doprovodné aktivity pro hodnocení [TBD].</li>
					<li><strong>Zadávání docházky</strong>: Pokud by vám někdo volal, že se mu nedaří zadat docházku / poslal papír, za každý tým můžete zadat docházku, obdobně jako to dělají učitelé / týmy.</li>
					<li><strong>Zablokování správce</strong>: Pokud potřebujete nějakého správce zrušit, můžete ho zablokovat. Pak nezmizí ze systému aktivity, které již dělal (tím by mohl narušit soutěž), ale nebude se moct již přihlásit a cokoli dělat. </li>
				</ol>
				<h2>Materiály</h2>
				<ol>
					<li><strong>Přidávání materiálů ke stažení</strong>: Zde můžete zadávat materiály ke stažení a prostudování uživatelům.</li>
					<li><strong>Kategorie materiálů</strong>: Materiály se třídí do kategorií, ty můžete nastavit v Kategorie.</li>
				</ol></>)
		} else {
			return (<>
				<h1 id="n-vod-pro-webovou-aplikaci">Návod pro webovou aplikaci</h1>
				<p>V následujícím návodu vám ukážeme, jak se zaregistrovat, přihlásit a používat naši webovou aplikaci Pěšky do školy. V aplikaci můžete zadávat docházku dětí během akce Pěšky do školy i doprovodné aktivity, které budete s dětmi podnikat. Najdete tam také motivační sadu a další materiály ke stažení.</p>
				<p>Registrovat se mohou <strong>školy</strong> i jednotlivé třídy (i když se registrujete jako jediná třída ze školy, musíte nejdříve zaregistrovat školu).</p>
				<h2 id="registrace-koly">Registrace školy</h2>
				<ol>
					<li><p><strong>Vyplnění registračního formuláře</strong>: nejprve vyplňte registrační formulář. Zadejte orientační počet tříd, které se akce zúčastní. Upozorňujeme, že jedna škola může být zaregistrována pouze jednou.</p>
					</li>
					<li><p>V dalším kroku nám řekněte, zda se budete účastnit soutěže a jestli máte zájem o tištěnou motivační sadu.</p>
					</li>
					<li><p><strong>Potvrzení e-mailu a nastavení hesla</strong>: pro dokončení registrace je potřeba potvrdit e-mail a nastavit si heslo pro přístup do aplikace.</p>
					</li>
					<li><p><strong>Přihlášení</strong>: teď se můžete přihlásit do aplikace.</p>
					</li>
					<li><p>Pokud jste se zúčastnili výzvy loni, tak vám funguje stávající účet a škola, ale musíte vyplnit přihlášku do letošního ročníku.</p>
					</li>
				</ol>
				<h2 id="po-p-ihl-en-">Po přihlášení</h2>
				<p>Po přihlášení uvidíte svou úvodní stránku (Dashboard). Zatím tu nic není, a tak můžete buď přizvat kolegy, kteří si sami vytvoří své třídy, nebo začít přidávat třídy.</p>
				<h3 id="nastaven-koly">Nastavení školy</h3>
				<p>Můžete upravovat nastavení školy, například nahrát obrázek, který se použije na veřejných stránkách vaší školy s průběžnými výsledky. Do nastavení se dostanete přes tlačítko <strong>Moje škola</strong> na Dashboardu.</p>
				<h2 id="p-id-n-koleg-">Přidání kolegů</h2>
				<p>Své kolegy přidáte pod záložkou <strong>Správci</strong>. <strong>Správce třídy</strong> bude zadávat docházku dětí. Může jím být třídní učitel, třídy mohou mít i společného správce. <strong>Správce školy</strong> bude mít přístup do všech tříd školy. Škola může mít více správců školy.</p>
				<ol>
					<li><p><strong>Jednotlivé pozvání</strong>: kolegy můžete přidat po jednom přes tlačítko <strong>Pozvat správce</strong>.</p>
					</li>
					<li><p><strong>Hromadná pozvánka</strong>: pokud potřebujete přidat více kolegů najednou, použijte funkci Hromadná pozvánka, kde stačí zadat na každý řádek email (například vykopírovat z Excelu) a všem se pošle pozvánka do aplikace přímo do profilu vaší školy.</p>
					</li>
					<li><p><strong>Někteří kolegové budou mít už přístupy z loňského roku. Odebrali jsme všem přístup do škol, takže je musíte pozvat znovu, ale přístupové údaje jim zůstávají stejné.</strong></p>
					</li>
					<li><p><strong>Přehled správců</strong>: přehled všech přidaných kolegů pak vidíte v sekci <strong>Správci</strong>.</p>
					</li>
				</ol>
				<h3 id="p-id-n-t-dy">Přidání třídy</h3>
				<p>Každá třída, která se bude účastnit akce Pěšky do školy musí mít svoji stránku, kde se bude zadávat docházka dětí. Třídu přidává správce školy na záložce Dashboard, nebo si vlastní třídu založí jednotliví správci třídy.</p>
				<ol>
					<li><p><strong>Zadejte název třídy a kategorii</strong></p>
					</li>
					<li><p><strong>Přiřaďte třídě správce</strong>: i vaši kolegové vidí všechny třídy ve vaší škole.</p>
					</li>
					<li><p><strong>Upravte nastavení třídy</strong>: zadejte křestní jména nebo přezdívky dětí. Můžete přidat také profilový obrázek třídy.</p>
					</li>
				</ol>
				<p>Během akce Pěšky do školy bude správce třídy zadávat <strong>docházku dětí</strong> a nahrávat <strong>doprovodné aktivity</strong>.</p>
				<h3 id="materi-ly-ke-sta-en-">Materiály ke stažení</h3>
				<p>V sekci <strong>Materiály ke stažení</strong> naleznete celou motivační sadu (například plakáty, kde děti mohou docházku vyplňovat ručně) a další osvětové a výukové materiály.</p>
				<h2 id="zad-v-n-doch-zky">Zadávání docházky</h2>
				<p>Docházku můžete zadávat v průběhu výzvy (doporučujeme) a nebo po jejím skončení hromadně. Zadávání se zaktivní v den začátku výzvy.</p>
				<p>Třídy zadávají docházku ve svém profilu. U každého člena třídy  vyplňte, jaký způsob dopravy v daný den použil při cestě do školy -- pěšky, na kole, na koloběžce nebo veřejnou dopravou je za 1 bod, autem za 0 bodů. Pokud jede dítě autem, ale jde do školy alespoň 5 minut nebo 300 m pěšky, získává 0,5 bodu. Dítě, které do školy daný den nejde, vyplní symbol A. Aplikace automaticky počítá součet bodů, který zohledňuje počet dětí ve třídě a absence. Nezapomeňte doplnit doprovodné aktivity, můžete za ně získat až 30 bodů, které uděluje pořadatel. Pokud chcete pro akci Pěšky do školy zvolit jiný termín, kontaktujte pořadatele.</p>
				<p><strong>Doprovodné aktivity nově můžete přidávat i pro všechny třídy ve škole najednou.</strong></p>
				<p>Po skončení pěšího tdne nemusíte nic odesílat, pořadatel vidí veškeré aktuálně zadané informace.</p>

			</>)
		}
	}

	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<div id="view-help">
					<HelpText />
				</div>
			</GenericPage>
		</>
	)
}
