import { Layout as ContemberLayout, Link } from '@contember/admin'
import * as React from 'react'
import { logo } from './Logo'

import { LogoutLink } from './Navigation'
import { LoggedAs } from './LoggedAs'

export const LayoutBlocked = () => {

	return <ContemberLayout
		sidebarHeader={<a href="/view/list">{logo}</a>}
		sidebarFooter={<><LoggedAs /><LogoutLink /></>}
		children={<h1 className="text-center py-6">Byli jste zablokování administrátorem</h1>}
	/>
}
