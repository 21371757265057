import * as React from 'react'
import { EditPage, Field, FieldView, FloatField, HasMany, Heading, If, LinkButton, PersistButton } from '@contember/admin'

export default () => (
	<>
		<EditPage entity="Team(id=$id)" rendererProps={{ title: <>Doprovodné aktivity: <Field field="name" /> (<Field field="organization.name" />)</>, actions: <><LinkButton to="admin/team/activitiesList">Zpět na doprovodné aktivity</LinkButton></> }}>
			<div className="flex space-x-4">
				<FloatField field="accompanyingActivityPoints" label="Počet bodů za doprovodné aktivity" />
				<div className="mt-5"><PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" /></div>
			</div>
			<Heading depth={2}>Doprovodné aktivity za třídu</Heading>
			<HasMany field="accompanyingActivities">
				<div className="bg-white bg-opacity-40 rounded-xl p-4">
					<p><Field field="name" /></p>
					<HasMany field="attachments">
						<FieldView field="image.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
						<FieldView field="file.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
					</HasMany>
				</div>
			</HasMany>
			<If condition={entity => Array.from(entity.getEntityList('accompanyingActivities')).length === 0}>
				<p className="text-center">Nejsou vloženy doprovodné aktivity za třídu.</p>
			</If>
			<Heading depth={2} className="mt-10">Doprovodné aktivity za školu</Heading>
			<HasMany field="organization.accompanyingActivities">
				<div className="bg-white bg-opacity-40 rounded-xl p-4">
					<p><Field field="name" /></p>
					<HasMany field="attachments">
						<FieldView field="image.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
						<FieldView field="file.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
					</HasMany>
				</div>
			</HasMany>
			<If condition={entity => Array.from(entity.getEntityList('organization.accompanyingActivities')).length === 0}>
				<p className="text-center">Nejsou vloženy doprovodné aktivity za školu.</p>
			</If>
		</EditPage>
	</>
)
