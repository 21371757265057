
import * as React from 'react'
import { Component, EntitySubTree, useEntitySubTree } from '@contember/admin'

type IsTeamOrClassProps = {
	teamOutput: React.ReactNode
	classOutput: React.ReactNode
	personId?: string
}

export const IsTeamOrClass = Component(({ teamOutput, classOutput }: IsTeamOrClassProps) => {
	const organizationToConnect = useEntitySubTree('organizationToConnect')
	if (organizationToConnect.idOnServer) {
		return <>{classOutput}</>
	} else {
		return <>{teamOutput}</>
	}
}, ({ personId }: IsTeamOrClassProps) => (<>
	<EntitySubTree
		entity={`Organization(users.personId='${personId}')`}
		alias="organizationToConnect"
	/>
</>), 'IsTeamOrClass')
