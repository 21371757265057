import * as React from 'react'
import { Component, EntityAccessor, EntityListSubTree, Field, useEntityListSubTree } from '@contember/admin'
import { isAfter, parseISO } from 'date-fns'

type CanAccessBulkPointSubmissionProps = {
	children: React.ReactElement
	disabled?: React.ReactElement
}

export const CanAccessBulkPointSubmission = Component<CanAccessBulkPointSubmissionProps>(
	({ children, disabled }) => {
		const currentEditions = useEntityListSubTree('currentEdition')
		let currentEdition: EntityAccessor | undefined = undefined
		if (Array.from(currentEditions).length > 0) {
			currentEdition = Array.from(currentEditions)[0]
		}

		if (!currentEdition) {
			return null
		}

		const finishedAt = currentEdition.getField<string>('finishedAt').value
		if (finishedAt && isAfter(new Date(), parseISO(finishedAt))) {
			return children
		} else {
			return disabled || null
		}
	},
	({ children }) => (
		<>
			<EntityListSubTree
				entities={`Edition`}
				alias="currentEdition"
				orderBy="startedAt desc"
			>
				<Field field="startedAt" />
				<Field field="finishedAt" />
			</EntityListSubTree>
			{children}
		</>
	),
	'CanAccessBulkPointSubmission',
)
