import {
	Component,
	Field,
	HasOne,
	IdentityContext,
	If,
	RadioField,
} from '@contember/admin'
import * as React from 'react'
import { AnalogByPostNotice } from '../partials/howWillYouParticipate/AnalogByPostNotice'
import { AnalogPrintSelfNotice } from '../partials/howWillYouParticipate/AnalogPrintSelfNotice'
import { DigitalNotice } from '../partials/howWillYouParticipate/DigitalNotice'

export const OrganizationQuestionnaire = Component(
	() => {
		const ctx = React.useContext(IdentityContext)
		return (
			<HasOne field="questionnaire">
				<RadioField
					field="wantToGetInvolved"
					label="Chceme se zapojit do soutěže*"
					options={[
						{ value: true, label: 'Ano' },
						{ value: false, label: 'Ne, budeme chodit jen tak' },
					]}
					defaultValue={true}
				/>
				<RadioField
					field="howWillYouParticipate"
					label="Jakým způsobem chcete zaznamenávat cesty do školy?"
					options={[
						{ value: 'digital', label: 'Digitálně v aplikaci' },
						{
							value: 'analogPrintSelf',
							label: 'Na papír (vystiskneme si sami)',
						},
						{
							value: 'analogByPost',
							label: 'Objednat originální papírovou motivační sadu (500,-, objednat do 15. 6.)',
						},
					]}
					defaultValue={'digital'}
				/>
				<RadioField
					field="howDidYouFindOutUs"
					label="Jak jste se o výzvě Pěšky do školy dozvěděli?*"
					options={[
						{ value: 'involvedRegularly', label: 'Účastníme se pravidelně' },
						{ value: 'direct', label: 'Oslovili nás přímo z Pěšky městem' },
						{ value: 'parents', label: 'S iniciativou přisli rodiče' },
						{ value: 'city', label: 'Od obce nebo městské části' },
						{ value: 'socialNetworks', label: 'Ze sociálních sítí' },
						{ value: 'media', label: 'Z médií' },
					]}
				/>
				<If
					condition={entity =>
						entity.getAccessor().getField('howWillYouParticipate').value ===
						'digital' && !ctx
					}
				>
					<DigitalNotice />
				</If>
				<If
					condition={entity =>
						entity.getAccessor().getField('howWillYouParticipate').value ===
						'analogPrintSelf' && !ctx
					}
				>
					<AnalogPrintSelfNotice />
				</If>
				<If
					condition={entity =>
						entity.getAccessor().getField('howWillYouParticipate').value ===
						'analogByPost' && !ctx
					}
				>
					<AnalogByPostNotice />
				</If>
			</HasOne>
		)
	},
	() => (
		<HasOne field="questionnaire">
			<Field field="wantToGetInvolved" />
			<Field field="howDidYouFindOutUs" />
			<Field field="howWillYouParticipate" />
		</HasOne>
	),
	'OrganizationQuestionnaire',
)
