import { Field, MultiEditPage, PersistButton, StaticRender, TextField } from '@contember/admin'
import * as React from 'react'

export default () => {
	return (
		<MultiEditPage
			entities="TeamCategory"
			rendererProps={{ title: 'Kategorie týmu', sortableBy: 'order', actions: <PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" /> }}
			onBeforePersist={entityListAccessor => {
				const entities = Array.from(entityListAccessor())
				entities.forEach(entity => {
					if (entity.idOnServer && entity.getField('name').value !== entity.getField('name').valueOnServer) {
						entity.getField('updatedAt').updateValue('now')
					}
				})
			}}
		>
			<TextField field="name" label="Název" />
			<StaticRender>
				<Field field="updatedAt" />
			</StaticRender>
		</MultiEditPage>
	)
}
