import * as React from 'react'
import { EditPage, PersistButton } from '@contember/admin'
import { UserFormAdmin } from '../../../components/forms/UserFormAdmin'
import { useUpdateUser } from '../../../hooks/useUpdateUser'

export default () => (
	<>
		<EditPage
			entity="User(id=$id)"
			onBeforePersist={useUpdateUser()}
			rendererProps={{ title: 'Upravit uživatele', actions: '' }}
		>
			<UserFormAdmin />
			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)
