import * as React from 'react'
import { Component, EntityListSubTree, Field, FieldView, HasOne, useEntityListSubTree } from '@contember/admin'


type MaterialCategoryFilesProps = {
	type: 'video' | 'file'
	headline?: string
}

export const MaterialCategoryFiles = Component<MaterialCategoryFilesProps>(
	({ type, headline }) => {
		const materials = useEntityListSubTree(`materials_${type}`)

		if (Array.from(materials).length === 0) {
			return null
		}

		return (<>
			{headline && <h3 className="mt-4 mb-0">{headline}</h3>}
			<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
				<EntityListSubTree entities={`EducationalMaterial[category.id=$id && type=${type}]`}>
					<div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
						<div className="flex flex-1 flex-col px-8 pt-8 pb-4">
							<h3 className="mt-6 text-sm font-medium text-gray-900"><Field field="name" /></h3>
							<HasOne field="category">
								<span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"><Field field="name" /></span>
							</HasOne>
						</div>
						<div>
							<div className="flex bg-gray-100 rounded-br-lg rounded-bl-lg">
								<div className="-ml-px flex w-0 flex-1">
									<FieldView field="type" render={({ value }) => {
										if (value === 'video') {
											return (
												<FieldView field="youtubeVideo" render={({ value }) => {
													return (
														<a className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" target="_blank" href={value as string}>Otevřít video</a>
													)
												}} />
											)
										} else {
											return (
												<HasOne field="file">
													<FieldView field="url" render={({ value }) => {
														return (
															<a className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" target="_blank" href={value as string}>Stáhnout</a>
														)
													}} />
												</HasOne>
											)
										}
									}} />
								</div>
							</div>
						</div>
					</div>
				</EntityListSubTree>
			</div>
		</>)
	},
	({ type }) => (<>
		<EntityListSubTree entities={`EducationalMaterial[category.id=$id && type=${type}]`} alias={`materials_${type}`}>
			<Field field="name" />
			<Field field="category.name" />
			<Field field="type" />
			<HasOne field="file">
				<Field field="url" />
			</HasOne>
			<Field field="youtubeVideo" />
		</EntityListSubTree>
	</>))
