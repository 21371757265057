import { Button, Component, Filter, useCurrentContentGraphQlClient } from '@contember/admin'
import * as Papa from 'papaparse'
import * as React from 'react'
import { sanitizeFilter } from '../../utils/filter'

const LIST_ORGANIZATION_QUERY = `
	query($filter: OrganizationWhere) {
		listOrganization(filter: $filter) {
			name
			idNo
			street
			city
			postalCode
			users (filter: { role: { eq: organizationManager }, phone: { isNull: false } }, limit: 1) {
				firstName
				lastName
				phone
				email
			}
		}
	}
`

type ListOrganizationQueryResult = {
	listOffer: Organization[]
}

type Organization = {
	name: string
	idNo: string
	street?: string
	city?: string
	postalCode?: string
	users: {
		firstName: string
		lastName: string
		phone: string
		email: string
	}[]
}

type OrganizationExportPrintProps = {
	filter: Filter
}

export const OrganizationExportPrint = Component<OrganizationExportPrintProps>(
	({ filter }) => {
		const client = useCurrentContentGraphQlClient()
		const [prepareDownload, setPrepareDownload] = React.useState<boolean>(false)
		const [items, setItems] = React.useState<any>(null)
		const [objectUrl, setObjectUrl] = React.useState<string>()
		const sanitizedFilter = React.useMemo(() => sanitizeFilter(filter), [filter])
		const handler = React.useCallback(async () => {
			return await client.sendRequest<ListOrganizationQueryResult>(LIST_ORGANIZATION_QUERY, { variables: { filter: sanitizedFilter } })
		}, [client, sanitizedFilter])

		React.useEffect(() => {
			if (items) {
				const csv = items.data?.listOrganization?.map((organization: Organization) => {
					return [
						organization.name,
						organization.idNo,
						organization.street,
						organization.city,
						organization.postalCode,
						organization.users[0] ? `${organization.users[0].firstName} ${organization.users[0].lastName}` : '',
						organization.users[0] ? `${organization.users[0].email}` : '',
						organization.users[0] ? `${organization.users[0].phone}` : '',
					]
				})

				csv.unshift([
					'Jméno školy',
					'IČ',
					'Ulice a č.p.',
					'Město',
					'PSČ',
					'Jméno a příjmení správce',
					'E-mail správce',
					'Telefon správce',
				])

				const myCsv = Papa.unparse(csv, { delimiter: ';' })
				const blob = new Blob([myCsv], { type: 'text/csv;charset=utf-8;' })
				const url = URL.createObjectURL(blob)
				setObjectUrl(url)
				return () => {
					URL.revokeObjectURL(url)
				}
			}
		}, [items])

		if (objectUrl) {
			return (
				<a href={objectUrl} download="organizations.csv">
					<Button distinction="outlined">Stáhnout export</Button>
				</a>
			)
		} else {
			return (
				<Button
					onClick={async () => {
						setPrepareDownload(true)
						setTimeout(async () => setItems(await handler()), 1500)
					}}
					distinction="outlined"
					loading={prepareDownload}
				>
					Exportovat
				</Button>
			)
		}
	},
	() => null,
	'OrganizationExportPrint',
)
