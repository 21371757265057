import { DataBindingProvider, EntityListSubTree, FeedbackRenderer, Field, FieldView, GenericPage, HasOne, LinkButton, StaticRender } from '@contember/admin'
import * as React from 'react'
import klukImage from '../../assets/images/kluk-do-appky.png'

export default () => (
	<GenericPage>
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<h2 className="mb-0 pt-4">Materiály</h2>
			<div>
				<EntityListSubTree entities="EducationalMaterialCategory" orderBy="order">
					<FieldView
						fields={['name', 'id']}
						render={({ value: name }, { value: id }) => (
							<LinkButton className="w-auto mr-2" to={{ pageName: 'organization/materialsCategory', parameters: { id: id as string } }}>{name as string}</LinkButton>
						)}
					/>
				</EntityListSubTree>
			</div>
			<div className="max-w-5xl">
				<img src={klukImage} className="w-full" />
			</div>
		</DataBindingProvider>
	</GenericPage>
)
