import * as React from 'react'
import { ReactNode } from 'react'
import { Layout as ContemberLayout } from '@contember/admin'
import { GlobalFooter } from './partials/footer'

export const LayoutView = (props: { children?: ReactNode }) => {
	return (
		<>
			<ContemberLayout children={props.children} />
			<GlobalFooter />
		</>
	)
}
