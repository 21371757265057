import * as React from 'react'
import { EditPage, Field, FieldContainer, FieldView, HasMany, PersistButton, Repeater, Stack } from '@contember/admin'
import { format, parseISO } from 'date-fns'

export default () => (
	<>
		<EditPage entity="Team(id=$id)" rendererProps={{ title: 'Upravit docházku týmu dle členů', actions: <></> }}>
			<HasMany field="teamMembers" orderBy="order asc">
				<h3><Field field="name" /></h3>
				<Repeater field="attendances" orderBy="date asc" label="Docházka" enableRemoving={true} enableAddingNew={false}>
					<Stack direction="horizontal">
						<FieldContainer label="Datum"><FieldView field="date" render={({ value }) => format(parseISO(String(value)), 'd. M. yyyy')} /></FieldContainer>
						< FieldContainer label="Přítomen" > <FieldView field="wasPresent" render={({ value }) => value ? 'Ano' : 'Ne'} /></FieldContainer>
						<FieldContainer label="Typ docházky"><Field field="attendanceType.name" /></FieldContainer>
					</Stack>
				</Repeater>
			</HasMany>
			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)
