import * as React from 'react'
import { ReactNode } from 'react'
import {
	Component,
	EntityListSubTree,
	Field,
	useEntityListSubTree,
} from '@contember/admin'

import { useGetCurrentEdition } from '../hooks/useGetCurrentEdition'

export const OnlyIfCanAddTeamsForCurrentEdition = Component(
	(props: { children?: ReactNode; closed?: ReactNode }) => {
		const editions = useEntityListSubTree('editions')
		const currentEdition = useGetCurrentEdition(editions)
		if (currentEdition.getField('isOpenForAddingTeams').value === false) {
			return (
				<>
					{props?.closed}
				</>
			)
		} else {
			return <>{props?.children}</>
		}
	},
	(props: { children?: ReactNode }) => (
		<>
			{props?.children}
			<EntityListSubTree
				entities="Edition"
				alias="editions"
				orderBy="startedAt desc"
			>
				<Field field="startedAt" />
				<Field field="finishedAt" />
				<Field field="isOpenForAddingTeams" />
			</EntityListSubTree>
		</>
	),
	'OnlyIfCanAddTeamsForCurrentEdition',
)
