import { EntityId, useAuthedContentMutation } from '@contember/admin'
import { useCallback } from 'react'

type MutationPayload = { organization: { connect: { id: EntityId } } }

export const useUpdateContentUser = () => {
	const [updateUserMutation] = useAuthedContentMutation<{ updateUser: { ok: boolean; errorMessage: string | null } }, { by: { personId: string }, data: MutationPayload }>(
		`mutation UpdateUser($by: UserUniqueWhere!, $data: UserUpdateInput!) {
			updateUser(by: $by, data: $data) {
				ok
				errorMessage
			}
		}
		`,
	)

	return useCallback(
		(variables: { by: { personId: string }, data: MutationPayload }) => {
			return updateUserMutation(variables)
		},
		[updateUserMutation],
	)
}
