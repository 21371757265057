import * as React from 'react'
import { DataBindingProvider, EntityList, EntitySubTree, FeedbackRenderer, Field, GenericPage, HasMany, HasOne, If, Table, TableCell, TableHeaderCell, TableRow, useRedirect } from '@contember/admin'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'
import { decimalFormat } from '../../utils/format'
import { regions } from '../../modules/regions'

export default () => {
	const redirect = useRedirect()
	const urlParams = new URLSearchParams(window.location.search)
	if (!urlParams.get('edition')) {
		redirect('view/list')
	}

	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<DataBindingProvider stateComponent={FeedbackRenderer}>
					<EntitySubTree entity="Edition(id=$edition)">
						<If condition="[areResultsPublic=false]">
							<h1 className="text-center pt-8">
								Výsledky nejsou zveřejněny
							</h1>
						</If>
						<If condition="[areResultsPublic=true]">
							<h1 className="text-center pt-8">
								Výsledky
							</h1>
							<Table>
								<TableRow>
									<TableHeaderCell></TableHeaderCell>
									<TableHeaderCell>Počet škol</TableHeaderCell>
									<TableHeaderCell>Počet tříd</TableHeaderCell>
									<TableHeaderCell>Body celkem</TableHeaderCell>
								</TableRow>
								<HasMany field="regionSumUps" orderBy="totalPoints desc">
									<TableRow>
										<TableCell><Field field="region" format={value => value ? regions.find(it => it.value === value)?.label : ''} /></TableCell>
										<TableCell><Field field="schoolCount" /></TableCell>
										<TableCell><Field field="classCount" /></TableCell>
										<TableCell><Field<number> field="totalPoints" format={value => value ? decimalFormat(value) : 0} /></TableCell>
									</TableRow>
								</HasMany>
								<HasOne field="stats">
									<TableRow >
										<TableCell><span className="font-bold">Celkový součet</span></TableCell>
										<TableCell><span className="font-bold"><Field field="schoolCount" /></span></TableCell>
										<TableCell><span className="font-bold"><Field field="classCount" /></span></TableCell>
										<TableCell><span className="font-bold"><Field<number> field="totalPoints" format={value => value ? decimalFormat(value) : 0} /></span></TableCell>
									</TableRow>
								</HasOne>
							</Table>
						</If>

					</EntitySubTree >
				</DataBindingProvider >
			</GenericPage >
		</>
	)
}
