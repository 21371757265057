import * as React from 'react'
import {
	CheckboxField,
	Component,
	Field,
	RadioField,
	SelectField,
	StaticRender,
	TextField,
} from '@contember/admin'
import { userRoles } from '../../modules/userRoles'

export const UserFormAdmin = Component(() => (
	<>
		<TextField field="firstName" label="Jméno" required />
		<TextField field="lastName" label="Příjmení" required />
		<TextField field="phone" label="Kontaktní telefon" required />
		<TextField field="email" label="Kontaktní e-mail" required disabled />
		<SelectField
			field="organization"
			label="Škola"
			options="Organization.name"
			allowNull
		/>
		<RadioField field="role" label="Role" options={userRoles.filter(it => it.value !== 'admin')} />
		<CheckboxField field="isBlocked" label="Zablokovat uživatele?" notNull />
		<StaticRender>
			<Field field="personId" />
			<Field field="identityId" />
		</StaticRender>
	</>
))
