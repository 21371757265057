import * as React from 'react'
import { Button, DataBindingProvider, EntityId, GenericPage, useAuthedContentMutation, useShowToast } from '@contember/admin'
import { ImportCsvForm } from '../../../components/forms/ImportCsvForm'
import { FeedbackRendererNoSpinner } from '../../../components/FeedBackRendererNoSpinner'
import { parse } from 'papaparse'

export type ImportCsvFormState = { organization: EntityId | undefined; csv: string | undefined }

export default () => {
	const toast = useShowToast()
	const dates = [
		new Date('2023-09-18').toISOString(),
		new Date('2023-09-19').toISOString(),
		new Date('2023-09-20').toISOString(),
		new Date('2023-09-21').toISOString(),
		new Date('2023-09-22').toISOString(),
	]

	type MutationPayload = {
		teams: {
			create: {
				name: string
				type: string
				accompanyingActivities?: [{ create: { name: string } }]
				bulkPointSubmissions: {
					create: {
						date: string
						totalPoints: number
						totalPresences: number
					}
				}[]
			}
		}[]
	}

	const [formState, setFormState] = React.useState<ImportCsvFormState>({ organization: undefined, csv: undefined })
	const [createClassesMutation] = useAuthedContentMutation<{ updateOrganization: { ok: boolean; errorMessage: string | null } }, { by: { id: EntityId }; data: MutationPayload }>(
		`mutation UpdateOrganization($by: OrganizationUniqueWhere!, $data: OrganizationUpdateInput!) {
			updateOrganization(by: $by, data: $data) {
				ok
				errorMessage
			}
		}
		`,
	)

	const handleSubmit = async () => {
		if (formState.csv) {
			const data = parse(formState.csv)
			const dataToImport: MutationPayload['teams'] = []
			data.data.forEach((row, index) => {
				const rowTyped = row as string[]
				if (index > 1 && rowTyped[3]) {
					dataToImport.push({
						create: {
							name: rowTyped[3],
							type: 'class',
							bulkPointSubmissions: [
								{
									create: {
										date: dates[0],
										totalPoints: parseFloat(rowTyped[5].replace(',', '.')) ? parseFloat(rowTyped[5].replace(',', '.')) : 0,
										totalPresences: parseInt(rowTyped[4]) ? parseInt(rowTyped[4]) : 0,
									},
								},
								{
									create: {
										date: dates[1],
										totalPoints: parseFloat(rowTyped[7].replace(',', '.')) ? parseFloat(rowTyped[7].replace(',', '.')) : 0,
										totalPresences: parseInt(rowTyped[6]) ? parseInt(rowTyped[6]) : 0,
									},
								},
								{
									create: {
										date: dates[2],
										totalPoints: parseFloat(rowTyped[9].replace(',', '.')) ? parseFloat(rowTyped[9].replace(',', '.')) : 0,
										totalPresences: parseInt(rowTyped[8]) ? parseInt(rowTyped[8]) : 0,
									},
								},
								{
									create: {
										date: dates[3],
										totalPoints: parseFloat(rowTyped[11].replace(',', '.')) ? parseFloat(rowTyped[11].replace(',', '.')) : 0,
										totalPresences: parseInt(rowTyped[10]) ? parseInt(rowTyped[10]) : 0,
									},
								},

								{
									create: {
										date: dates[4],
										totalPoints: parseFloat(rowTyped[13].replace(',', '.')) ? parseFloat(rowTyped[13].replace(',', '.')) : 0,
										totalPresences: parseInt(rowTyped[12]) ? parseInt(rowTyped[12]) : 0,
									},
								},
							],
							...(rowTyped[14] ? {
								accompanyingActivities: [
									{
										create: {
											name: rowTyped[14],
										},
									},
								],
							} : {}),
						},
					})
				}

			})
			const response = await createClassesMutation({
				by: {
					id: formState.organization ?? '',
				},
				data: { teams: dataToImport },
			})
			if (response.updateOrganization.ok) {
				toast({ type: 'success', message: 'Import proběhl úspěšně' })
				setFormState({ organization: undefined, csv: undefined })
			} else {
				toast({ type: 'error', message: 'Import proběhl úspěšně' })
			}
		}
	}

	return (
		<>
			<GenericPage>
				<DataBindingProvider stateComponent={FeedbackRendererNoSpinner}>
					<ImportCsvForm formState={formState} setFormState={setFormState} />
					<Button onClick={() => handleSubmit()}>Import</Button>
				</DataBindingProvider>
			</GenericPage >

		</>
	)
}
