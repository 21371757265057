import { EditPage, Repeater, SelectField, TextField, useIdentity } from '@contember/admin'
import * as React from 'react'
import { OrganizationAddress } from '../../components/forms/OrganizationAddress'
import { OrganizationQuestionnaire } from '../../components/forms/OrganizationQuestionnaire'
import { ImageField } from '../../components/ImageField'
import { regions } from '../../modules/regions'

export default () => {
	const identity = useIdentity()
	return (
		<EditPage entity={`Organization(users.personId='${identity.person?.id}')`} rendererProps={{ title: 'Upravit školu' }}>
			<TextField field="name" label="Jméno školy" />
			<ImageField field="coverImage" label="Obrázek pro výpisy" />
			<TextField field="idNo" label="IČ" />
			<TextField field="street" label="Ulice a č.p." />
			<TextField field="city" label="Město" />
			<TextField field="postalCode" label="PSČ" />
			<SelectField field="region" label="Kraj" options={regions} required />
			<OrganizationQuestionnaire />
			<Repeater label="Adresy" field="addresses" orderBy={undefined} initialEntityCount={0} enableAddingNew={false} enableRemoving={false}>
				<OrganizationAddress />
			</Repeater>
		</EditPage>
	)
}
