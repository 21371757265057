import {
	Button,
	ContemberClient,
	Divider,
	ErrorList,
	I18nProvider,
	Icon,
	IdentityProvider,
	IDP,
	IDPInitButton,
	IDPResponseHandler,
	Link,
	MessageDictionaryByLocaleCode,
	MiscPageLayout,
	Page,
	Pages,
	Project,
	ProjectListButtons,
	RequestProvider,
	RoutingContext,
	RoutingContextValue,
	Stack,
	StyleProvider,
	TitleBar,
	Toaster,
	ToasterProvider,
	useLogout,
	useOptionalIdentity,
} from '@contember/admin'
import * as React from 'react'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { logo } from '../Logo'
import { Login } from '../login/Login'
import { CreateResetPasswordRequestForm } from '../login/CreateResetPasswordRequestForm'
import { ResetPasswordForm } from '../login/ResetPasswordForm'
import { FillResetPasswordTokenForm } from '../login/FillResetPasswordTokenForm'

export interface LoginEntrypointProps {
	apiBaseUrl: string
	loginToken: string
	sessionToken?: string
	basePath?: string
	projects: readonly string[] | (() => Promise<readonly string[]>)
	identityProviders?: readonly IDP[]
	formatProjectUrl: (project: Project) => string
	heading?: string
	projectsPageActions?: ReactNode
	collapsedEmailLogin?: boolean
	defaultLocale?: string
	dictionaries?: MessageDictionaryByLocaleCode
}

const indexPageName = 'index'
const resetRequestPageName = 'resetRequest'
const redirectOnSuccessPageName = 'resetRequestSuccess'
const passwordResetPageName = 'passwordReset'

const loginToolbar = (
	<TitleBar
		navigation={logo}
		children={null}
	/>
)

export const LoginEntrypoint = (props: LoginEntrypointProps) => {
	const routing: RoutingContextValue = {
		basePath: props.basePath ?? '/',
		routes: {},
		defaultDimensions: {},
		pageInQuery: true,
	}

	return (
		<ContemberClient
			apiBaseUrl={props.apiBaseUrl}
			sessionToken={props.sessionToken}
			loginToken={props.loginToken}
		>
			<StyleProvider>
				<div id="login-root">
					<I18nProvider
						localeCode={props.defaultLocale}
						dictionaries={props.dictionaries}
					>
						<ToasterProvider>
							<RoutingContext.Provider value={routing}>
								<RequestProvider>
									<Pages>
										<Page name={indexPageName}>
											<IdentityProvider allowUnauthenticated={true}>
												<LoginEntrypointIndex
													projects={props.projects}
													formatProjectUrl={props.formatProjectUrl}
													identityProviders={props.identityProviders}
													heading={props.heading}
													projectsPageActions={props.projectsPageActions}
													collapsedEmailLogin={props.collapsedEmailLogin}
												/>
											</IdentityProvider>
										</Page>
										<Page name={resetRequestPageName}>
											<MiscPageLayout
												gap="large"
												heading="Reset hesla"
												actions={
													<>
														<Link to={indexPageName}>
															&larr; Zpět na přihlášení
														</Link>
													</>
												}
											>
												{logo}
												<CreateResetPasswordRequestForm
													redirectOnSuccess={redirectOnSuccessPageName}
												/>
											</MiscPageLayout>
										</Page>
										<Page name={redirectOnSuccessPageName}>
											<MiscPageLayout
												gap="large"
												heading="Reset hesla"
												actions={
													<>
														<Link to={indexPageName}>
															&larr; Zpět na přihlášení
														</Link>
													</>
												}
											>
												{logo}
												<FillResetPasswordTokenForm resetLink={`${passwordResetPageName}(token: $token)`} />
											</MiscPageLayout>
										</Page>
										<Page name={passwordResetPageName}>
											{({ token }: { token: string }) => (
												<MiscPageLayout
													gap="large"
													heading="Nastavte nové heslo"
													actions={
														<>
															<Link to={indexPageName}>
																&larr; Zpět na přihlášení
															</Link>
														</>
													}
												>
													{logo}
													<ResetPasswordForm
														token={token}
														redirectOnSuccess={indexPageName}
													/>
												</MiscPageLayout>
											)}
										</Page>
									</Pages>
								</RequestProvider>
							</RoutingContext.Provider>
							<Toaster />
						</ToasterProvider>
					</I18nProvider>
				</div>
			</StyleProvider>
		</ContemberClient>
	)
}

const LoginEntrypointIndex: FC<
	Pick<
		LoginEntrypointProps,
		| 'projects'
		| 'formatProjectUrl'
		| 'identityProviders'
		| 'heading'
		| 'projectsPageActions'
		| 'collapsedEmailLogin'
	>
> = props => {
	const logout = useLogout()
	const identity = useOptionalIdentity()
	const [projectSlugs, setProjectSlugs] = useState<readonly string[]>()
	const projectsProvider = props.projects
	useEffect(() => {
		(async () => {
			if (identity === undefined) {
				return
			}
			setProjectSlugs(
				projectsProvider instanceof Function
					? await projectsProvider()
					: projectsProvider,
			)
		})()
	}, [identity, projectsProvider])

	const projects = useMemo(() => {
		return identity?.projects.filter(it => projectSlugs?.includes(it.slug))
	}, [identity?.projects, projectSlugs])

	if (identity === undefined) {
		return (
			<>
				<MiscPageLayout>
					{logo}
					<LoginContainer
						identityProviders={props.identityProviders}
						collapsedEmailLogin={props.collapsedEmailLogin}
					/>
					<Divider />
					<Stack
						direction="horizontal"
						justify="center"
						onResize={undefined}
						onResizeCapture={undefined}
					>
						<a href="/register">Registrace</a>
					</Stack>
				</MiscPageLayout>
			</>
		)
	} else if (projects === undefined) {
		return (
			<MiscPageLayout
				heading="Projects"
				actions={
					<>
						{logo}
						{props.projectsPageActions}
						<Button
							onClick={() => logout()}
							size={'small'}
							distinction={'seamless'}
						>
							<Icon blueprintIcon={'log-out'} />
						</Button>
					</>
				}
			>
				Načítám projekty...
			</MiscPageLayout>
		)
	} else if (projects.length === 1) {
		window.location.href = props.formatProjectUrl(projects[0])
		return null
	} else {
		return (
			<MiscPageLayout
				heading="Projects"
				actions={props.projectsPageActions}
				footerActions={
					<Button onClick={() => logout()} distinction="seamless" flow="block">
						Odhlásit <Icon blueprintIcon="log-out" />
					</Button>
				}
			>
				{logo}
				<ProjectListButtons
					projects={projects}
					formatProjectUrl={props.formatProjectUrl}
				/>
			</MiscPageLayout>
		)
	}
}

interface LoginContainerProps {
	identityProviders?: readonly IDP[]
	collapsedEmailLogin?: boolean
}

const LoginContainer = ({
	identityProviders,
	collapsedEmailLogin: initialCollapsedEmailLogin,
}: LoginContainerProps) => {
	const [collapsedEmailLogin, setCollapsedEmailLogin] = useState(
		initialCollapsedEmailLogin ?? false,
	)
	const [error, setError] = useState<string>()
	const onLoginHandler = useCallback(() => {
		const params = new URLSearchParams(window.location.search)
		const backlink = params.get('backlink')
		if (backlink) {
			const resolvedBacklink = new URL(backlink, window.location.href)
			if (resolvedBacklink.origin === window.location.origin) {
				window.location.href = resolvedBacklink.toString()
			}
		}
	}, [])

	const hasOauthResponse = useMemo(() => {
		const params = new URLSearchParams(window.location.search)
		return params.has('state') && (params.has('code') || params.has('id_token'))
	}, [])

	if (hasOauthResponse) {
		return <IDPResponseHandler onLogin={onLoginHandler} />
	}

	return (
		<>
			<ErrorList errors={error ? [{ message: error }] : []} />
			{!collapsedEmailLogin && (
				<Login resetLink={resetRequestPageName} onLogin={onLoginHandler} />
			)}
			{((identityProviders?.length ?? 0) > 0 || collapsedEmailLogin) && (
				<Stack
					direction="vertical"
					onResize={undefined}
					onResizeCapture={undefined}
				>
					{identityProviders?.map((it, i) => (
						<IDPInitButton key={i} provider={it} onError={setError} />
					))}
					{collapsedEmailLogin && (
						<Button onClick={() => setCollapsedEmailLogin(false)}>
							Přihlásit se e-mailem
						</Button>
					)}
				</Stack>
			)}
		</>
	)
}
