import { Component, CreatePage, EntityAccessor, EntityListSubTree, Field, FieldContainer, FloatField, HasMany, HasOne, HiddenField, NumberField, PersistButton, Repeater, Select, Stack, StaticRender, TextField, useEntity, useEntitySubTree, useRedirect } from '@contember/admin'
import * as React from 'react'
import { addDays, format, parse, parseISO, startOfWeek } from 'date-fns'
import { ConnectEntity } from '../../../components/ConnectEntity'


export default () => (
	<CreatePage entity={`Team`} rendererProps={{
		title: <>Hromadné zadání bodů</>,
		actions: <PersistButton labelSave="Uložit a přidat další třídu" labelSaved="Uložit a přidat další třídu" />,
	}}>
		<Stack direction="horizontal" align="center">
			<SelectTeam />
			<span className="text-black text-xs pt-4 inline-flex">nebo</span>
			<TextField field="name" label="Jméno třídy" required />
		</Stack>
		<Repeater field="bulkPointSubmissions" label={undefined} orderBy="date" initialEntityCount={0} enableAddingNew={false} enableRemoving={false} onInitialize={(accessor, options) => {
			const editionsToConnect = Array.from(
				options.getEntityListSubTree(`editionsToConnect`),
			)
			if (editionsToConnect.length) {
				const currentEditionStartedAt = editionsToConnect[0].getField<string>('startedAt').value
				if (currentEditionStartedAt) {
					const dateFormat = 'y-MM-dd'
					const date = format(parseISO(currentEditionStartedAt), dateFormat)
					const startOfWeekDate = startOfWeek(parse(date, dateFormat, new Date()), {
						weekStartsOn: 1,
					})
					const datesOfCurrentWeek = [
						format(startOfWeekDate, dateFormat),
						format(addDays(startOfWeekDate, 1), dateFormat),
						format(addDays(startOfWeekDate, 2), dateFormat),
						format(addDays(startOfWeekDate, 3), dateFormat),
						format(addDays(startOfWeekDate, 4), dateFormat),
					]
					datesOfCurrentWeek.forEach(day => {
						accessor().createNewEntity(entityAccessor => {
							entityAccessor().updateValues({ date: day })
						})
					})
				}
			}
		}}>
			<div className="grid gap-2 md:grid-cols-3 content-end">
				<div className="grid content-end pb-2 font-bold"><Field field="date" format={value => <>{new Date(value as string).toLocaleDateString('cs-CZ')}</>} /></div>
				<NumberField field="totalPresences" label="Počet přítomných žáků" />
				<FloatField field="totalPoints" label="Celkový počet bodů" />
			</div>
		</Repeater>

		<HiddenField field="type" defaultValue="class" />
		<EntityListSubTree
			entities={`Edition`}
			alias="editionsToConnect"
			orderBy="startedAt desc"
		>
			<StaticRender>
				<Field field="name" />
				<Field field="startedAt" />
				<Field field="finishedAt" />
			</StaticRender>
		</EntityListSubTree>
		<ConnectEntity field="organization" entity="Organization" where="(id=$organizationId)">
			<HasMany field="teams">
				<Field field="name" />
				<HasOne field="manager">
					<Field field="personId" />
				</HasOne>
			</HasMany>
			<StaticRender>
			</StaticRender>
		</ConnectEntity>
		<HasOne
			field="edition"
			onInitialize={(getAccessor, options) => {
				const editionsToConnect = Array.from(
					options.getEntityListSubTree(`editionsToConnect`),
				)
				const parentEntity = getAccessor().getParent()
				if (editionsToConnect.length && parentEntity) {
					(parentEntity as EntityAccessor).connectEntityAtField(
						'edition',
						editionsToConnect[0],
					)
				}
			}}
		/>
	</CreatePage>
)

const SelectTeam = Component(
	() => {
		const entity = useEntity()
		const redirect = useRedirect()
		const organization = entity.getEntity('organization')

		return <>
			<FieldContainer label="Výběr existující třídy">
				<Select options={Array.from(organization.getEntityList('teams')).map(team => ({
					value: team.idOnServer,
					label: team.getField<string>('name').value ?? '',
				}))} onChange={value => value && redirect({ pageName: 'admin/organization/bulkPointSubmissionEdit', parameters: { id: value } })} />
			</FieldContainer>
		</>
	},
	() => (<></>),
	'SelectTeam',
)
