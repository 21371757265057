import * as React from 'react'
import {
	Button,
	Component,
	EntityAccessor,
	Field,
	Repeater,
	SelectField,
	TextField,
} from '@contember/admin'
import { ImageField } from '../ImageField'
import { BulkAppendTeamMembers } from '../BulkAppendTeamMembers'

export const TeamFormOrganization = Component(() => {
	const [isBulkAppendActive, setIsBulkAppendActive] = React.useState(false)

	return (
		<>
			{isBulkAppendActive
				? <BulkAppendTeamMembers isBulkAppendActive={isBulkAppendActive} setIsBulkAppendActive={setIsBulkAppendActive} />
				: <>
					<TextField field="name" label="Jméno třídy" required />
					<SelectField
						field="category"
						label="Kategorie třídy"
						options="TeamCategory.name"
					/>
					<SelectField
						field="manager"
						label="Správce třídy"
						options="User[organization.id!=null]"
						renderOption={(accessor: EntityAccessor) => {
							const lastName = accessor.getField('lastName').value
							const firstName = accessor.getField('firstName').value
							const email = accessor.getField('email').value

							return `${lastName} ${firstName} (${email})`
						}}
						optionsStaticRender={
							<>
								<Field field="lastName" />
								<Field field="firstName" />
								<Field field="email" />
							</>
						}
					/>
					<Button onClick={() => setIsBulkAppendActive(true)}>Přidat žáky hromadně</Button>
					<Repeater field="teamMembers" sortableBy="order" label="Žáci">
						<TextField field="name" label="Jméno" />
					</Repeater>
					<ImageField field="coverImage" label="Cover fotka" />
				</>
			}
		</>
	)
}, () => (<>
	<Field field="name" />
	<SelectField
		field="category"
		label="Kategorie třídy"
		options="TeamCategory.name"
	/>
	<SelectField
		field="manager"
		label="Správce třídy"
		options="User[organization.id!=null]"
		renderOption={(accessor: EntityAccessor) => {
			const lastName = accessor.getField('lastName').value
			const firstName = accessor.getField('firstName').value
			const email = accessor.getField('email').value

			return `${lastName} ${firstName} (${email})`
		}}
		optionsStaticRender={
			<>
				<Field field="lastName" />
				<Field field="firstName" />
				<Field field="email" />
			</>
		}
	/>
	<Repeater field="teamMembers" sortableBy="order" label="Žáci">
		<TextField field="name" label="Jméno" />
	</Repeater>
	<ImageField field="coverImage" label="Cover fotka" />
</>), 'TeamFormOrganization')
