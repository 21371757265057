import * as React from 'react'
import { AnyUploadField, Component, Field, FileUrlFieldView, HasOne, If, ImageFieldView, Repeater, StaticRender, TextField } from '@contember/admin'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'

export const AccompanyingActivityForm = Component(() => (
	<Repeater field={`accompanyingActivities[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`} sortableBy={undefined} label="" addButtonText="Přidat další doprovodnou aktivitu">
		<TextField field="name" label="Název" />
		<div className="repeater-issues">
			<Repeater field="attachments" sortableBy="order" label="Přílohy" initialEntityCount={1} addButtonText="Přidat další přílohu">
				<If condition={entity => entity.existsOnServer}>
					<HasOne field="file">
						<If condition={entity => {
							const fileType = entity.getField<string>('type').value ?? ''
							return fileType.startsWith('image/')
						}}>
							<ImageFieldView srcField="url" width={200} />
						</If>
						<If condition={entity => {
							const fileType = entity.getField<string>('type').value ?? ''
							return !fileType.startsWith('image/')
						}}>
							<FileUrlFieldView fileUrlField="url" className="text-left" />
						</If>
						<StaticRender>
							<Field field="type" />
						</StaticRender>
					</HasOne>
				</If>
				<If condition={entity => !entity.existsOnServer}>
					<AnyUploadField
						baseEntity="file"
						urlField="url"
						label="Soubor"
						fileNameField="name"
						fileTypeField="type"
					/>
				</If>
			</Repeater>
		</div >
	</Repeater>
))
