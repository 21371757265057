import { Layout as ContemberLayout, Link, useCurrentRequest, useProjectUserRoles } from '@contember/admin'
import * as React from 'react'
import { ReactNode } from 'react'

import { LayoutAdmin } from './LayoutAdmin'
import { LayoutOrganization } from './LayoutOrganization'
import { LayoutTeam } from './LayoutTeam'
import { LayoutView } from './LayoutView'
import { LayoutBlocked } from './LayoutBlocked'
import { LayoutIframe } from './LayoutIframe'

export const Layout = (props: { children?: ReactNode }) => {
	const currenRequest = useCurrentRequest()
	const roles = useProjectUserRoles()

	if (roles.has('blocked') && !currenRequest?.pageName.startsWith('view/')) {
		return <LayoutBlocked />
	}

	if (currenRequest?.pageName.startsWith('admin/')) {
		return <LayoutAdmin>{props.children}</LayoutAdmin>
	} else if (currenRequest?.pageName.startsWith('organization/')) {
		return <LayoutOrganization>{props.children}</LayoutOrganization>
	} else if (currenRequest?.pageName.startsWith('team/')) {
		return <LayoutTeam>{props.children}</LayoutTeam>
	} else if (currenRequest?.pageName.startsWith('view/iframe')) {
		return <LayoutIframe>{props.children}</LayoutIframe>
	} else if (currenRequest?.pageName.startsWith('view/')) {
		return <LayoutView>{props.children}</LayoutView>
	} else {
		return (
			<ContemberLayout
				sidebarHeader={<Link to="index">Pešky do školy</Link>}
				navigation={false}
				children={props.children}
			/>
		)
	}
}
