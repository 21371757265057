import * as React from 'react'
import { DataGridPage, GenericCell, HasOneSelectCell, LinkButton, NumberCell, TextCell } from '@contember/admin'
import { decimalFormat } from '../../../utils/format'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../../utils/constant'

export default () => (
	<>
		<DataGridPage
			entities={`Team[(accompanyingActivities.id != null || organization.accompanyingActivities.id != null) && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}' && isDeleted=false && (accompanyingActivityPoints = 0 || accompanyingActivityPoints = null)]`}
			itemsPerPage={50}
			rendererProps={{
				title: 'Doprovodné aktivity (nevyhodnocené)', actions: <><LinkButton to="admin/team/activitiesListAll">Doprovodné aktivity - vše</LinkButton></>,
			}}
		>
			<GenericCell shrunk canBeHidden={false}>
				<LinkButton to="admin/team/activityEdit(id: $entity.id)">
					Otevřít
				</LinkButton>
			</GenericCell>
			<TextCell field="name" header="Název týmu" />
			<HasOneSelectCell field="organization" header="Škola" options="Organization.name" />
			<NumberCell field="stats.totalStandardizedPoints" header="Body za docházku" format={decimalFormat} />
			<NumberCell field="accompanyingActivityPoints" header="Body za doprovodnou aktivitu" format={decimalFormat} />
		</DataGridPage>
	</>
)
