import * as React from 'react'
import { Component, ControlledDataGrid, DataBindingProvider, DataGrid, DataGridPageRenderer, EnumCell, FeedbackRenderer, GenericCell, HasMany, HasOneSelectCell, Icon, If, Link, LinkButton, NumberCell, StaticRender, TextCell, useDataGrid } from '@contember/admin'
import { regions } from '../../../modules/regions'
import { createEnumOptions } from '../../../utils/createEnumOptions'
import { useMemo } from 'react'
import { decimalFormat } from '../../../utils/format'
import { RestoreTeam } from '../../../components/buttons/RestoreTeam'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../../utils/constant'

export default () => (
	<>
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<ClassDataGrid />
		</DataBindingProvider>
	</>
)

const ClassDataGrid = Component(
	() => {
		const dataGrid = useDataGrid({
			entities: `Team[type=class && isDeleted=true && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`,
			children: useMemo(
				() => (<ClassCells />), []),
		})

		return (<>
			<ControlledDataGrid
				component={DataGridPageRenderer}
				componentProps={{
					title: 'Smazané třídy',
				}} {...dataGrid} />
		</>)
	},
	() => (<DataGrid entities={`Team[type=class && isDeleted=true && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}><ClassCells /></DataGrid>),
	'ClassDataGrid',
)

const ClassCells = Component(() => (<>
	<GenericCell shrunk canBeHidden={false}>
		<RestoreTeam buttonProps={{ distinction: 'seamless' }} redirectToOnSuccess="admin/team/listClass" />
	</GenericCell>
	<TextCell field="organization.name" header="Jméno školy" />
	<EnumCell field="organization.region" header="Region" options={createEnumOptions(regions)} />
	<TextCell field="name" header="Název třídy" />
	<HasOneSelectCell field="category" header="Kategorie týmu" options="TeamCategory.name" />
	<NumberCell field="stats.membersCount" header="Počet členů" />
	<NumberCell field="stats.totalStandardizedPoints" header="Body za docházku" format={decimalFormat} />
	<NumberCell field="stats.accompanyingActivities" header="Body za doprovodnou aktivitu" format={decimalFormat} />
	<NumberCell field="stats.totalStandardizedPointsWithAccompanyingActivities" header="Body celkem" format={decimalFormat} />
</>))
