import { RoutingLinkTarget, useCreateResetPasswordRequest, useForm, useRedirect } from '@contember/admin'
import { Button, FieldContainer, Stack, TextInput, useShowToast } from '@contember/ui'
import { FC, useCallback } from 'react'
import * as React from 'react'

interface CreateResetPasswordRequestFormProps {
	redirectOnSuccess: RoutingLinkTarget
}

const initialValues = {
	email: '',
}

export const CreateResetPasswordRequestForm: FC<CreateResetPasswordRequestFormProps> = ({ redirectOnSuccess }) => {
	const redirect = useRedirect()
	const addToast = useShowToast()
	const createResetPasswordRequest = useCreateResetPasswordRequest()

	const { register, isSubmitting, onSubmit } = useForm(initialValues, useCallback(
		async (values: typeof initialValues) => {
			const response = await createResetPasswordRequest({
				email: values.email,
			})
			if (response.ok) {
				addToast({
					type: 'success',
					message: `Instrukce s dalším postupem najdete v e-mailové schránce.`,
					dismiss: true,
				})
				redirect(redirectOnSuccess)
			} else {
				switch (response.error.code) {
					case 'PERSON_NOT_FOUND':
						return addToast({ message: `E-mail nebyl nalezen v databázi`, type: 'error' })
				}
			}
		},
		[addToast, createResetPasswordRequest, redirect, redirectOnSuccess],
	),
	)


	return (
		<form onSubmit={onSubmit}>
			<Stack direction="vertical" gap="large">
				<FieldContainer label="E-mail">
					<TextInput {...register('email')} required={true} type={'email'} />
				</FieldContainer>
				<FieldContainer label={undefined}>
					<Button distinction="primary" type={'submit'} disabled={isSubmitting}>
						Vyžádat reset hesla
					</Button>
				</FieldContainer>
			</Stack>
		</form>
	)
}
