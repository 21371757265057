import * as React from 'react'
import { Box, EditPage, HasOne, If, PersistButton, RadioField } from '@contember/admin'
import { TeamFormAdmin } from '../../../components/forms/TeamFormAdmin'

export default () => (
	<>
		<EditPage entity="Team(id=$id)" rendererProps={{ title: 'Upravit tým nebo třídu', actions: '' }}>
			<TeamFormAdmin />
			<If condition={accessor => !!accessor.getEntity('questionnaire').idOnServer}>
				<Box heading="Dotazník">
					<HasOne field="questionnaire">
						<RadioField
							field="howDidYouFindOutUs"
							label="Jak jste se o výzvě Pěšky do školy dozvěděli?*"
							options={[
								{ value: 'involvedRegularly', label: 'Účastníme se pravidelně' },
								{ value: 'direct', label: 'Oslovili nás přímo z Pěšky městem' },
								{ value: 'parents', label: 'S iniciativou přisli rodiče' },
								{ value: 'city', label: 'Od obce nebo městské části' },
								{ value: 'socialNetworks', label: 'Ze sociálních sítí' },
								{ value: 'media', label: 'Z médií' },
							]}
						/>
					</HasOne>
				</Box>
			</If>
			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)
