import * as React from 'react'
import { Box, EditPage, EntityListSubTree, Field, FieldView, HasMany, Heading, If, ImageFieldView, Repeater, Stack, StaticRender, Table, TableCell, TableRow } from '@contember/admin'

export default () => (
	<>
		<EditPage entity="Organization(id=$id)" rendererProps={{ title: 'Upravit školu', actions: '' }}>
			<div className="bg-white p-4 rounded">
				<div className="flex justify-between">
					<h2>Škola</h2>
				</div>
				<table className="max-w-2xl">
					<tr>
						<td>Jméno školy</td>
						<td className="pl-8 font-bold text-black"><Field field="name" /></td>
					</tr>
					<tr>
						<td>IČ</td>
						<td className="pl-8 font-bold text-black"><Field field="idNo" /></td>
					</tr>
					<tr>
						<td>Ulice a č.p.</td>
						<td className="pl-8 font-bold text-black"><Field field="street" /></td>
					</tr>
					<tr>
						<td>Město</td>
						<td className="pl-8 font-bold text-black"><Field field="city" /></td>
					</tr>
					<tr>
						<td>PSČ</td>
						<td className="pl-8 font-bold text-black"><Field field="postalCode" /></td>
					</tr>
					<tr>
						<td>Kraj</td>
						<td className="pl-8 font-bold text-black"><Field field="region" /></td>
					</tr>
					<tr>
						<td>Počet nahlášených týmů</td>
						<td className="pl-8 font-bold text-black"><Field field="teamCounts" /></td>
					</tr>
				</table>
				<div className="flex justify-between gap-2">
					<HasMany field="addresses">
						<div className="mt-4 shadow p-2 w-full">
							<table className="max-w-2xl">
								<tr>
									<td className="font-bold text-black"><Field field="type" /></td>
									<td></td>
								</tr>
								<tr>
									<td>IČ</td>
									<td className="pl-8 font-bold text-black"><Field field="idNo" /></td>
								</tr>
								<tr>
									<td>Ulice a č.p.</td>
									<td className="pl-8 font-bold text-black"><Field field="street" /></td>
								</tr>
								<tr>
									<td>Město</td>
									<td className="pl-8 font-bold text-black"><Field field="city" /></td>
								</tr>
								<tr>
									<td>PSČ</td>
									<td className="pl-8 font-bold text-black"><Field field="postalCode" /></td>
								</tr>
							</table>
						</div>
					</HasMany>
				</div>
			</div>

			<Box heading="Srávci">
				<HasMany field="users[role=organizationManager]">
					<Heading depth={3} size="small" className="my-4">
						<FieldView<string, string>
							fields={["firstName", "lastName"]}
							render={({ value: firstName }, { value: lastName }) => `${firstName} ${lastName}`}
						/>

					</Heading>
					<Table>
						<TableRow>
							<TableCell>E-mail</TableCell>
							<TableCell><Field field="email" /></TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Telefon</TableCell>
							<TableCell><Field field="phone" /></TableCell>
						</TableRow>
					</Table>
				</HasMany>
			</Box>

			<EntityListSubTree entities="Edition" orderBy="startedAt asc">
				<Box>
					<Heading depth={3}><Field field="name" /></Heading>
					<HasMany field="teams[organization.id=$id]">
						<div className="py-4">
							<Heading depth={3} size="small"><Field field="name" /></Heading>
							<div className="mt-4">
								<Table>
									<TableRow>
										<TableCell>Počet členů týmu</TableCell>
										<TableCell><Field field="stats.membersCount" /></TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Body za aktivitu</TableCell>
										<TableCell><Field field="stats.accompanyingActivities" /></TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Celkový počet bodů</TableCell>
										<TableCell><Field field="stats.totalPoints" /></TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Způsob zadávání docházky</TableCell>
										<TableCell>
											<If condition={entity => Array.from(entity.getEntityList('bulkPointSubmissions')).length > 0}>
												hromadně
											</If>
											<If condition={entity => Array.from(entity.getEntityList('bulkPointSubmissions')).length === 0}>
												jednotlivě
											</If>
										</TableCell>
									</TableRow>
								</Table>
							</div>
							<div className="mt-4">
								<Repeater
									field="accompanyingActivities"
									orderBy="id"
									label="Dokumentace doprovodné aktivity třídy"
									initialEntityCount={0}
									enableAddingNew={false}
									enableRemoving={false}
								>
									<Stack direction={'vertical'}>
										<Field field="name" />
										<Stack direction='horizontal' wrap>
											<HasMany field="attachments">
												<ImageFieldView srcField="file.url" width={100} />
											</HasMany>
										</Stack>
									</Stack>
								</Repeater>
							</div>
						</div>

						<StaticRender>
							<HasMany field="bulkPointSubmissions" />
						</StaticRender>
					</HasMany>
				</Box>
			</EntityListSubTree>

		</EditPage>
	</>
)
