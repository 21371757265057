
import * as React from 'react'
import { Component, EntitySubTree, FieldContainer, Select, useEntitySubTree, useRedirect } from '@contember/admin'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../utils/constant'

export const SelectTeam = Component(
	() => {
		const redirect = useRedirect()
		const organizationToConnect = useEntitySubTree('organizationToConnect')
		return <>
			<FieldContainer label="Výběr existující třídy">
				<Select options={Array.from(organizationToConnect.getEntityList(`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`)).map(team => ({
					value: team.idOnServer,
					label: team.getField<string>('name').value ?? '',
				}))} onChange={value => value && redirect({ pageName: 'organization/bulkPointSubmissionEdit', parameters: { id: value } })} />
			</FieldContainer>
		</>
	},
	() => (<></>),
	'SelectTeam',
)
