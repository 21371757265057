import { EditPage, Field, LinkButton, PersistButton } from '@contember/admin'
import * as React from 'react'
import {
	TeamFormTeamManager,
} from '../../components/forms/TeamFormTeamManager'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { DeleteTeam } from '../../components/buttons/DeleteTeam'

export default () => (
	<>
		<TeamTitleBar />
		<EditPage
			entity="Team(id=$id)"
			refreshDataBindingOnPersist={false}
			rendererProps={{
				title: (<>Upravit <Field field="name" /></>), actions: <>
					<div className="mr-4"><DeleteTeam redirectToOnSuccess="team/list" /></div>
					<LinkButton to="team/attendanceTeam(id: $entity.id)">Zadat docházku</LinkButton>
					<LinkButton to="team/editActivity(id: $entity.id)">Přidat aktivitu</LinkButton>
					<PersistButton distinction="primary" labelSave="Uložit" labelSaved="Uložit" />
				</>,
			}} >
			<div className="form-box">
				<TeamFormTeamManager />
				<PersistButton labelSave="Uložit" labelSaved="Uložit" />
			</div>
		</EditPage>
	</>
)
