import { FieldContainer, Radio } from '@contember/admin'
import * as React from 'react'
import { RegisterFormType } from '../../modules/register'
import { ANALOG_PRICE } from '../../utils/constant'
import { AnalogByPostNotice } from '../partials/howWillYouParticipate/AnalogByPostNotice'
import { DigitalNotice } from '../partials/howWillYouParticipate/DigitalNotice'
import { AnalogPrintSelfNotice } from '../partials/howWillYouParticipate/AnalogPrintSelfNotice'

type QuestionnaireProps = {
	registerForm: RegisterFormType
	setRegisterForm: React.Dispatch<React.SetStateAction<RegisterFormType>>
}

export const Questionnaire = ({ registerForm, setRegisterForm }: QuestionnaireProps) => {
	return (
		<>
			<FieldContainer label="Chceme se zapojit do soutěže*">
				<Radio
					name="wantToGetInvolved"
					options={[
						{ value: 'true', label: 'Ano' },
						{ value: 'false', label: 'Ne, budeme chodit jen tak' },
					]}
					value={registerForm.questionnaire.wantToGetInvolved ? 'true' : 'false'}
					onChange={value => setRegisterForm({ ...registerForm, questionnaire: { ...registerForm.questionnaire, wantToGetInvolved: value === 'true' ? true : false } })}
				/>
			</FieldContainer>
			<FieldContainer label="Jakým způsobem chcete zaznamenávat cesty do školy?">
				<Radio
					options={[
						{
							value: 'digital',
							label: 'Digitálně v aplikaci',
						},
						{
							value: 'analogPrintSelf',
							label: <div className="flex gap-2"><span>Na papír (vystiskneme si sami)</span></div>,
						},
						{
							value: 'analogByPost',
							label: <div className="flex flex-wrap gap-2 items-center">
								<span>Objednat originální papírovou motivační sadu (objednat do 15. 6.)</span>
								<span className="inline-flex bg-red-500 text-white text-[12px] rounded-md px-2 py-1">Zploplatněno {ANALOG_PRICE},-</span>
							</div>,
						},
					]}
					value={registerForm.questionnaire.howWillYouParticipate}
					onChange={value => {
						if (value === 'analogByPost' && !registerForm.billingAddress.name) {
							const { noClasses, ...address } = registerForm.organization
							setRegisterForm({ ...registerForm, billingAddress: address, deliveryAddress: address, questionnaire: { ...registerForm.questionnaire, howWillYouParticipate: value as RegisterFormType['questionnaire']['howWillYouParticipate'] } })
						} else {
							setRegisterForm({ ...registerForm, questionnaire: { ...registerForm.questionnaire, howWillYouParticipate: value as RegisterFormType['questionnaire']['howWillYouParticipate'] } })
						}
					}
					}
				/>
			</FieldContainer>
			<FieldContainer label="Jak jste se o výzvě Pěšky do školy dozvěděli?*">
				<Radio
					options={[
						{ value: 'involvedRegularly', label: 'Účastníme se pravidelně' },
						{ value: 'direct', label: 'Oslovili nás přímo z Pěšky městem' },
						{ value: 'parents', label: 'S iniciativou přisli rodiče' },
						{ value: 'city', label: 'Od obce nebo městské části' },
						{ value: 'socialNetworks', label: 'Ze sociálních sítí' },
						{ value: 'media', label: 'Z médií' },
					]}
					value={registerForm.questionnaire.howDidYouFindOutUs}
					onChange={value => setRegisterForm({ ...registerForm, questionnaire: { ...registerForm.questionnaire, howDidYouFindOutUs: value as RegisterFormType['questionnaire']['howDidYouFindOutUs'] } })}
				/>
			</FieldContainer>
			{registerForm.questionnaire.howWillYouParticipate === 'digital' && <DigitalNotice />}
			{registerForm.questionnaire.howWillYouParticipate === 'analogPrintSelf' && <AnalogPrintSelfNotice />}
			{registerForm.questionnaire.howWillYouParticipate === 'analogByPost' && <AnalogByPostNotice />}
		</>)
}
