import * as React from 'react'
import {
	AutoFields,
	Box,
	CheckboxField,
	Component,
	EntityAccessor,
	Field,
	HasMany,
	HasOne,
	LocationField,
	MultiSelectField,
	NumberField,
	RadioField,
	SelectField,
	TextField,
} from '@contember/admin'
import { ImageField } from '../ImageField'
import { regions } from '../../modules/regions'
import { addressTypeLabels, howDidYouFindOutUsLabels, howWillYouParticipateLabels } from '../../utils/labels'

export const OrganizationForm = Component(() => (
	<>
		<Box heading="Škola">
			<div className="flex gap-2 flex-col">
				<div className="flex gap-2">
					<TextField field="name" label="Jméno školy" required />
					<TextField field="idNo" label="IČ" required />
				</div>
				<TextField field="street" label="Ulice a č.p." required />
				<div className="flex gap-2">
					<TextField field="city" label="Město" required />
					<TextField field="postalCode" label="PSČ" required />
				</div>
				<div className="flex gap-2">
					<SelectField field="region" label="Kraj" options={regions} required />
					<NumberField key="teamCounts" field="teamCounts" label="Počet nahlášených týmů" />
				</div>

				<LocationField latitudeField="latitude" longitudeField="longitude" label="Lokalita" />
				<CheckboxField field="isLocationPublic" label="Zobrazovat na veřejné mapě" />
			</div>
		</Box>
		<Box heading="Adresy">
			<div className="flex gap-2">
				<HasMany field="addresses">
					<div className="flex gap-2 flex-col">
						<SelectField field="type" label="Typ" options={addressTypeLabels} required />
						<TextField field="street" label="Ulice a č.p." required />
						<TextField field="city" label="Město" required />
						<TextField field="postalCode" label="PSČ" required />
					</div>
				</HasMany>
			</div>
		</Box>
		<Box heading="Správci">
			<MultiSelectField
				field="users[role=organizationManager]"
				label="Správci"
				options="User[role=organizationManager]"
				renderOption={(accessor: EntityAccessor) => {
					const lastName = accessor.getField('lastName').value
					const firstName = accessor.getField('firstName').value
					const email = accessor.getField('email').value

					return `${lastName} ${firstName} (${email})`
				}}
				optionsStaticRender={
					<>
						<Field field="lastName" />
						<Field field="firstName" />
						<Field field="email" />
					</>
				}
			/>
			<MultiSelectField
				field="users[role=teamManager]"
				label="Učitelé"
				options="User[role=teamManager]"
				renderOption={(accessor: EntityAccessor) => {
					const lastName = accessor.getField('lastName').value
					const firstName = accessor.getField('firstName').value
					const email = accessor.getField('email').value

					return `${lastName} ${firstName} (${email})`
				}}
				optionsStaticRender={
					<>
						<Field field="lastName" />
						<Field field="firstName" />
						<Field field="email" />
					</>
				}
			/>
		</Box>
		<Box heading="Dotazník">
			<HasOne field="questionnaire">
				<CheckboxField field="wantToGetInvolved" label="Chceme se zapojit do soutěže" />
				<hr />
				<RadioField
					field="howWillYouParticipate"
					label="Jakým způsobem chcete zaznamenávat cesty do školy?"
					options={howWillYouParticipateLabels}
					defaultValue={true}
				/>
				<hr />
				<RadioField
					field="howDidYouFindOutUs"
					label="Jak jste se o výzvě Pěšky do školy dozvěděli?*"
					options={howDidYouFindOutUsLabels}
				/>
			</HasOne>
		</Box>

		<Box heading="Týmy">
			<HasMany field="teams">
				<Field field="name" />
				<br />
			</HasMany>
		</Box>
	</>
))

export const OrganizationSideForm = Component(() => (
	<>
		<ImageField field="coverImage" label="Cover fotka" />
	</>
))
