import {
	Layout as ContemberLayout,
	useProjectUserRoles,
	useRedirect,
} from '@contember/admin'
import * as React from 'react'
import { ReactNode } from 'react'
import { GlobalFooter } from './partials/footer'

export const LayoutTeam = (props: { children?: ReactNode }) => {
	const roles = useProjectUserRoles()
	const redirect = useRedirect()
	if (!roles.has('teamManager')) {
		redirect('index')
	}

	return (
		<>
			<ContemberLayout children={props.children} />
			<GlobalFooter />
		</>
	)
}
