import { EditPage, GenericPage, PersistButton, TextareaField, TextField, useIdentity } from '@contember/admin'
import * as React from 'react'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'

export default () => {
	const identity = useIdentity()
	return (
		<>
			<TeamTitleBar />
			<EditPage entity={`User(personId='${identity.person?.id}')`} rendererProps={{ title: 'Upravit profil', actions: <></> }}>
				<div className="form-box">
					<TextField field="firstName" label="Jméno" required />
					<TextField field="lastName" label="Příjmení" required />
					<TextField field="phone" label="Kontaktní telefon" required />
					<TextField field="email" label="Kontaktní e-mail" required disabled />
					<PersistButton labelSave="Uložit" labelSaved="Uložit" />
				</div>
			</EditPage>
		</>
	)
}
