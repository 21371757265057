import { GenericPage, useProjectUserRoles } from '@contember/admin'
import * as React from 'react'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'


export default () => {
	const roles = useProjectUserRoles()

	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<div id="view-kids">
					<h1 className="text-center">Dětská sekce</h1>
					<div className="flex flex-col md:flex-row items-center justify-center mt-8 gap-4">
						<a href="https://kviz.peskydoskoly.cz/onlajn" target="_blank" rel="noopener noreferrer" className="no-underline">
							<div className="w-48 h-48 bg-blue-100 rounded-lg shadow-md transition-transform duration-300 hover:transform hover:scale-105 hover:shadow-lg">
								<img
									src="https://data.eu.cntmbr.com/pesky-do-skoly/pesky-do-skoly/f924eff0-7470-460f-a228-ee1ddf0181f9.png"
									alt="Zachraň klima s Onl@jnem"
									className="w-full h-full object-cover"
								/>
								<h2 className="!text-lg font-semibold text-blue-800 mt-2 text-center">Zachraň klima s Onl@jnem</h2>
							</div>
						</a>
						<a href="https://kviz.peskydoskoly.cz/bezpecne-do-skoly" target="_blank" rel="noopener noreferrer" className="no-underline">
							<div className="w-48 h-48 bg-green-100 rounded-lg shadow-md transition-transform duration-300 hover:transform hover:scale-105 hover:shadow-lg">
								<img
									src="https://data.eu.cntmbr.com/pesky-do-skoly/pesky-do-skoly/e5126348-5f13-4c82-a476-6c21ce6c1106.png"
									alt="Bezpečně do školy"
									className="w-full h-full object-cover"
								/>
								<h2 className="!text-lg font-semibold text-green-800 mt-2 text-center">Bezpečně do školy</h2>
							</div>
						</a>
					</div>
				</div>
			</GenericPage>
		</>
	)
}
