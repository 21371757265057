import * as React from 'react'
import { useProjectUserRoles, useRedirect } from '@contember/admin'

export default () => {
	const redirect = useRedirect()
	const roles = useProjectUserRoles()
	React.useEffect(() => {
		if (roles.has('admin')) {
			redirect('admin/dashboard'), [redirect]
		} else if (roles.has('organizationManager')) {
			redirect('organization/dashboard'), [redirect]
		} else if (roles.has('teamManager')) {
			redirect('team/dashboard'), [redirect]
		}
	}, [])
	return null
}
