import * as React from 'react'
import { Button, Component, useEntity, useEntitySubTree, usePersistWithFeedback, useRedirect } from '@contember/admin'

export const RemoveUserButton = Component(() => {
	const entity = useEntity()
	const triggerPersist = usePersistWithFeedback()
	const redirect = useRedirect()

	return (
		<Button distinction="outlined" onClick={async () => {
			entity.disconnectEntityAtField('organization')
			await triggerPersist().catch(() => {
			})
			redirect('organization/user/list')
		}}>Odebrat uživatele</Button>
	)
}, () => (<></>), 'RemoveUserButton')
