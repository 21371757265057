import { Component, EditPage, EntityListSubTree, Field, FloatField, HasMany, HasOne, Link, NumberField, PersistButton, Repeater, StaticRender, TextField, useEntity } from '@contember/admin'
import * as React from 'react'
import { addDays, format, parse, parseISO, startOfWeek } from 'date-fns'
import { CanAccessBulkPointSubmission } from '../../components/CanAccessBulkPointSubmission'


export default () => {
	return (
		<EditPage entity={`Team(id=$id)`} rendererProps={{
			title: <>Hromadné zadání bodů k existující třídě</>,
			actions: <PersistButton labelSave="Uložit" labelSaved="Uložit" />,
		}}>
			<CanAccessBulkPointSubmission
				disabled={<div className="bg-red-100 shadow sm:rounded-lg">
					<div className="px-4 py-5 sm:p-6">
						<div className="max-w-xl text-sm text-gray-500">
							<p>Body lze hromadně zadat až po skončení ročníku.</p>
						</div>
					</div>
				</div>}
			>
				<>
					<BulkPointSubmissionEditForm />

					<StaticRender>
						<HasOne field="stats">
							<Field field="totalPoints" />
						</HasOne>
						<HasMany field="bulkPointSubmissions">
							<Field field="date" />
							<Field field="totalPresences" />
							<Field field="totalPoints" />
						</HasMany>
					</StaticRender>
					<EntityListSubTree
						entities={`Edition`}
						alias="editionsToConnect"
						orderBy="startedAt desc"
					>
						<StaticRender>
							<Field field="name" />
							<Field field="startedAt" />
							<Field field="finishedAt" />
						</StaticRender>
					</EntityListSubTree>
				</>
			</CanAccessBulkPointSubmission>
		</EditPage>
	)
}

const BulkPointSubmissionEditForm = Component(() => {
	const entity = useEntity()
	const totalPoints = entity.getEntity('stats').getField<number>('totalPoints').value

	if (Array.from(entity.getEntityList('bulkPointSubmissions')).length > 0) {
		return (<>
			<Link to="organization/bulkPointSubmission"> ← Zpět</Link>
			<TextField field="name" label="Jméno třídy" required disabled />
			<Repeater field="bulkPointSubmissions" label={undefined} orderBy="date" initialEntityCount={0} enableAddingNew={false} enableRemoving={false}>
				<div className="grid gap-2 md:grid-cols-3 content-end">
					<div className="grid content-end pb-2 font-bold"><Field field="date" format={value => <>{new Date(value as string).toLocaleDateString('cs-CZ')}</>} /></div>
					<NumberField field="totalPresences" label="Počet přítomných žáků" />
					<FloatField field="totalPoints" label="Celkový počet bodů" />
				</div>
			</Repeater>
		</>)
	} else if (totalPoints && totalPoints > 0) {
		return <>
			<div className="bg-white shadow sm:rounded-lg">
				<div className="px-4 py-5 sm:p-6">
					<div className="max-w-xl text-sm text-gray-500">
						<p>K třídě nelze zadat hromadně body, protože k ní již byla zadána v režimu jednotlivých žáků. <Link to="organization/bulkPointSubmission">Zpět</Link></p>
					</div>
				</div>
			</div>
		</>
	} else {
		return (<>
			<div className="bg-white shadow sm:rounded-lg">
				<div className="px-4 py-5 sm:p-6">
					<div className="max-w-xl text-sm text-gray-500">
						<p>Pokud jste body počítaly mimo aplikaci, zda je můžete pro každou třídu rychle doplnit, aby se třída objevila v celkovém žebříčku.</p>
					</div>
				</div>
			</div>
			<TextField field="name" label="Jméno třídy" required disabled />
			<Repeater field="bulkPointSubmissions" label={undefined} orderBy="date" initialEntityCount={0} enableAddingNew={false} enableRemoving={false} onInitialize={(accessor, options) => {
				const editionsToConnect = Array.from(
					options.getEntityListSubTree(`editionsToConnect`),
				)
				if (editionsToConnect.length) {
					const currentEditionStartedAt = editionsToConnect[0].getField<string>('startedAt').value
					if (currentEditionStartedAt) {
						const dateFormat = 'y-MM-dd'
						const date = format(parseISO(currentEditionStartedAt), dateFormat)
						const startOfWeekDate = startOfWeek(parse(date, dateFormat, new Date()), {
							weekStartsOn: 1,
						})
						const datesOfCurrentWeek = [
							format(startOfWeekDate, dateFormat),
							format(addDays(startOfWeekDate, 1), dateFormat),
							format(addDays(startOfWeekDate, 2), dateFormat),
							format(addDays(startOfWeekDate, 3), dateFormat),
							format(addDays(startOfWeekDate, 4), dateFormat),
						]
						datesOfCurrentWeek.forEach(day => {
							accessor().createNewEntity(entityAccessor => {
								entityAccessor().updateValues({ date: day })
							})
						})
					}
				}
			}}>
				<div className="grid gap-2 md:grid-cols-3 content-end">
					<div className="grid content-end pb-2 font-bold"><Field field="date" format={value => <>{new Date(value as string).toLocaleDateString('cs-CZ')}</>} /></div>
					<NumberField field="totalPresences" label="Počet přítomných žáků" />
					<FloatField field="totalPoints" label="Celkový počet bodů" />
				</div>
			</Repeater>
		</>)
	}

}, () => (<>
	<TextField field="name" label="Jméno třídy" required disabled />
	<Repeater field="bulkPointSubmissions" label={undefined} orderBy="date" initialEntityCount={0} enableAddingNew={false} enableRemoving={false} onInitialize={(accessor, options) => {
		if (Array.from(accessor()).length > 0) {
			return
		}
		const editionsToConnect = Array.from(
			options.getEntityListSubTree(`editionsToConnect`),
		)
		if (editionsToConnect.length) {
			const currentEditionStartedAt = editionsToConnect[0].getField<string>('startedAt').value
			if (currentEditionStartedAt) {
				const dateFormat = 'y-MM-dd'
				const date = format(parseISO(currentEditionStartedAt), dateFormat)
				const startOfWeekDate = startOfWeek(parse(date, dateFormat, new Date()), {
					weekStartsOn: 1,
				})
				const datesOfCurrentWeek = [
					format(startOfWeekDate, dateFormat),
					format(addDays(startOfWeekDate, 1), dateFormat),
					format(addDays(startOfWeekDate, 2), dateFormat),
					format(addDays(startOfWeekDate, 3), dateFormat),
					format(addDays(startOfWeekDate, 4), dateFormat),
				]
				datesOfCurrentWeek.forEach(day => {
					accessor().createNewEntity(entityAccessor => {
						entityAccessor().updateValues({ date: day })
					})
				})
			}
		}
	}}>
		<div className="grid gap-2 md:grid-cols-3 content-end">
			<div className="grid content-end pb-2 font-bold"><Field field="date" format={value => <>{new Date(value as string).toLocaleDateString('cs-CZ')}</>} /></div>
			<NumberField field="totalPresences" label="Počet přítomných žáků" />
			<FloatField field="totalPoints" label="Celkový počet bodů" />
		</div>
	</Repeater>
</>), 'BulkPointSubmissionEditForm')

