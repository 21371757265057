import * as React from 'react'
import { Component, FieldContainer, TextInput } from '@contember/admin'
import { RegisterFormType } from '../../modules/register'

type DeliveryAddressProps = {
	registerForm: RegisterFormType
	setRegisterForm: React.Dispatch<React.SetStateAction<RegisterFormType>>
}

export const DeliveryAddress = Component(({ registerForm, setRegisterForm }: DeliveryAddressProps) => (
	<FieldContainer label="Doručovací údaje">
		<FieldContainer label="Jméno školy">
			<TextInput label="Jméno školy" value={registerForm.deliveryAddress.name} onChange={value => setRegisterForm({ ...registerForm, deliveryAddress: { ...registerForm.deliveryAddress, name: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="IČ">
			<TextInput label="IČ" value={registerForm.deliveryAddress.idNo} onChange={value => setRegisterForm({ ...registerForm, deliveryAddress: { ...registerForm.deliveryAddress, idNo: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="Ulice a č.p.">
			<TextInput label="Ulice a č.p." value={registerForm.deliveryAddress.street} onChange={value => setRegisterForm({ ...registerForm, deliveryAddress: { ...registerForm.deliveryAddress, street: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="Město">
			<TextInput label="Město" value={registerForm.deliveryAddress.city} onChange={value => setRegisterForm({ ...registerForm, deliveryAddress: { ...registerForm.deliveryAddress, city: value ? String(value) : undefined } })} />
		</FieldContainer>
		<FieldContainer label="PSČ">
			<TextInput label="PSČ" value={registerForm.deliveryAddress.postalCode} onChange={value => setRegisterForm({ ...registerForm, deliveryAddress: { ...registerForm.deliveryAddress, postalCode: value ? String(value) : undefined } })} />
		</FieldContainer>
	</FieldContainer>
))
