import { Button, Component, Filter, useCurrentContentGraphQlClient } from '@contember/admin'
import * as Papa from 'papaparse'
import * as React from 'react'
import { sanitizeFilter } from '../../utils/filter'

const LIST_ORGANIZATION_QUERY = `
	query($filter: OrganizationWhere) {
		listOrganization(filter: $filter) {
			name
			idNo
			street
			city
			region
			postalCode
			teamCounts
			stats {
				teamsTotal
				walkingTotalPoints
				accompanyingActivitiesTotalPoints
				teamsTotalPoints
			}
			users (filter: { role: { eq: organizationManager }, phone: { isNull: false } }, limit: 1) {
				firstName
				lastName
				email
			}
			questionnaire {
				howDidYouFindOutUs
				howWillYouParticipate
				wantToGetInvolved
			}
		}
	}
`

type ListOrganizationQueryResult = {
	listOffer: Organization[]
}

type Organization = {
	name: string
	idNo: string
	street?: string
	city?: string
	region?: string
	postalCode?: string
	teamCounts?: number
	stats: {
		teamsTotal?: number
		walkingTotalPoints?: number
		accompanyingActivitiesTotalPoints?: number
		teamsTotalPoints?: number
	}
	users: {
		firstName: string
		lastName: string
		email: string
	}[]
	questionnaire: {
		howDidYouFindOutUs: string
		howWillYouParticipate: string
		wantToGetInvolved: boolean
	}
}

type OrganizationExportProps = {
	filter: Filter
}

export const OrganizationExport = Component<OrganizationExportProps>(
	({ filter }) => {
		const client = useCurrentContentGraphQlClient()
		const [prepareDownload, setPrepareDownload] = React.useState<boolean>(false)
		const [items, setItems] = React.useState<any>(null)
		const [objectUrl, setObjectUrl] = React.useState<string>()
		const sanitizedFilter = React.useMemo(() => sanitizeFilter(filter), [filter])
		const handler = React.useCallback(async () => {
			return await client.sendRequest<ListOrganizationQueryResult>(LIST_ORGANIZATION_QUERY, { variables: { filter: sanitizedFilter } })
		}, [client, sanitizedFilter])

		React.useEffect(() => {
			setObjectUrl(undefined)
			setItems(null)
			setPrepareDownload(false)
		}, [filter])

		React.useEffect(() => {
			if (items) {
				const csv = items.data?.listOrganization?.map((organization: Organization) => {

					const wantToGetInvolved = organization.questionnaire?.wantToGetInvolved ? 'Ano' : 'Ne'
					const howWillYouParticipate = organization.questionnaire?.howWillYouParticipate === 'digital' ? 'Digitálně v aplikaci' : organization.questionnaire?.howWillYouParticipate === 'analogPrintSelf' ? 'Na papír (vystiskneme si sami)' : organization.questionnaire?.howWillYouParticipate === 'analogByPost' ? 'Objednat originální papírovou motivační sadu (500,-, objednat do 15. 6.)' : ''
					const howDidYouFindOutUs = organization.questionnaire?.howDidYouFindOutUs === 'involvedRegularly' ? 'Účastníme se pravidelně' : organization.questionnaire?.howDidYouFindOutUs === 'direct' ? 'Oslovili nás přímo z Pěšky městem' : organization.questionnaire?.howDidYouFindOutUs === 'parents' ? 'S iniciativou přisli rodiče' : organization.questionnaire?.howDidYouFindOutUs === 'city' ? 'Od obce nebo městské části' : organization.questionnaire?.howDidYouFindOutUs === 'socialNetworks' ? 'Ze sociálních sítí' : organization.questionnaire?.howDidYouFindOutUs === 'media' ? 'Z médií' : ''

					return [
						organization.name,
						organization.idNo,
						organization.street,
						organization.city,
						organization.region,
						organization.postalCode,
						organization.teamCounts ?? 0,
						organization?.stats?.teamsTotal ?? 0,
						organization?.stats?.walkingTotalPoints ?? 0,
						organization?.stats?.accompanyingActivitiesTotalPoints ?? 0,
						organization?.stats?.teamsTotalPoints ?? 0,
						organization.users[0] ? `${organization.users[0].firstName} ${organization.users[0].lastName}` : '',
						organization.users[0] ? `${organization.users[0].email}` : '',
						wantToGetInvolved,
						howWillYouParticipate,
						howDidYouFindOutUs,
					]
				})

				csv.unshift([
					'Jméno školy',
					'IČ',
					'Ulice a č.p.',
					'Město',
					'Kraj',
					'PSČ',
					'Nahlášené týmy',
					'Počet týmů',
					'Body za docházku',
					'Body za doprovodné aktivity',
					'Body celkem',
					'Jméno a příjmení správce',
					'E-mail správce',
					'Chtějí se zapojit do soutěže',
					'Jak se o nás dozvěděli',
					'Jak se budou zapojovat',
				])

				const myCsv = Papa.unparse(csv, { delimiter: ';' })
				const blob = new Blob([myCsv], { type: 'text/csv;charset=utf-8;' })
				const url = URL.createObjectURL(blob)
				setObjectUrl(url)
				return () => {
					URL.revokeObjectURL(url)
				}
			}
		}, [items])

		if (objectUrl) {
			return (
				<a href={objectUrl} download="organizations.csv">
					<Button distinction="outlined">Stáhnout export</Button>
				</a>
			)
		} else {
			return (
				<Button
					onClick={async () => {
						setPrepareDownload(true)
						setTimeout(async () => setItems(await handler()), 1500)
					}}
					distinction="outlined"
					loading={prepareDownload}
				>
					Exportovat
				</Button>
			)
		}
	},
	() => null,
	'OrganizationExport',
)
