import * as React from 'react'
import { DataBindingProvider, EntitySubTree, FeedbackRenderer, Field, FieldView, GenericPage, HasOne, useRedirect } from '@contember/admin'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'

export default () => {
	const redirect = useRedirect()
	const urlParams = new URLSearchParams(window.location.search)
	if (!urlParams.get('id')) {
		redirect('view/list')
	}
	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<DataBindingProvider stateComponent={FeedbackRenderer}>
					<EntitySubTree entity="Team(id=$id)">
						<section style={{ textAlign: 'center', marginTop: '50px' }}>
							<h2>
								<Field field="name" />
							</h2>
							<HasOne field="stats">
								<FieldView
									field="totalStandardizedPointsWithAccompanyingActivities"
									render={field => {
										const value = field.getAccessor().value
										return <p className="text-xl">{value ? `${value} bodů` : '0 bodů'}</p>
									}}
								/>
							</HasOne>
						</section>
					</EntitySubTree>
				</DataBindingProvider>
			</GenericPage>
		</>
	)
}
