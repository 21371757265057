import { useEffect } from 'react'

export const useSMap = (
	cb: any,
	loaderApiConfig?: {
		jak?: boolean
		poi?: boolean
		pano?: boolean
		suggest?: boolean
		api?: 'full' | 'simple'
	} | null,
) => {
	useEffect(() => {

		const onload = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Loader.async = true
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Loader.load(null, loaderApiConfig, cb)
		}

		const script = document.createElement('script')
		script.src = 'https://api.mapy.cz/loader.js'
		script.async = true
		script.onload = onload
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])
}
