import { CreatePage, EntityAccessor, EntityListSubTree, EntitySubTree, Field, FloatField, HasMany, HasOne, HiddenField, NumberField, PersistButton, Repeater, Stack, StaticRender, TextField, useIdentity } from '@contember/admin'
import * as React from 'react'
import { addDays, format, parse, parseISO, startOfWeek } from 'date-fns'
import { SelectTeam } from '../../components/SelectTeam'
import { CanAccessBulkPointSubmission } from '../../components/CanAccessBulkPointSubmission'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'


export default () => {
	const identity = useIdentity()
	return (
		<CreatePage entity={`Team`} rendererProps={{
			title: <>Hromadné zadání bodů</>,
			actions: <PersistButton labelSave="Uložit a přidat další třídu" labelSaved="Uložit a přidat další třídu" />,
		}}>
			<CanAccessBulkPointSubmission
				disabled={<div className="bg-red-100 shadow sm:rounded-lg">
					<div className="px-4 py-5 sm:p-6">
						<div className="max-w-xl text-sm text-gray-500">
							<p>Body lze hromadně zadat až po skončení ročníku.</p>
						</div>
					</div>
				</div>}
			>
				<>
					<div className="bg-white shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6">
							<div className="max-w-xl text-sm text-gray-500">
								<p>Pokud jste body počítaly mimo aplikaci, zda je můžete pro každou třídu rychle doplnit, aby se třída objevila v celkovém žebříčku.</p>
							</div>
						</div>
					</div>
					<Stack direction="horizontal" align="center">
						<SelectTeam />
						<span className="text-black text-xs pt-4 inline-flex">nebo</span>
						<TextField field="name" label="Jméno třídy" required />
					</Stack>
					<Repeater field="bulkPointSubmissions" label={undefined} orderBy="date" initialEntityCount={0} enableAddingNew={false} enableRemoving={false} onInitialize={(accessor, options) => {
						const editionsToConnect = Array.from(
							options.getEntityListSubTree(`editionsToConnect`),
						)
						if (editionsToConnect.length) {
							const currentEditionStartedAt = editionsToConnect[0].getField<string>('startedAt').value
							if (currentEditionStartedAt) {
								const dateFormat = 'y-MM-dd'
								const date = format(parseISO(currentEditionStartedAt), dateFormat)
								const startOfWeekDate = startOfWeek(parse(date, dateFormat, new Date()), {
									weekStartsOn: 1,
								})
								const datesOfCurrentWeek = [
									format(startOfWeekDate, dateFormat),
									format(addDays(startOfWeekDate, 1), dateFormat),
									format(addDays(startOfWeekDate, 2), dateFormat),
									format(addDays(startOfWeekDate, 3), dateFormat),
									format(addDays(startOfWeekDate, 4), dateFormat),
								]
								datesOfCurrentWeek.forEach(day => {
									accessor().createNewEntity(entityAccessor => {
										entityAccessor().updateValues({ date: day })
									})
								})
							}
						}
					}}>
						<div className="grid gap-2 md:grid-cols-3 content-end">
							<div className="grid content-end pb-2 font-bold"><Field field="date" format={value => <>{new Date(value as string).toLocaleDateString('cs-CZ')}</>} /></div>
							<NumberField field="totalPresences" label="Počet přítomných žáků" />
							<FloatField field="totalPoints" label="Celkový počet bodů" />
						</div>
					</Repeater>

					<HiddenField field="type" defaultValue="class" />
					<EntitySubTree
						entity={`Organization(users.personId='${identity?.person?.id}')`}
						alias="organizationToConnect"
					>
						<StaticRender>
							<HasMany field={`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
								<Field field="name" />
							</HasMany>
						</StaticRender>
					</EntitySubTree>
					<EntityListSubTree
						entities={`Edition`}
						alias="editionsToConnect"
						orderBy="startedAt desc"
					>
						<StaticRender>
							<Field field="name" />
							<Field field="startedAt" />
							<Field field="finishedAt" />
						</StaticRender>
					</EntityListSubTree>
					<HasOne
						field="organization"
						onInitialize={(getAccessor, options) => {
							const organizationToConnect = options.getEntitySubTree(
								`organizationToConnect`,
							)
							const parentEntity = getAccessor().getParent()
							if (parentEntity && organizationToConnect) {
								(parentEntity as EntityAccessor).connectEntityAtField(
									'organization',
									organizationToConnect,
								)
							}
						}}
					/>
					<HasOne
						field="edition"
						onInitialize={(getAccessor, options) => {
							const editionsToConnect = Array.from(
								options.getEntityListSubTree(`editionsToConnect`),
							)
							const parentEntity = getAccessor().getParent()
							if (editionsToConnect.length && parentEntity) {
								(parentEntity as EntityAccessor).connectEntityAtField(
									'edition',
									editionsToConnect[0],
								)
							}
						}}
					/>
				</>
			</CanAccessBulkPointSubmission>
		</CreatePage>
	)
}
