import { BooleanFieldView, EditPage, Field, FieldView, HasMany, HasOne, If, Link, LinkButton, StaticRender, useIdentity } from '@contember/admin'
import * as React from 'react'

import girlTalk from '../../assets/images/holcicka.png'
import { decimalFormat } from '../../utils/format'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'
import { NewEditionOrganizationQuestionnaire } from '../../components/forms/NewEditionOrganizationQuestionnaire'

export default () => {
	const identity = useIdentity()
	return (
		<EditPage
			entity={`Organization(users.personId='${identity.person?.id}')`}
			rendererProps={{
				title: <>Škola <Field field="name" /></>,
				actions: <>
					<FieldView field="id" render={({ value }) => <a href={`/view/organization?id=${value}`} className="cui-button view-justifyCenter"><div className="cui-button-content">Moje škola</div></a>} />
					<LinkButton to="organization/team/create">Přidat třídu</LinkButton>
				</>,
			}}
		>
			<If condition="[stats.isActiveInCurrentEdition=false]">
				<NewEditionOrganizationQuestionnaire />
			</If>
			<If condition="[stats.isActiveInCurrentEdition=true]">
				<If condition={it => it.getEntityList(`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`).length === 0}>
					<div className="bg-white shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6">

							<h3 className="text-base font-semibold leading-6 text-gray-900">Vítejte v Pěšky do školy</h3>
							<div className="mt-2 max-w-xl text-sm text-gray-500">
								<p>Zatím tu nic není.</p>
								<p>Jako první můžete <LinkButton to="organization/user/invite">přizvat vaše kolegy učitele</LinkButton> nebo <LinkButton to="organization/team/create">založit první třídu</LinkButton>.</p>
							</div>
						</div>
					</div>
				</If>
				<HasMany field={`teams[createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}>
					<If condition="[isDeleted=false]">
						<div className="flex w-full items-center justify-between p-8 rounded-full bg-white">
							<div>
								<div className="flex flex-col items-start">
									<h2 className="!text-black mb-0"><Field field="name" /></h2>
									<p className="mb-0 mt-1 text-lg">
										<HasOne field="stats">
											<FieldView<number> field="totalStandardizedPointsWithAccompanyingActivities" render={({ value }) => {
												return <>{value ? decimalFormat(value) : 0}</>
											}} />
										</HasOne> bodů</p>
								</div>
							</div>
							<div className="flex items-center">
								<Link to="organization/team/edit(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid whitespace-nowrap border-gray-200 hover:bg-gray-50 mr-2">Nastavení</Link>
								<Link to="organization/team/activity(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid whitespace-nowrap border-gray-200 hover:bg-gray-50 mr-2">Přidat aktivitu</Link>
								<StaticRender>
									<HasMany field="bulkPointSubmissions" />
								</StaticRender>
								<If condition={accessor => Array.from(accessor.getEntityList('bulkPointSubmissions')).length === 0}>
									<Link to="organization/team/attendance(id: $entity.id)" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm border border-solid whitespace-nowrap border-gray-200 hover:bg-gray-50">Zadat docházku</Link>
								</If>
							</div>
						</div>
					</If>
				</HasMany>
				<div className="flex justify-center items-center">
					<div>
						<div className="text-center bg-white p-2 relative -top-4 rounded-br-none text-black rounded-xl">Výsledné pořadí se může změnit podle počtu bodů za doprovodnou aktivitu, které uděluje pořadatel.</div>
					</div>
					<img src={girlTalk} width="80" />
				</div>
			</If>
		</EditPage>
	)
}
