export type RegisterFormType = {
	role: 'teamManager' | 'organizationManager'
	name: string | undefined
	surname: string | undefined
	phone: string | undefined
	email: string | undefined
	team: {
		name: string | undefined
		city: string | undefined
		region: string | undefined
		type: 'team'
	}
	organization: {
		name: string | undefined
		idNo: string | undefined
		street: string | undefined
		city: string | undefined
		postalCode: string | undefined
		region: string | undefined
		longitude: number | undefined
		latitude: number | undefined
		noClasses: number | undefined
	}
	questionnaire: {
		wantToGetInvolved: boolean
		howWillYouParticipate: 'digital' | 'analogPrintSelf' | 'analogByPost'
		howDidYouFindOutUs: 'involvedRegularly' | 'direct' | 'parents' | 'city' | 'socialNetworks' | 'media' | undefined
	}
	billingAddress: {
		name: string | undefined
		idNo: string | undefined
		street: string | undefined
		city: string | undefined
		postalCode: string | undefined
	}
	deliveryAddress: {
		name: string | undefined
		idNo: string | undefined
		street: string | undefined
		city: string | undefined
		postalCode: string | undefined
	}
	agreement: boolean
}

export const isRegisterFormValid = (registerForm: RegisterFormType): boolean => {
	if (!registerForm.agreement || !registerForm.name || !registerForm.surname || !registerForm.phone || !registerForm.email) {
		return false
	}
	if (registerForm.role === 'teamManager') {
		if (!registerForm.team.name || !registerForm.team.city || !registerForm.team.region) {
			return false
		}
	}
	if (registerForm.role === 'organizationManager') {
		if (!registerForm.organization.name || !registerForm.organization.idNo || !registerForm.organization.street || !registerForm.organization.city || !registerForm.organization.postalCode || !registerForm.organization.region) {
			return false
		}
	}
	return true
}

export const isRegisterFormSecondStepValid = (registerForm: RegisterFormType): boolean => {
	if (!registerForm.questionnaire.howDidYouFindOutUs) {
		return false
	}
	if (registerForm.questionnaire.howWillYouParticipate === 'analogByPost') {
		if (!registerForm.billingAddress.name || !registerForm.billingAddress.street || !registerForm.billingAddress.city || !registerForm.billingAddress.postalCode || !registerForm.deliveryAddress.name || !registerForm.deliveryAddress.street || !registerForm.deliveryAddress.city || !registerForm.deliveryAddress.postalCode) {
			return false
		}
	}
	return true
}

export const step1ValidationErrors = (registerForm: RegisterFormType): Record<string, string> => {
	let validationErrors = {}

	if (!registerForm.agreement) {
		validationErrors = { ...validationErrors, agreement: 'Souhlas se zpracováním osobních údajů je povinný.' }
	}
	if (!registerForm.name) {
		validationErrors = { ...validationErrors, name: 'Jméno je povinný údaj.' }
	}
	if (!registerForm.surname) {
		validationErrors = { ...validationErrors, surname: 'Příjmení je povinný údaj.' }
	}
	if (!registerForm.phone) {
		validationErrors = { ...validationErrors, phone: 'Telefon je povinný údaj.' }
	} else {
		if (!registerForm.phone.match(/^[+]?[()/0-9. -]{9,}$/)) {
			validationErrors = { ...validationErrors, phone: 'Zadejte validní telefonní číslo' }
		}
	}

	if (!registerForm.email || !registerForm.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
		validationErrors = { ...validationErrors, email: 'Validní e-mail je povinný údaj.' }
	}

	if (registerForm.role === 'teamManager') {
		if (!registerForm.team.name) {
			validationErrors = { ...validationErrors, teamName: 'Název týmu je povinný údaj.' }
		}
		if (!registerForm.team.city) {
			validationErrors = { ...validationErrors, teamCity: 'Město týmu je povinný údaj.' }
		}
		if (!registerForm.team.region) {
			validationErrors = { ...validationErrors, teamRegion: 'Kraj týmu je povinný údaj.' }
		}
	}

	if (registerForm.role === 'organizationManager') {
		if (!registerForm.organization.name) {
			validationErrors = { ...validationErrors, organizationName: 'Název organizace je povinný údaj.' }
		}
		if (!registerForm.organization.idNo) {
			validationErrors = { ...validationErrors, organizationIdNo: 'IČ je povinný údaj.' }
		} else {
			if (!registerForm.organization.idNo.match(/\d{8}/)) {
				validationErrors = { ...validationErrors, organizationIdNo: 'Zadejte validní IČ (8 číslic bez mezer).' }
			}
		}
		if (!registerForm.organization.street) {
			validationErrors = { ...validationErrors, organizationStreet: 'Ulice je povinný údaj.' }
		}
		if (!registerForm.organization.city) {
			validationErrors = { ...validationErrors, organizationCity: 'Město je povinný údaj.' }
		}
		if (!registerForm.organization.postalCode) {
			validationErrors = { ...validationErrors, organizationPostalCode: 'PSČ je povinný údaj.' }
		} else {
			if (!registerForm.organization.postalCode.match(/\d{3} ?\d{2}/)) {
				validationErrors = { ...validationErrors, organizationPostalCode: 'Zadejte validní PSČ.' }
			}
		}
		if (!registerForm.organization.region) {
			validationErrors = { ...validationErrors, organizationRegion: 'Kraj je povinný údaj.' }
		}
	}

	return validationErrors
}
